import { observer } from 'mobx-react-lite';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOnrampForm, useOnrampTokens } from 'entities/Onramp/lib/hooks';
import { OnrampFiatCurrency } from 'entities/Onramp/model';

import { ExpandIcon } from 'shared/assets';
import theme from 'shared/theme';
import { Box, Button, Spinner, Text } from 'shared/ui';

import { OnrampFiatIcon } from '../OnrampFiat/OnrampFiatIcon';

import styles from './OnrampFiatSelect.module.scss';

interface Props {
  onClick: () => void;
  selected: OnrampFiatCurrency | null;
}

export const OnrampFiatSelectButton: React.FC<Props> = observer(({ onClick = (): void => {}, selected }) => {
  const { t } = useTranslation();

  const { input } = useOnrampForm();
  const { isLoading } = useOnrampTokens();

  const [hovered, setHovered] = useState(false);

  return (
    <>
      {selected || isLoading || input.isLoading ? (
        <OnrampSelectedButton currency={selected} onClick={onClick} />
      ) : (
        <Button
          onClick={onClick}
          width="fit-content"
          size="small"
          variant="secondary"
          borderRadius={24}
          text="app-14-medium"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <Box flexDirection="row" gap={8} alignItems="center">
            <Text whiteSpace="nowrap">{t('onramp.button.selectCurrency')}</Text>
            <ExpandIcon
              strokeWidth={3}
              width={16}
              height={16}
              color={hovered ? theme.colors['secondary-01'] : theme.colors['secondary-03']}
            />
          </Box>
        </Button>
      )}
    </>
  );
});

interface SelectedProps {
  currency: OnrampFiatCurrency | null;
  onClick: () => void;
}

const OnrampSelectedButton: FC<SelectedProps> = observer(({ currency, onClick }) => {
  const { isLoading, isError } = useOnrampTokens();
  const { input } = useOnrampForm();

  return (
    <Box className={styles.select} onClick={onClick}>
      {(isLoading || input.isLoading) && <Spinner size={24} />}
      {!(isLoading || input.isLoading) && !isError && <OnrampFiatIcon currency={currency} size={24} />}
      {!(isLoading || input.isLoading) && !isError && (
        <div className={styles.label}>{currency?.ticker.toUpperCase()}</div>
      )}
      <div className={styles.icon}>
        <ExpandIcon strokeWidth={3} width={16} height={16} />
      </div>
    </Box>
  );
});
