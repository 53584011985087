import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

import { routerAbi } from './abi';
import { AppChain } from './types';

export const base: AppChain<'base'> = {
  blockExplorers: {
    default: {
      name: 'BaseScan',
      url: 'https://basescan.org',
    },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  id: 8453,
  logo: <CurrencyIcon ticker="base" size={24} />,
  name: 'Base',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  network: 'base',
  tokenStandard: 'ERC-20',
  renderLogo: (size) => <CurrencyIcon ticker="base" size={size} />,
  router: {
    abi: routerAbi,
    address: '0xa04295018eb7353a9755a2b227c9203fed3d9b7c',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.ankr.com/base'],
    },
    public: {
      http: ['https://rpc.ankr.com/base'],
    },
  },
  vault: {
    address: '0xde6b4964c4384bcdfa150a4a8be9865c5b91e29c',
  },
};
