import { useEffect, useState } from 'react';

import { useNetwork } from 'shared/hooks/network';

import { TokensStorage } from '../lib/tokenStorage';

import { TokenFullData } from './types';

export const useGetSwapStorageTokens = () => {
  const { selectedChainId: chainId } = useNetwork();

  const [data, setData] = useState<TokenFullData[]>([]);

  useEffect(() => {
    setData(TokensStorage.get({ chainId }));
  }, [TokensStorage, chainId]);

  const refetch = () => {
    setData(TokensStorage.get({ chainId }));
  };

  return { data, refetch };
};
