/* eslint-disable no-useless-return */

/* eslint-disable react-hooks/exhaustive-deps */
import { DependencyList, useEffect, useRef } from 'react';

/**
 * The `useMountedEffect` function is a custom hook in TypeScript React that runs a callback function only after the
 * component has completely mounted (also counting React Strict Mode double render) and when the specified dependencies change.
 * @param {EffectCallback} callback - The `callback` parameter is a function that will be executed when
 * the component mounts or when the dependencies change. It can contain any logic or side effects that
 * you want to run.
 * @param {DependencyList} dependencies - The `dependencies` parameter is an array of values that the
 * effect depends on. When any of these values change, the effect will be re-run.
 */
export function useMountedEffect(
  callback: (isMounted: () => boolean) => void,
  deps?: DependencyList | undefined,
): void {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    callbackRef.current(() => mountedRef.current);
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
