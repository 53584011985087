import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useSaveHistoryApp = () => {
  const location = useLocation();

  const paths = useRef<string[]>([]);

  useEffect(() => {
    sessionStorage.removeItem('history-app');
  }, []);

  useEffect(() => {
    paths.current.push(location.pathname);
    sessionStorage.setItem('history-app', JSON.stringify(paths.current));
  }, [location.pathname]);
};

export const useHistoryApp = () => {
  const history = sessionStorage.getItem('history-app');

  if (!history) return null;

  return JSON.parse(history) as string[];
};
