import { Event, track } from '@rehold-io/data-layer-client';
import { To, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import {
  OnrampFormProvider,
  OnrampProvider,
  OnrampQuoteProvider,
  OnrampTokensProvider,
} from 'entities/Onramp/lib/providers';

import { Box } from 'shared/ui';

import { OnrampContent } from './OnrampContent';
import { OnrampWidget } from './OnrampWidget';

export const OnrampTab: React.FC<{ onTabChange: (tab: string) => void; tab: string }> = observer(
  ({ onTabChange, tab }) => {
    const navigate = useNavigate();
    const [submitResult, setSubmitResult] = useState<string | null>();

    return (
      <Box
        px={submitResult === null || submitResult ? 0 : { default: 12, desktop: 16, tablet: 16 }}
        py={submitResult === null || submitResult ? 0 : { desktop: 20, phone: 0, tablet: 16 }}
      >
        <OnrampProvider>
          <OnrampTokensProvider>
            <OnrampFormProvider>
              <OnrampQuoteProvider>
                <OnrampContent onChange={url => window.location.href = url as string} onTabChange={onTabChange} tab={tab} />
              </OnrampQuoteProvider>
            </OnrampFormProvider>
          </OnrampTokensProvider>
        </OnrampProvider>
      </Box>
    );
  },
);
