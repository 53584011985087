import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { UserRejectedRequestError } from 'viem';

import { DEFAULT_ERROR } from 'shared/lib/constants';
import { didUserReject, mapErrorToUserReadableMessage } from 'shared/lib/errors';
import { logger } from 'shared/lib/logger';
import { notifyAttention, notifyError } from 'shared/lib/notifications';
import { i18n } from 'shared/locales';

import { AuthResponse } from './types';

type OptionsConfirmationModal = 'message' | 'tx' | { description: string; title: string };

export class UserStore {
  isOpenWelcomeModal = false;
  isOpenConfiramationModal = false;
  optionsConfirmationModal: OptionsConfirmationModal = 'tx';
  promise: Promise<any> | undefined;
  promiseReject: (() => void) | undefined;

  settings = {};

  isLoading = true;

  user: AuthResponse | null = null;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.isOpenConfiramationModal,
      (isOpenConfiramationModal) => {
        if (this.promise && !isOpenConfiramationModal) {
          this.promiseReject?.();
          this.promise = undefined;
        }
      },
    );
  }

  setIsOpenWelcomeModal = (value: boolean) => {
    this.isOpenWelcomeModal = value;
  };

  setIsOpenConfiramationModal = (value: boolean) => {
    this.isOpenConfiramationModal = value;
  };

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };

  setUser = (user: AuthResponse | null) => {
    this.user = user;
  };

  waitForUserAction = <R>(promise: Promise<R> | undefined, options: OptionsConfirmationModal = 'tx') => {
    if (!promise) {
      return Promise.reject(new Error('Passed promise not defined'));
    }

    this.promise = promise;
    this.isOpenConfiramationModal = true;
    this.optionsConfirmationModal = options;

    const rejectPromise = new Promise<R>((_, reject) => {
      this.promiseReject = () => reject(new UserRejectedRequestError(new Error('Manual Reject')));
    });

    return Promise.race([promise, rejectPromise])
      .catch((e) => {
        if (e instanceof UserRejectedRequestError || didUserReject(e)) {
          notifyAttention({ text: i18n.t('common.userRejectedRequest') });
        } else {
          notifyError({
            text: mapErrorToUserReadableMessage(e) || DEFAULT_ERROR,
          });
          logger.error(e);
        }

        throw e;
      })
      .finally(() => {
        runInAction(() => {
          this.isOpenConfiramationModal = false;
        });
      });
  };

  get userInfo() {
    return this.user;
  }
}

export const userStore = new UserStore();
