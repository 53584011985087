export const routerAbi = [
  {
    inputs: [
      { internalType: 'contract IVault', name: '_vault', type: 'address' },
      { internalType: 'address', name: '_WETH', type: 'address' },
      { internalType: 'address', name: '_MPC', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'address', name: 'inputToken', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'inputAmount', type: 'uint256' },
      { indexed: false, internalType: 'bytes32', name: 'txHash', type: 'bytes32' },
    ],
    name: 'DualCanceled',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: true, internalType: 'address', name: 'receiver', type: 'address' },
      { indexed: false, internalType: 'address', name: 'outputToken', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'outputAmount', type: 'uint256' },
      { indexed: false, internalType: 'bytes32', name: 'txHash', type: 'bytes32' },
    ],
    name: 'DualClaimed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'user', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'chainId', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'baseToken', type: 'address' },
      { indexed: false, internalType: 'address', name: 'quoteToken', type: 'address' },
      { indexed: false, internalType: 'address', name: 'inputToken', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'inputAmount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'stakingPeriod', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'yield', type: 'uint256' },
    ],
    name: 'DualCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'oldMPC', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newMPC', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'effectiveTime', type: 'uint256' },
    ],
    name: 'MPCUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }],
    name: 'Paused',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }],
    name: 'Unpaused',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'contract IVault', name: 'oldVault', type: 'address' },
      { indexed: true, internalType: 'contract IVault', name: 'newVault', type: 'address' },
    ],
    name: 'VaultUpdated',
    type: 'event',
  },
  {
    inputs: [],
    name: 'DELAY',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'WETH',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'address', name: 'inputToken', type: 'address' },
      { internalType: 'uint256', name: 'inputAmount', type: 'uint256' },
      { internalType: 'bytes32', name: 'txHash', type: 'bytes32' },
    ],
    name: 'cancel',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'user', type: 'address' },
      { internalType: 'address', name: 'receiver', type: 'address' },
      { internalType: 'address', name: 'outputToken', type: 'address' },
      { internalType: 'uint256', name: 'outputAmount', type: 'uint256' },
      { internalType: 'bytes32', name: 'txHash', type: 'bytes32' },
      { internalType: 'bytes', name: 'signature', type: 'bytes' },
    ],
    name: 'claim',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    name: 'claimed',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'uint256', name: 'chainId', type: 'uint256' },
          { internalType: 'address', name: 'user', type: 'address' },
          { internalType: 'address', name: 'baseToken', type: 'address' },
          { internalType: 'address', name: 'quoteToken', type: 'address' },
          { internalType: 'uint256', name: 'minBaseAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'maxBaseAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'minQuoteAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'maxQuoteAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'thresholdBaseAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'thresholdQuoteAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'stakingPeriod', type: 'uint256' },
          { internalType: 'uint256', name: 'yield', type: 'uint256' },
          { internalType: 'uint256', name: 'expireAt', type: 'uint256' },
        ],
        internalType: 'struct Tariff',
        name: 'tariff',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'address', name: 'user', type: 'address' },
          { internalType: 'address', name: 'token', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        internalType: 'struct IRouter.Input',
        name: 'input',
        type: 'tuple',
      },
      { internalType: 'bytes', name: 'signature', type: 'bytes' },
    ],
    name: 'create',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'uint256', name: 'chainId', type: 'uint256' },
          { internalType: 'address', name: 'user', type: 'address' },
          { internalType: 'address', name: 'baseToken', type: 'address' },
          { internalType: 'address', name: 'quoteToken', type: 'address' },
          { internalType: 'uint256', name: 'minBaseAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'maxBaseAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'minQuoteAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'maxQuoteAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'thresholdBaseAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'thresholdQuoteAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'stakingPeriod', type: 'uint256' },
          { internalType: 'uint256', name: 'yield', type: 'uint256' },
          { internalType: 'uint256', name: 'expireAt', type: 'uint256' },
        ],
        internalType: 'struct Tariff',
        name: 'tariff',
        type: 'tuple',
      },
      { internalType: 'bytes', name: 'signature', type: 'bytes' },
    ],
    name: 'createETH',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'uint256', name: 'chainId', type: 'uint256' },
          { internalType: 'address', name: 'user', type: 'address' },
          { internalType: 'address', name: 'baseToken', type: 'address' },
          { internalType: 'address', name: 'quoteToken', type: 'address' },
          { internalType: 'uint256', name: 'minBaseAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'maxBaseAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'minQuoteAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'maxQuoteAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'thresholdBaseAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'thresholdQuoteAmount', type: 'uint256' },
          { internalType: 'uint256', name: 'stakingPeriod', type: 'uint256' },
          { internalType: 'uint256', name: 'yield', type: 'uint256' },
          { internalType: 'uint256', name: 'expireAt', type: 'uint256' },
        ],
        internalType: 'struct Tariff',
        name: 'tariff',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'address', name: 'user', type: 'address' },
          { internalType: 'address', name: 'token', type: 'address' },
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
        ],
        internalType: 'struct IRouter.Input',
        name: 'input',
        type: 'tuple',
      },
      { internalType: 'bytes', name: 'signature', type: 'bytes' },
      {
        components: [
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'uint256', name: 'deadline', type: 'uint256' },
          { internalType: 'uint8', name: 'v', type: 'uint8' },
          { internalType: 'bytes32', name: 'r', type: 'bytes32' },
          { internalType: 'bytes32', name: 's', type: 'bytes32' },
        ],
        internalType: 'struct Permit',
        name: 'permit',
        type: 'tuple',
      },
    ],
    name: 'createWithPermit',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'mpc',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'pause', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [],
    name: 'paused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  { inputs: [], name: 'unpause', outputs: [], stateMutability: 'nonpayable', type: 'function' },
  {
    inputs: [{ internalType: 'address', name: 'newMPC', type: 'address' }],
    name: 'updateMPC',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'contract IVault', name: '_vault', type: 'address' }],
    name: 'updateVault',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'vault',
    outputs: [{ internalType: 'contract IVault', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const;

export type RouterAbiType = typeof routerAbi;
