import { createWalletClient, http } from 'viem';
import { MockConnector } from 'wagmi/connectors/mock';

import { supportedChains } from 'shared/config';
import { LOCAL_STORAGE_MOCK_ADDRESS_KEY } from 'shared/lib/constants';

import { getImageWallet } from '../../getImages';
import { AppWallet } from '../../types';

const MOCK_FALLBACK_RPC = 'https://rpc.ankr.com/eth';

export const mock: AppWallet = {
  connector: new MockConnector({
    chains: supportedChains,
    options: {
      walletClient: createWalletClient({
        account: localStorage.getItem(LOCAL_STORAGE_MOCK_ADDRESS_KEY) as `0x${string}`,
        transport: http(window.ethereum || MOCK_FALLBACK_RPC),
      }),
    },
  }),
  iconPath: getImageWallet('injected'),
  id: 'mock',
  name: 'Mock',
};
