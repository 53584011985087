import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import React, { CFC, useEffect, useMemo, useState } from 'react';
import { FormProvider, UseFormReturn, useForm, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import {
  OnrampCryptoCurrencyFieldValue,
  OnrampFiatCurrency,
  OnrampFiatCurrencyFieldValue,
} from 'entities/Onramp/model';
import { OnrampFormValues } from 'entities/Onramp/model/form';

import { useMountedEffect } from 'shared/hooks';
import { removeQueryParam } from 'shared/lib';

import { useOnrampProvider } from '../hooks/useOnrampProvider';
import { useOnrampTokenByCode } from '../hooks/useOnrampTokenByCode';

type Props = {
  form: UseFormReturn<OnrampFormValues, any, undefined>;
  formValues: OnrampFormValues;
  input: OnrampFiatCurrencyFieldValue;
  output: OnrampCryptoCurrencyFieldValue;
  update: (args: {
    input?: Partial<OnrampFiatCurrencyFieldValue>;
    output?: Partial<OnrampCryptoCurrencyFieldValue>;
  }) => void;
};

const USD_CURRENCY = 'usd';

export const OnrampFormContext = React.createContext({} as Props);

export const OnrampFormProvider: CFC = observer(({ children }) => {
  const { provider } = useOnrampProvider();
  const [searchParams] = useSearchParams();

  const baseTickerParam = useMemo<string | null>(() => searchParams.get('baseTicker'), []);
  const quoteTickerParam = useMemo<string | null>(() => searchParams.get('quoteTicker'), []);

  const baseTokenParam = useOnrampTokenByCode(baseTickerParam?.toLowerCase(), 'fiat');
  const quoteTokenParam = useOnrampTokenByCode(quoteTickerParam?.toLowerCase(), 'crypto');

  const [baseTokenParamUsed, setBaseTokenParamUsed] = useState(false);
  const [quoteTokenParamUsed, setQuoteTokenParamUsed] = useState(false);

  const defaultFiatToken = useOnrampTokenByCode(USD_CURRENCY) as OnrampFiatCurrency;

  const [inputValues, setInputValues] = useState<OnrampFiatCurrencyFieldValue>({
    amount: '',
    currency: defaultFiatToken,
    isLoading: true,
  });

  const [outputValues, setOutputValues] = useState<OnrampCryptoCurrencyFieldValue>({
    amount: '',
    currency: null,
    isLoading: !!quoteTickerParam,
  });

  const onrampForm = useForm<OnrampFormValues>({
    defaultValues: {
      provider,
      fromTokenCode: inputValues.currency?.code,
      fromAmount: inputValues.amount,
      toTokenCode: outputValues.currency?.code,
    },
    mode: 'onSubmit',
  });

  const onrampFormValues = useWatch({ control: onrampForm.control });

  useEffect(() => {
    if (defaultFiatToken && !baseTickerParam) {
      update({
        input: { currency: defaultFiatToken, isLoading: false },
      });
    }
  }, [defaultFiatToken]);

  useEffect(() => {
    if (!baseTokenParamUsed && baseTokenParam !== null) {
      update({
        input: { currency: baseTokenParam || defaultFiatToken, isLoading: false },
      });
      setBaseTokenParamUsed(true);
    }
    if (!quoteTokenParamUsed && quoteTokenParam !== null) {
      update({
        output: { currency: quoteTokenParam || null, isLoading: false },
      });
      setQuoteTokenParamUsed(true);
    }
  }, [baseTokenParam, quoteTokenParam]);

  useEffect(() => {
    if (searchParams.get('baseTicker') && baseTickerParam) removeQueryParam('baseTicker');
    if (searchParams.get('quoteTicker') && quoteTickerParam) removeQueryParam('quoteTicker');
  }, [searchParams, baseTickerParam, quoteTickerParam]);

  useEffect(() => {
    if (!inputValues.amount || BigNumber(inputValues.amount).isZero())
      update({
        output: { amount: '' },
      });
  }, [inputValues.amount]);

  useMountedEffect(
    (mounted) => {
      if (mounted() && defaultFiatToken) {
        inputChange({ currency: defaultFiatToken, amount: '', isLoading: false });
        outputChange({ currency: null, amount: '', isLoading: false });
      }

      onrampForm.setValue('provider', provider);
    },
    [provider],
  );

  const inputChange = (value: Partial<OnrampFiatCurrencyFieldValue>) => {
    setInputValues((prev) => ({ ...prev, ...value }));
    if (value.currency !== undefined) onrampForm.setValue('fromTokenCode', value.currency?.code || '');
    if (value.amount !== undefined) onrampForm.setValue('fromAmount', value.amount);
  };

  const outputChange = (value: Partial<OnrampCryptoCurrencyFieldValue>) => {
    setOutputValues((prev) => ({ ...prev, ...value }));
    if (value.currency !== undefined) onrampForm.setValue('toTokenCode', value.currency?.code || '');
  };

  const update = ({
    input,
    output,
  }: {
    input?: Partial<OnrampFiatCurrencyFieldValue>;
    output?: Partial<OnrampCryptoCurrencyFieldValue>;
  }) => {
    if (input) {
      inputChange({ ...inputValues, ...input });
    }

    if (output) {
      outputChange({ ...outputValues, ...output });
    }
  };

  const values = useMemo(
    () => ({ input: inputValues, output: outputValues, form: onrampForm, formValues: onrampFormValues, update }),
    [inputValues, outputValues, onrampForm, onrampFormValues, update],
  );

  return (
    <OnrampFormContext.Provider value={values as Props}>
      <FormProvider {...values.form}>{children}</FormProvider>
    </OnrampFormContext.Provider>
  );
});
