import { Network } from 'entities/Chain';
import { DualAssetType, DualTariff } from 'entities/Dual/model';

import { AppChain } from 'shared/config/chains/types';

export const getTariffId = (id: string) => {
  const [tariffId] = id.split('::');
  return tariffId;
};

export const mapNativeTariffId = (id: string) => `${id}::`;

export const formatTariff = (tariff: DualTariff, network?: AppChain<Network>) => {
  if (!network) return null;

  const { baseTicker, id, quoteTicker } = tariff;

  const wrapTicker = `w${network.nativeCurrency.symbol}`.toLowerCase();
  const nativeTicker = network.nativeCurrency.symbol.toLowerCase();

  if (wrapTicker !== baseTicker && wrapTicker !== quoteTicker) return null;

  const newTarrif = {
    ...tariff,
    baseTicker: wrapTicker === baseTicker ? nativeTicker : baseTicker,
    id: mapNativeTariffId(id || ''),
    quoteTicker: wrapTicker === quoteTicker ? nativeTicker : quoteTicker,
  };

  return newTarrif;
};

export const formatAsset = (asset: DualAssetType, network?: AppChain<Network>) => {
  if (!network) return null;

  const { apr, baseTicker, quoteTicker, chainId } = asset;

  const wrapTicker = `w${network.nativeCurrency.symbol}`.toLowerCase();
  const nativeTicker = network.nativeCurrency.symbol.toLowerCase();

  if (wrapTicker !== baseTicker && wrapTicker !== quoteTicker) return null;
  const wbaseTicker = wrapTicker === baseTicker ? nativeTicker : baseTicker;
  const wquoteTicker = wrapTicker === quoteTicker ? nativeTicker : quoteTicker;

  const newAsset = {
    apr,
    assetId: `${wbaseTicker}/${wquoteTicker}`,
    baseTicker: wbaseTicker,
    quoteTicker: wquoteTicker,
    chainId,
  };

  return newAsset;
};
