export const getReason = (error: any): string | undefined => {
  let reason: string | undefined;
  while (error) {
    reason = error.reason ?? error.shortMessage ?? error.message ?? error.data?.message ?? reason;
    error = error.error ?? error.data?.originalError;
  }
  return reason;
};

export const didUserReject = (error: any): boolean => {
  const reason = getReason(error);

  return !!(
    error?.code === 4001 ||
    // ethers v5.7.0 wrapped error
    error?.code === 'ACTION_REJECTED' ||
    // For Rainbow :
    (reason?.match(/request/i) && reason?.match(/reject/i)) ||
    // For Frame:
    reason?.match(/declined/i) ||
    // For SafePal:
    reason?.match(/cancell?ed by user/i) ||
    // For Trust:
    reason?.match(/user cancell?ed/i) ||
    // For Coinbase:
    reason?.match(/user denied/i) ||
    // For Fireblocks
    reason?.match(/user rejected/i)
  );
};

export const getUserReject = (error: any): boolean => {
  const reason = getReason(error);

  return !!(
    error?.code === 4001 ||
    // ethers v5.7.0 wrapped error
    error?.code === 'ACTION_REJECTED' ||
    // For Rainbow :
    (reason?.match(/request/i) && reason?.match(/reject/i)) ||
    // For Frame:
    reason?.match(/declined/i) ||
    // For SafePal:
    reason?.match(/cancell?ed by user/i) ||
    // For Trust:
    reason?.match(/user cancell?ed/i) ||
    // For Coinbase:
    reason?.match(/user denied/i) ||
    // For Fireblocks
    reason?.match(/user rejected/i)
  );
};

export const mapErrorToUserReadableMessage = (error: any): string => {
  if (!error) return '';
  if (didUserReject(error)) {
    return 'Transaction rejected';
  }

  const reason = getReason(error);

  if (!reason) return '';

  const routerReasonIndex = reason.indexOf('Router: ');
  if (routerReasonIndex !== -1) {
    return reason.slice('Router: '.length + routerReasonIndex);
  }

  const vaultReasonIndex = reason.indexOf('Vault: ');
  if (vaultReasonIndex !== -1) {
    return reason.slice('Vault: '.length + vaultReasonIndex);
  }

  const dualReasonIndex = reason.indexOf('Dual: ');
  if (dualReasonIndex !== -1) {
    return reason.slice('Dual: '.length + dualReasonIndex);
  }

  return 'Unknown Error';
};
