import { useEffect, useState } from 'react';
import { Address } from 'viem';

import { getTokenByAddress } from 'entities/Token/lib';
import { UserToken } from 'entities/Token/model/types';

import { useNetwork } from 'shared/hooks/network';
import { fetchToken } from 'shared/lib';
import { logger } from 'shared/lib/logger';

import { useSwapTokenAdd } from './useSwapTokenAdd';
import { useSwapTokens } from './useSwapTokens';

export const useSwapTokenByAddress = (address?: Address | null, useCache: boolean = true) => {
  const { selectedChainId: chainId } = useNetwork();

  const { data: tokensData, cache: tokensCache, refetch, isLoading, ...rest } = useSwapTokens();

  const [tokens, setTokens] = useState<UserToken[]>([]);

  const addTokenToStorage = useSwapTokenAdd({ cache: useCache });

  useEffect(() => {
    if (tokensData && tokensCache) setTokens(useCache ? [...tokensData, ...tokensCache] : tokensData);
  }, [tokensData, tokensCache]);

  useEffect(() => {
    if (tokens.length && tokensData && tokensCache) {
      const token = getTokenByAddress<UserToken>(tokens, address!);

      if (!token && address && addTokenToStorage) {
        fetchToken({ address, chainId })
          .then((ercToken) => {
            if (ercToken) {
              addTokenToStorage({
                ...ercToken,
                logoURI: '',
              });
              refetch();
            }
          })
          .catch(logger.log);
      }
    }
  }, [tokens, tokensData, tokensCache, addTokenToStorage?.toString()]);

  return {
    ...rest,
    isLoading,
    data:
      tokensData && tokensCache
        ? getTokenByAddress<UserToken>(
            tokensData && tokensCache ? (useCache ? [...tokensData, ...tokensCache] : tokensData) : [],
            address!,
          )
        : undefined,
  };
};
