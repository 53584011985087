import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { getNotificationCount } from 'entities/User/api/queries';
import { CountNotificationResponse } from 'entities/User/model/types';
import { useUserStore } from 'entities/User/model/useUserStore';

import { useAccount } from 'shared/hooks';

export const useGetNotificationCountQuery = (options: UseQueryOptions<CountNotificationResponse, AxiosError>) => {
  const { address } = useAccount();
  const { userInfo } = useUserStore();

  const timestamp = userInfo?.clientSettings?.notificationLastSeenAt;

  return useQuery<CountNotificationResponse, AxiosError>(
    [`notification-count-${address}-${timestamp}`],
    () => getNotificationCount({ address: address!, timestamp }),
    {
      cacheTime: 60 * 1000,
      enabled: !!address,
      refetchInterval: 60 * 1000,
      staleTime: 60 * 1000,
      ...options,
    },
  );
};
