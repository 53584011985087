import { useQuery } from '@tanstack/react-query';

import { getRates } from '../api/queries';

import { ratesStore } from './ratesStore';

export const useGetRatesQuery = () => {
  const result = useQuery(['rates'], () => getRates(), {
    refetchInterval: 50_000,
    onSuccess(data) {
      ratesStore.setRates(data);
    },
  });

  return result;
};
