import React from 'react';

import { useGetDualTokenById } from 'entities/Token/model';

import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

export const DualTokenIcon: React.FC<React.ComponentProps<typeof CurrencyIcon>> = (props) => {
  const { ticker } = props;

  const result = useGetDualTokenById(ticker?.toLowerCase());

  return <CurrencyIcon {...props} url={result?.logoURI} />;
};
