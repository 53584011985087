/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';

import { getRateBySymbol } from '../lib/getRateBySymbol';

import { ratesStore } from './ratesStore';
import { RateProduct } from './types';

type RateArgs = {
  from: string | undefined;
  inputTicker?: string;
  product?: RateProduct;
  skip?: boolean;
  to: string | undefined;
};

export const useRate = ({ from, skip = false, to, inputTicker, product }: RateArgs) => {
  const symbol = from && to ? `${from?.toLowerCase()}/${to?.toLowerCase()}` : '';

  useEffect(() => {
    if (!symbol || skip) {
      return;
    }

    ratesStore.rateSubscribe(symbol, product);

    return () => {
      ratesStore.rateUnsubscribe(symbol, product);
    };
  }, [symbol, skip, from, to]);

  const formattedRate = useMemo(
    () => getRateBySymbol(ratesStore.rates[product || RateProduct.DEFAULT][symbol]!, symbol, inputTicker),
    [
      ratesStore.rates[product || RateProduct.DEFAULT][symbol]?.mid,
      ratesStore.rates[product || RateProduct.DEFAULT][symbol]?.ask,
      ratesStore.rates[product || RateProduct.DEFAULT][symbol]?.bid,
      symbol,
      inputTicker,
      from,
      to,
    ],
  );

  return formattedRate;
};
