import { Tooltip as MUTooltip, TooltipProps } from '@mui/material';
import React, { CFC } from 'react';

import { Box } from '../Box';

type Props = TooltipProps & { disabled?: boolean };

export const Tooltip: CFC<Props> = ({ children, disabled, ...props }) => {
  if (disabled) return children;

  return (
    <MUTooltip arrow placement="top" enterTouchDelay={0} leaveDelay={0} leaveTouchDelay={2500} {...props}>
      <Box>{children}</Box>
    </MUTooltip>
  );
};
