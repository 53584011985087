import { Skeleton } from '@mui/material';
import { formatAmount } from '@rehold-io/formatters';
import { useTranslation } from 'react-i18next';

import { DualCloseCalculateItemType } from 'entities/Dual';
import { DualTokenIcon, useGetDualTokensQuery } from 'entities/Token';

import { Box, Text } from 'shared/ui';

interface DualChartCalculationRowProps {
  active?: boolean;
  isLoading?: boolean;
  row: DualCloseCalculateItemType;
}

export const DualChartCalculationRow: React.FC<DualChartCalculationRowProps> = ({ active = true, isLoading, row }) => {
  const { t } = useTranslation();
  const { tokens } = useGetDualTokensQuery();

  return (
    <Box flexDirection="row" justifyContent="space-between" sx={{ opacity: active ? 1 : 0.2 }}>
      <Box>
        <Text text="app-12-medium" color="secondary-03" mb={4}>
          {t('charts.totalReceive')}
        </Text>
        <Text text="app-12-medium" color="white-01" display="flex" gap="4px">
          {isLoading ? (
            <Skeleton
              width={48}
              height={15}
              sx={{ bgcolor: '#864DF7', borderRadius: '4px', transform: 'none' }}
              animation="wave"
            />
          ) : (
            <>
              {formatAmount({
                symbol: row.outputTicker,
                value: row.outputAmount,
              })}
            </>
          )}
          <Text text="app-12-medium" color="white-01">
            {tokens[row.outputTicker]?.symbol}
          </Text>
        </Text>
      </Box>
      <Box flexDirection="row" flexBasis={130} flexShrink={0} flexGrow={0}>
        <Box alignSelf="center" mr={8}>
          <DualTokenIcon ticker={row.outputTicker} size={24} />
        </Box>
        <Box>
          <Text text="app-12-medium" color="secondary-03" mb={4}>
            {t('charts.profit')}
          </Text>

          {isLoading ? (
            <Skeleton
              width={48}
              height={15}
              sx={{ bgcolor: '#864DF7', borderRadius: '4px', transform: 'none' }}
              animation="wave"
            />
          ) : (
            <Text text="app-12-medium" color="primary-01">
              +
              {formatAmount({
                symbol: row.outputTicker,
                value: row.profit,
              })}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};
