import { AxiosError } from 'axios';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useOnrampQuote } from 'entities/Onramp/lib/hooks';
import { OnrampFiatCurrency, OnrampFiatCurrencyFieldValue, OnrampValidationError } from 'entities/Onramp/model';

import { Box, Text } from 'shared/ui';
import { StyledText } from 'shared/ui/Error/styled';

import { OnrampFiatInput } from '../OnrampFiatInput';
import { OnrampFiatSelect } from '../OnrampFiatSelect';

interface Props {
  disabled?: boolean;
  onChange: (value: OnrampFiatCurrencyFieldValue) => void;
  title: string;
  value: OnrampFiatCurrencyFieldValue;
}

const ERROR_SMALL_SOURCE_AMOUNT = 'Source amount should be greater';

export const OnrampFiatField: FC<Props> = ({ title, disabled = false, value, onChange }) => {
  const { t } = useTranslation();

  const { error } = useOnrampQuote();

  const errorSource = (error as AxiosError<OnrampValidationError>)?.response?.data._error;

  const errorText = errorSource?.message.includes(ERROR_SMALL_SOURCE_AMOUNT)
    ? t('onramp.error.sourceAmount', {
        amount: errorSource.params?.amount,
        ticker: value.currency?.ticker.toUpperCase(),
      })
    : errorSource?.message;

  const handleAmountChange = (amount: string) => {
    onChange({
      ...value,
      amount,
    });
  };
  const handleCurrencyChange = (currency: OnrampFiatCurrency | null) => {
    onChange({
      ...value,
      currency,
    });
  };

  return (
    <Box border={1} borderColor="secondary-02" borderRadius={16} padding={16} gap={2} zIndex="unset">
      <Text text="app-12-medium" style={{ opacity: 0.4 }}>
        {title}
      </Text>
      <Box zIndex="unset" flexDirection="row" alignItems="center">
        <OnrampFiatInput
          value={value.amount}
          disabled={disabled}
          handleChange={({ value }) => handleAmountChange(value)}
        />
        <OnrampFiatSelect value={value.currency} onSelect={handleCurrencyChange} />
        {errorSource && errorText && (
          <StyledText
            $align="left"
            className="triangle-up"
            position="absolute"
            bottom={0}
            zIndex={2}
            borderRadius="8px"
            px={8}
            py={4}
            bgColor="red-01"
            text="app-12-regular"
            left={0}
            right="unset"
            maxWidth="320px"
          >
            {errorText}
          </StyledText>
        )}
      </Box>
      <Box minHeight={18} flexDirection="row" alignItems="center" justifyContent="space-between" />
    </Box>
  );
};
