import { contextLinesIntegration, extraErrorDataIntegration } from '@sentry/integrations';
import { init, BrowserTracing, captureException, reactRouterV6Instrumentation } from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { Logger } from './logger';

const INTERVAL_MS = 1000 * 60;
const environment = process.env.REACT_APP_ENV || 'dev';

export class SentryLogger implements Logger {
  private events: Map<string, number> = new Map([]);

  constructor() {
    if (process.env.REACT_APP_SENTRY_RELEASE && process.env.REACT_APP_SENTRY_DSN)
      init({
        beforeBreadcrumb(breadcrumb, hint) {
          if (breadcrumb.category === 'xhr') return null;
          if (breadcrumb.level === 'warning' && breadcrumb.category === 'console') return null;
          if (breadcrumb.level === 'log' && breadcrumb.category === 'console') return null;

          const get = getFindBreadCrumbs();
          const dataId = get(hint?.event?.target);

          if (breadcrumb.category === 'ui.click' && dataId) {
            breadcrumb.message = dataId;
          }

          return breadcrumb;
        },
        denyUrls: [
          // Chrome extensions
          /extension/i,
          /^chrome:\/\//i,
        ],
        dist: process.env.REACT_APP_SENTRY_RELEASE,
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment,
        ignoreErrors: [
          /^UserRejectedRequestError/i,
          /User rejected/i,
          /User canceled/i,
          /No matching key/i,
          /Loading chunk/i,
          /Cannot redefine property: ethereum/i,
          /No tab with id/i,
        ],
        integrations: [
          new BrowserTracing({
            routingInstrumentation: reactRouterV6Instrumentation(
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes,
            ),
          }),
          extraErrorDataIntegration({ captureErrorCause: true, depth: 5 }),
          contextLinesIntegration(),
        ],
        release: process.env.REACT_APP_SENTRY_RELEASE,
        autoSessionTracking: false,
      });

    if (INTERVAL_MS) {
      window.setInterval(() => {
        this.cleanEvents();
      }, INTERVAL_MS);
    }
  }

  private canSend(key: string) {
    if (process.env.NODE_ENV !== 'production' || !process.env.REACT_APP_SENTRY_DSN) {
      return false;
    }

    if (!INTERVAL_MS) {
      return true;
    }

    const now = Date.now();
    const lastTime = this.events.get(key);

    const isRecent = Boolean(lastTime && now - lastTime < INTERVAL_MS);

    this.events.set(key, now);

    return !isRecent;
  }

  private cleanEvents() {
    const now = Date.now();

    for (const [key, lastTime] of this.events.entries()) {
      if (now - lastTime > INTERVAL_MS) {
        this.events.delete(key);
      }
    }
  }

  public error(exception?: any, extraInfo?: any) {
    console.error(exception);
    const key = exception?.message || exception?.toString();

    if (this.canSend(key)) {
      const isError = exception instanceof Error;
      if (!isError) {
        captureException('Error', { contexts: exception, extra: extraInfo });
        return;
      }

      captureException(exception);
    }
  }

  public log(...args: any[]) {
    console.log(args);
  }
}

const getFindBreadCrumbs = () => {
  let count = 0;
  function findBreadCrumbs(target: HTMLElement | null): null | string {
    count++;
    if (!target) return null;
    if (target.dataset.id) return target.dataset.id;
    if (count === 5) return null;

    return findBreadCrumbs(target.parentElement);
  }

  return findBreadCrumbs;
};
