import { styled } from '@mui/material';

type Props = {
  $disabled?: boolean;
};

export const StyledContainer = styled('span')<Props>(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '12px',
  // cursor: $disabled ? 'not-allowed' : 'pointer',
  width: '100%',
}));
