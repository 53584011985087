import Step from 'shepherd.js/src/types/step';
import Tour from 'shepherd.js/src/types/tour';

import { ShepherdButtonWithType } from './types';

export const getItems = (values: string[]) =>
  values.reduce((acc, item, index) => {
    if (!document?.querySelector(`.${item}`)) return acc;

    acc.push(index);
    return acc;
  }, [] as number[]);

export const addSteps = (steps: Array<Step.StepOptions>, tour: Tour) => {
  if (!steps.length) {
    return [];
  }

  const parsedStepsforAction = steps.map((step: Step.StepOptions): Step.StepOptions => {
    const { buttons } = step;

    if (buttons) {
      step.buttons = buttons.map((button: ShepherdButtonWithType) => {
        const { action, classes, disabled, label, secondary, text, type } = button;
        return {
          action: type ? tour[type] : action,
          classes,
          disabled,
          label,
          secondary,
          text,
          type,
        };
      });
    }

    return step;
  });

  return parsedStepsforAction.forEach((step: any) => tour.addStep(step));
};
