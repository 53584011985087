import { useMemo } from 'react';

import { DualType } from 'entities/Dual';
import { useRate } from 'entities/Rates';

export const useDualChartData = (dual: DualType) => {
  const chartData = dual.chartData
    .map((d) => ({ createdAt: Date.parse(d.createdAt as any), price: +d.price }))
    .sort((a, b) => a.createdAt - b.createdAt);
  const dualInitialPrice = +dual.initialPrice;
  const dualStartedAt = Date.parse(dual.startedAt as any);
  const dualFinishAt = Date.parse(dual.finishAt as any);
  const dualFinished = !!dual.closedPrice || dualFinishAt < Date.now();

  const rate = useRate({
    from: dual.baseTicker,
    skip: dualFinished,
    to: dual.quoteTicker,
    inputTicker: dual.inputTicker,
  });

  const lastPoint = useMemo(
    () =>
      dualFinished || !rate?.price
        ? chartData[chartData.length - 1]
        : {
            createdAt: rate?.createdAt || Date.now(),
            price: rate?.price,
          },
    [dualFinished, rate, chartData],
  );

  const chartDataWithLastPoint = useMemo(() => {
    if (lastPoint) {
      return [...chartData, lastPoint];
    }

    return chartData;
  }, [chartData, lastPoint]);

  return { chartData, chartDataWithLastPoint, dualFinishAt, dualFinished, dualInitialPrice, dualStartedAt, lastPoint };
};
