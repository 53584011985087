import React from 'react';

import styles from './Spinner.module.scss';
import { ReactComponent as Circle } from './images/circle.svg';

interface SpinnerProps {
  size?: number;
}

export const Spinner: React.FC<SpinnerProps> = ({ size = 40 }) => (
  <Circle className={styles.spinner} height={size} width={size} />
);
