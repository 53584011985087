import { useWatch } from 'react-hook-form';

import { useDebounce } from 'shared/hooks';

import { useSwapForm } from './useSwapForm';

export const SWAP_INPUT_DEBOUNCE = 300;

export const useSwapDebouncedAmount = () => {
  const { form } = useSwapForm();
  const values = useWatch({ control: form.control });

  const debounced = useDebounce(values.fromAmount, SWAP_INPUT_DEBOUNCE);
  return debounced;
};
