import { UseQueryResult } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import React, { CFC, useEffect, useMemo, useState } from 'react';
import { useBalance } from 'wagmi';

import { useGetCacheStorageTokens, useGetSwapStorageTokens, useGetSwapTokensQuery } from 'entities/Token/model/index';
import { UserToken } from 'entities/Token/model/types';
import { useBalances } from 'entities/User';

import { NATIVE_TOKEN_ADDRESS } from 'shared/config';
import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';

type Props = {
  cache?: UserToken[];
  data?: UserToken[];
  refetch: () => void;
  refetchBalances: () => void;
  refetchCache: () => void;
  refetchNetwork: () => void;
  refetchStorage: () => void;
  tokens: UserToken[];
  tokensCache: UserToken[];
} & Omit<Omit<UseQueryResult<UserToken, unknown>, 'refetch'>, 'data'>;

export const SwapTokensContext = React.createContext({} as Props);

export const SwapTokensProvider: CFC = observer(({ children }) => {
  const { address } = useAccount();
  const { selectedChainId: chainId } = useNetwork();

  const { data: queryData, refetch: refetchNetwork, ...queryRest } = useGetSwapTokensQuery();

  const { data: storageData, refetch: refetchStorage } = useGetSwapStorageTokens();

  const { data: cacheData, refetch: refetchCache } = useGetCacheStorageTokens();

  const [data, setData] = useState<UserToken[] | undefined>();
  const [cache, setCache] = useState<UserToken[] | undefined>();

  const [balancesConfig, setBalancesConfig] = useState<any[]>();
  const { balances, refetch: refetchTokenBalances } = useBalances(balancesConfig || [], {
    enabled: !!address && !!balancesConfig && !!data,
  });
  const { data: nativeBalance, refetch: refetchNativeBalance } = useBalance({ address, chainId, enabled: !!address });

  useEffect(() => {
    setData(
      queryData &&
        storageData && [
          ...queryData.map((token) => ({ ...token, balance: null })),
          ...storageData.map((token) => ({ ...token, balance: null })),
        ],
    );
  }, [JSON.stringify(queryData), JSON.stringify(storageData)]);

  useEffect(() => {
    setCache(cacheData.map((token) => ({ ...token, balance: null })));
  }, [JSON.stringify(cacheData)]);

  useEffect(() => {
    if (address && data) {
      const fetchConfig = [...data, ...(cache || [])]
        .filter((token) => token.address !== NATIVE_TOKEN_ADDRESS)
        .map((token) => ({
          chainId,
          token: token.address,
          user: address,
        }));

      setBalancesConfig(fetchConfig);
    }
  }, [address, JSON.stringify(data), JSON.stringify(cache)]);

  useEffect(() => {
    if (balances && data) {
      setData((prev) =>
        prev?.map((token) => ({ ...token, balance: balances[`${chainId}:${token.address}`]?.toString() })),
      );
    }
    if (balances && cache) {
      setCache((prev) =>
        prev?.map((token) => ({ ...token, balance: balances[`${chainId}:${token.address}`]?.toString() })),
      );
    }
    if (nativeBalance && data) {
      setData((prev) =>
        prev?.map((token) => ({
          ...token,
          balance: token.address === NATIVE_TOKEN_ADDRESS ? nativeBalance.value.toString() : token.balance,
        })),
      );
    }
  }, [balances, nativeBalance]);

  const refetch = () => {
    refetchNetwork();
    refetchStorage();
    refetchCache();
  };

  const refetchBalances = () => {
    refetchNativeBalance();
    refetchTokenBalances();
  };

  const value = useMemo(
    () => ({
      ...queryRest,
      refetchNetwork,
      refetchStorage,
      refetchCache,
      refetchBalances,
      refetch,
      data,
      cache,
      tokens: data || [],
      tokensCache: cache || [],
    }),
    [data, queryRest, refetch, refetchCache, refetchStorage, refetchNetwork],
  );

  return <SwapTokensContext.Provider value={value as Props}>{children}</SwapTokensContext.Provider>;
});
