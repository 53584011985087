import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useUserStore } from 'entities/User';

import { Box, BoxProps, Text } from 'shared/ui';

import { SubscribeForm } from '../SubscribeForm';

export const NotificationDualsCard: React.FC<BoxProps> = observer(({ ...props }) => {
  const { t } = useTranslation();

  const { userInfo } = useUserStore();

  if (userInfo?.email) return null;

  return (
    <Box {...props} backgroundColor="secondary-02" padding={24} borderRadius={24} gap="16px">
      <Box gap="8px">
        <Text text="app-18-medium"> {t('notifications.title')}</Text>
        <Text text="app-14-medium" opacity={0.4}>
          {t('notifications.subscribeDescription')}
        </Text>
      </Box>
      <SubscribeForm fromLocation="duals" />
    </Box>
  );
});
