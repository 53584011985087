import { Theme, styled } from '@mui/material';
import React, { useCallback, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { OnrampCryptoCurrency } from 'entities/Onramp/model';

import { CHAIN_NAME_MAP, POPULAR_CODES } from 'shared/config';
import { shouldForwardProp } from 'shared/lib';
import { Box, Text } from 'shared/ui';

import { formatSnakeCase } from '../../../../shared/lib/formats/format';

import { OnrampAssetIcon } from './OnrampAssetIcon';

type BoxProps = {
  $borderColor: keyof Theme['colors'];
};

const StyledBox = styled(Box, { shouldForwardProp })<BoxProps>(({ $borderColor, theme }) => ({
  borderColor: `var(--currency-pair-color, ${theme.colors[$borderColor]})`,
  transition: 'all 0.2s ease',
}));

type Props = {
  className?: string;
  currency: OnrampCryptoCurrency;
  onPress: (selected: OnrampCryptoCurrency) => void;
  selected?: boolean;
};

export const OnrampAsset: FC<Props> = ({ className, currency, selected = false, onPress }) => {
  const { t } = useTranslation();

  const handlePress = useCallback(() => {
    onPress(currency);
  }, [currency, onPress]);

  return (
    <>
      <li className={className} onClick={handlePress} style={{ opacity: selected ? 0.5 : 1 }}>
        <Box flexDirection="row" alignItems="center" flexBasis={0} flexGrow={3}>
          <Box flexDirection="row">
            <StyledBox position="relative" zIndex={1} borderRadius={32} $borderColor="background-01">
              <OnrampAssetIcon currency={currency} size={32} />
            </StyledBox>
          </Box>{' '}
          <Box ml={8} flexBasis={0} flexGrow={1}>
            <Text color="white-01">{currency.name}</Text>
            <Text color="gray-01">{currency.ticker.toUpperCase()}</Text>
          </Box>
        </Box>
        <Box flexDirection="column" alignItems="end" gap={4} flexBasis={0} flexGrow={2}>
          <Text opacity={0.5} width="100%" maxWidth="100%" lineClamp={1} textOverflow="ellipsis" wordBreak="break-all">
            {CHAIN_NAME_MAP[currency.chain] || formatSnakeCase(currency.chain)}
          </Text>
          {POPULAR_CODES.includes(currency.code) && (
            <Box backgroundColor="secondary-03" borderRadius={20} paddingX={6} paddingY={2}>
              <Text text="app-12-medium">{t('onramp.popular')}</Text>
            </Box>
          )}
        </Box>
      </li>
    </>
  );
};
