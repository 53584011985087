import React from 'react';

import { IconByUrl } from '../IconByUrl';

interface CurrencyIconProps {
  className?: string;
  size?: number;
  ticker: string;
  url?: string;
}

export const CurrencyIcon: React.FC<CurrencyIconProps> = ({ className, size = 32, ticker, url }) => {
  if (url) {
    return <IconByUrl alt={ticker} url={url} size={size} className={className} />;
  }

  if (!ticker) {
    return <div style={{ height: size, width: size }} className={className} />;
  }

  return (
    <IconByUrl alt={ticker} url={`/static/tickers/${ticker?.toLowerCase()}.svg`} size={size} className={className} />
  );
};
