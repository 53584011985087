import { BitkeepConnector } from '@rehold-io/connectkit';

import { supportedChains } from 'shared/config';

import { getImageWallet } from '../../getImages';
import { AppWallet } from '../../types';

export const bitkeep: AppWallet = {
  connector: new BitkeepConnector({
    chains: supportedChains as any,
  }),
  iconPath: getImageWallet('bitkeep'),
  id: 'BitKeep',
  name: 'BitKeep',
};
