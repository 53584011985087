import BigNumber from 'bignumber.js';

import { requester } from 'shared/api';
import { FLEX_TARIFF_RELEASED_AT } from 'shared/config';

import {
  AutoReplayRequestArgs,
  ClaimDualRequestArgs,
  ClaimDualResponse,
  DualAssetsResponse,
  DualCountResponse,
  DualOverviewResponse,
  DualRequestArgs,
  DualSettings,
  DualSettingsRequestArgs,
  DualTariffRequestArgs,
  DualTariffsResponse,
  DualType,
  GetDualRequestArgs,
  ReplayRequestArgs,
  ReplayRequestResponse,
} from '../model';

export const getDualAssets = () => requester.get<DualAssetsResponse>(`/api/v2/duals/assets`).then((res) => res.data);

export const getDualTariffs = ({ chainId }: Pick<DualRequestArgs, 'chainId'>) =>
  requester.get<DualTariffsResponse>(`/api/v2/duals/${chainId}/tariffs`).then((res) => res.data);

export const getDualTariff = ({ chainId, baseToken, quoteToken }: DualTariffRequestArgs) =>
  requester
    .get<DualTariffsResponse>(`/api/v2/duals/${chainId}/tariffs`, { params: { baseToken, quoteToken } })
    .then((res) => res.data);

export const getDualsOverview = ({ chainId, address }: DualRequestArgs) =>
  requester.get<DualOverviewResponse>(`/api/v2/duals/${chainId}/${address}/overview`).then((res) => res.data);

export const getCountDuals = ({ chainId, address }: DualRequestArgs) =>
  requester.get<DualCountResponse>(`/api/v2/duals/${chainId}/${address}/count`).then((res) => res.data);

export const getSettingsDuals = ({ chainId }: DualSettingsRequestArgs) =>
  requester.get<DualSettings>(`/api/v2/duals/${chainId}/settings`).then((res) => res.data);

export const getDual = ({ chainId, id }: GetDualRequestArgs) =>
  requester.get<DualType>(`/api/v2/duals/${chainId}/${id}`).then(({ data: item }) => ({
    ...item,
    apr: FLEX_TARIFF_RELEASED_AT > item.startedAt ? BigNumber(item.apr).toFixed(6) : BigNumber(item.apr).toFixed(4),
  }));

export const getOpenedDuals = ({ chainId, address }: DualRequestArgs) =>
  requester.get<DualType[]>(`/api/v2/duals/${chainId}/${address}/opened`).then(({ data }) =>
    data.map((item) => ({
      ...item,
      apr: FLEX_TARIFF_RELEASED_AT > item.startedAt ? BigNumber(item.apr).toFixed(6) : BigNumber(item.apr).toFixed(4),
    })),
  );

export const getClosedDuals = ({ chainId, address }: DualRequestArgs) =>
  requester.get<DualType[]>(`/api/v2/duals/${chainId}/${address}/closed`).then(({ data }) =>
    data.map((item) => ({
      ...item,
      apr: FLEX_TARIFF_RELEASED_AT > item.startedAt ? BigNumber(item.apr).toFixed(6) : BigNumber(item.apr).toFixed(4),
    })),
  );

export const getClaimedDuals = ({ chainId, address }: DualRequestArgs) =>
  requester.get<DualType[]>(`/api/v2/duals/${chainId}/${address}/claimed`).then((res) => res.data);

export const setAutoReplay = ({ autoReplay, chainId, id, signature }: AutoReplayRequestArgs) =>
  requester.put(
    `/api/v2/duals/${chainId}/${id}`,
    {
      autoReplay,
    },
    {
      headers: {
        Authorization: signature,
      },
    },
  );

export const replayDual = ({ chainId, id, signature }: ReplayRequestArgs) =>
  requester.post<ReplayRequestResponse>(
    `/api/v2/duals/${chainId}/${id}/replay`,
    {},
    {
      headers: {
        Authorization: signature,
      },
    },
  );

export const claimDual = ({ chainId, id, signature }: ClaimDualRequestArgs) =>
  requester.post<ClaimDualResponse>(
    `/api/v2/duals/${chainId}/${id}/claim`,
    {},
    {
      headers: {
        Authorization: signature,
      },
    },
  );
