import { MetaMaskConnector } from 'wagmi/connectors/metaMask';

import { supportedChains } from 'shared/config';

import { getImageWallet } from '../../getImages';
import { AppWallet } from '../../types';

export const metamask: AppWallet = {
  connector: new MetaMaskConnector({ chains: supportedChains }),
  iconPath: getImageWallet('metamask'),
  id: 'metaMask', // used from MetaMaskConnector
  name: 'Metamask',
};
