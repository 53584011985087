import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import { getNotifications } from 'entities/User/api/queries';
import { NotificationsResponse } from 'entities/User/model/types';

import { useAccount } from 'shared/hooks';

export const useGetNotificationsQuery = () => {
  const { address } = useAccount();
  const { i18n } = useTranslation();

  return useQuery<NotificationsResponse, AxiosError>(
    [`notification-list-${address}`, i18n.language],
    () => getNotifications({ address: address!, language: i18n.language }),
    {
      cacheTime: 60 * 1000,
      enabled: !!address,
      staleTime: 60 * 1000,
    },
  );
};
