import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Onramp as OnrampWidget } from 'widgets/Onramp';

import { PUBLIC_URL } from 'shared/config';
import { PathPatterns } from 'shared/lib';
import { PageContent } from 'shared/ui';

export const Onramp = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('onramp.metaTitle')}>
        <link rel="canonical" href={`${PUBLIC_URL}${PathPatterns.Buy}`} />
      </Helmet>

      <PageContent px={0} style={{ overflow: 'visible' }}>
        <OnrampWidget />
      </PageContent>
    </>
  );
};
