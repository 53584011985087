import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';

import { supportedChains } from 'shared/config';

import { getImageWallet } from '../../getImages';
import { AppWallet } from '../../types';

export const coinbase: AppWallet = {
  connector: new CoinbaseWalletConnector({
    chains: supportedChains,
    options: {
      appName: 'ReHold',
      headlessMode: true,
    },
  }),
  iconPath: getImageWallet('coinbase'),
  id: 'coinbaseWallet',
  name: 'Coinbase',
};
