import { Dialog, styled } from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-container': {
    justifyContent: 'flex-end',
  },

  '.MuiPaper-root': {
    background: theme.colors['background-01'],
    borderLeft: `1px solid ${theme.colors['secondary-02']}`,
    height: '100%',
    margin: 0,
    maxHeight: '100%',
    maxWidth: '334px',
    width: '100%',
  },

  padding: '0',

  [theme.breakpoints.down('tablet')]: {
    '.MuiPaper-root': {
      maxWidth: '100%',
      width: '100vw',
    },
  },
}));
