import { toast } from 'react-hot-toast';

import { Attention, AttentionProps } from 'shared/ui/Toasts/Attention';

const uniqMap = new Map<any, boolean>();

export const notifyAttention = (options: AttentionProps) => {
  const optionsJson = JSON.stringify(options);
  if (uniqMap.has(optionsJson)) {
    return;
  }
  uniqMap.set(optionsJson, true);
  // we can show the same error if it happens in 10sec
  setTimeout(() => {
    uniqMap.delete(optionsJson);
  }, 2_000);

  toast.custom((t) => <Attention onClose={() => toast.dismiss(t.id)} {...options} {...t} />, {
    duration: 2_000,
  });
};
