import React from 'react';

import { logger } from 'shared/lib/logger';

class ErrorBoundary extends React.Component<
  React.PropsWithChildren<{ fallback: (reset: () => void) => React.ReactNode }>
> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    logger.error(error, errorInfo);
  }

  handleClick = () => {
    this.setState({
      hasError: false,
    });
  };

  render() {
    if (this.state.hasError) {
      return this.props.fallback(this.handleClick);
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
