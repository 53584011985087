import BigNumber from 'bignumber.js';

export const formatSuffixNumber = (num: number): string => {
  const suffixes = ['', 'k', 'M', 'B', 'T', 'P', 'E'];

  let index = 0;
  let divisor = 1;

  while (num / divisor >= 1000 && index < suffixes.length - 1) {
    divisor *= 1000;
    index++;
  }

  const formattedNumber =
    BigNumber(num / divisor).toFixed((num % divisor) / divisor > 0.1 && index > 0 ? 1 : 0, BigNumber.ROUND_FLOOR) +
    suffixes[index];

  return formattedNumber;
};
