import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

import { routerAbi } from './abi';
import { AppChain } from './types';

export const arbitrum: AppChain<'arbitrum'> = {
  blockExplorers: {
    default: {
      name: 'ArbiScan',
      url: 'https://arbiscan.io',
    },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  disabled: false,
  id: 42161,
  logo: <CurrencyIcon ticker="arb" size={24} />,
  name: 'Arbitrum',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  network: 'arbitrum',
  tokenStandard: 'Arbitrum',
  renderLogo: (size) => <CurrencyIcon ticker="arb" size={size} />,
  router: {
    abi: routerAbi,
    address: '0xa04295018eb7353a9755a2b227c9203fed3d9b7c',
  },
  rpcUrls: {
    default: {
      http: ['https://endpoints.omniatech.io/v1/arbitrum/one/public'],
    },
    public: {
      http: ['https://endpoints.omniatech.io/v1/arbitrum/one/public'],
    },
  },
  vault: {
    address: '0xde6b4964c4384bcdfa150a4a8be9865c5b91e29c',
  },
  tokens: {
    usdt: { address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9' },
  },
};
