import { InputBase, styled } from '@mui/material';

import { shouldForwardProp } from 'shared/lib';

import { Typography } from '../Text';

type StyledInputProps = {
  $isOutline: boolean;
  $size: 'large' | 'medium' | 'small' | 'tiny';
};

const SIZE = {
  large: '56px',
  medium: '48px',
  small: '40px',
  tiny: '32px',
};

const FONT = {
  large: 'app-16-medium',
  medium: 'app-16-medium',
  small: 'app-16-medium',
  tiny: 'app-14-medium',
} as const;

export const StyledInput = styled(InputBase, { shouldForwardProp })<StyledInputProps>(
  ({ $isOutline, $size, theme }) => ({
    background: theme.colors['background-01'],
    border: `1px solid ${theme.colors[$isOutline ? 'secondary-02' : 'background-01']}`,
    borderRadius: '8px',
    color: theme.colors['white-01'],
    fontFamily: theme.fontFamily,
    fontSize: theme.text[FONT[$size]].fontSize,
    fontWeight: theme.text[FONT[$size]].fontWeight,
    height: SIZE[$size],
    'input::placeholder': {
      color: theme.colors['gray-01'],
      fontFamily: theme.fontFamily,
      fontSize: theme.text[FONT[$size]].fontSize,
      fontWeight: theme.text[FONT[$size]].fontWeight,
      lineHeight: theme.text[FONT[$size]].lineHeight,
    },
    lineHeight: theme.text[FONT[$size]].lineHeight,

    padding: '0 16px',
  }),
);

export const StyledLabel = styled(Typography)(({ theme }) => ({
  background: theme.colors['background-01'],
  left: '8px',
  padding: '2px 8px',
  pointerEvents: 'none',
  position: 'absolute',
  top: '0',
  transform: 'translateY(-50%)',
  zIndex: '1',
}));

export const StyledErrorText = styled(Typography)(() => ({
  '&::before': {
    left: '1rem',
  },

  transform: 'translateY(calc(100% + 8px))',
}));
