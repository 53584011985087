import { formatPrice } from '@rehold-io/formatters';

import { RateObjType } from '../model/types';

export const getRateBySymbol = (rateObj: RateObjType, symbol: string, inputTicker?: string) => {
  const [baseTicker, quoteTicker] = symbol.split('/');

  let price = rateObj?.mid || 1;
  if (baseTicker === inputTicker) {
    price = rateObj?.bid || 1;
  }
  if (quoteTicker === inputTicker) {
    price = rateObj?.ask || 1;
  }

  const priceFormatted = formatPrice({ from: baseTicker, to: quoteTicker, value: price });

  return {
    formatted: {
      price: priceFormatted,
    },
    ready: !!rateObj,
    price,
    createdAt: rateObj?.createdAt,
    ask: rateObj?.ask,
    bid: rateObj?.bid,
    symbol,
  };
};
