import { observer } from 'mobx-react-lite';
import React from 'react';

import { useRate } from 'entities/Rates';

import { useIsMobile } from 'shared/hooks';
import { getShortName } from 'shared/lib';
import { Box, Text } from 'shared/ui';

interface DualRateProps {
  from: string;
  to: string;
}

const COUNT_CHAR_VIEW_START = 3;
const COUNT_CHAR_VIEW_END = 5;
const MIN_COUNT_CHAR_FOR_FORMATTING = 10;

export const DualRate: React.FC<DualRateProps> = observer(({ from, to }) => {
  const rateObj = useRate({ from, to });

  const isMobile = useIsMobile();

  if (!rateObj?.ready) {
    return null;
  }

  return (
    <Box alignItems="center" flexWrap="wrap" flexDirection="row">
      <Text mr={6} sx={{ opacity: '0.6' }} text="app-14-regular">
        {isMobile
          ? getShortName(
              `${rateObj.formatted.price}`,
              COUNT_CHAR_VIEW_START,
              COUNT_CHAR_VIEW_END,
              MIN_COUNT_CHAR_FOR_FORMATTING,
            )
          : rateObj.formatted.price}
      </Text>
    </Box>
  );
});
