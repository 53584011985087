import BN, { BigNumber } from 'bignumber.js';
import { format, intlFormatDistance, isToday, isYesterday, parseISO } from 'date-fns';

import { SHORT_ZEROS_BOUND } from 'shared/config';
import { roundToNearest } from 'shared/lib/helpers';
import { dateFnsLocales } from 'shared/locales';

import { toBigNumber } from './toBigNumber';

export const getFinalDecimals = (decimals: (number | undefined)[] = []) =>
  decimals.find((d) => typeof d !== 'undefined')!;

export const formatWithDecimals = ({
  decimals = [],
  roundingMode,
  value,
}: {
  decimals?: (number | undefined)[];
  roundingMode?: BN.RoundingMode | undefined;
  value: BN.Value;
}) => toBigNumber(value).toFixed(getFinalDecimals(decimals), roundingMode);

export const formatPercent = ({ decimals = 2, value }: { decimals?: number; value: BN.Value }) =>
  formatWithDecimals({ decimals: [decimals, 2], value: toBigNumber(value).multipliedBy(100) });

type FormatDate = 'full-date' | 'full-date-distance';

export const formatDate = (str: string, lang = 'en', type: FormatDate = 'full-date-distance') => {
  const date = parseISO(str);
  const locale = dateFnsLocales[lang] || dateFnsLocales.en;

  if ((isToday(date) || isYesterday(date)) && type === 'full-date-distance') {
    const day = intlFormatDistance(date, new Date(), {
      locale: lang,
      unit: 'day',
    });
    const time = format(date, 'HH:mm');

    return capitalize(`${day} ${time}`);
  }

  return format(date, 'dd.MM.yyyy HH:mm', { locale });
};

export const formatWithCommas = (num: string) => {
  const parts = num.split('.');

  const integerPart = parts[0];
  const decimalPart = parts[1];

  const formattedIntegerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'); // Adds commas between each three digits
  const formattedDecimalPart = decimalPart?.substring(0, 12) || '';

  return `${formattedIntegerPart}${decimalPart ? '.' : ''}${formattedDecimalPart}`;
};

export const formatCurrency = (
  num: number,
  fixed: number | undefined = undefined,
  minDecimals: number | undefined = undefined,
  maxDecimals: number | undefined = undefined,
) => {
  const parts = fixed
    ? roundToNearest(BigNumber(num)).toFixed(fixed).split('.')
    : roundToNearest(BigNumber(num)).toFixed(20, BigNumber.ROUND_CEIL).replace(/0*$/, '').split('.');

  const integerPart = parts[0];
  let decimalPart = parts[1];

  if (maxDecimals !== undefined && decimalPart.length > maxDecimals) {
    decimalPart = decimalPart.substring(0, maxDecimals);
  }
  if (minDecimals !== undefined && decimalPart.length < minDecimals) {
    decimalPart = decimalPart.padEnd(minDecimals, '0');
  }

  const formattedIntegerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'); // Adds commas between each three digits
  const formattedDecimalPart = decimalPart?.substring(0, 12) || '';

  return `$${formattedIntegerPart}${decimalPart ? '.' : ''}${formattedDecimalPart}`;
};

export const formatZeroDecimals = (input: number | string | BigNumber) => {
  const decimalValue = new BigNumber(input);

  const integerPart = decimalValue.integerValue(BigNumber.ROUND_DOWN).toString();

  const decimalsString = decimalValue.toFixed(20).replace(/0*$/, '').split('.')[1] || '';
  const formattedDecimalString =
    (decimalsString.match(/^0+/)?.[0].length || 0) >= SHORT_ZEROS_BOUND
      ? `.0...${decimalsString.replace(/^0+/, '')}`
      : `.${decimalsString}`;
  const significantDecimals = decimalsString.length > 0 ? formattedDecimalString : '';

  const formattedDecimal = `${integerPart}${significantDecimals}`;

  return formattedDecimal;
};

export const formatSnakeCase = (str: string) => {
  const words = str.split('_');
  const titleCaseWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  const titleCasePhrase = titleCaseWords.join(' ');

  return titleCasePhrase;
};

export const formatUnsigZeros = (str: string) => {
  const result = str.replace(/0*$/, '');

  const [, dec] = result.split('.');

  if (!dec) return result.replace('.', '');

  return result;
};

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
