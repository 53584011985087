import { InjectedConnector } from 'wagmi/connectors/injected';

import { supportedChains } from 'shared/config';

import { getImageWallet } from '../../getImages';
import { AppWallet } from '../../types';

export const injected: AppWallet = {
  connector: new InjectedConnector({
    chains: supportedChains,
    options: {},
  }),
  iconPath: getImageWallet('injected'),
  id: 'injected',
  name: 'Injected',
};
