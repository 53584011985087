import BigNumber from 'bignumber.js';

import { MAX_DECIMAL_ROUNDING, ROUNDING_THRESHOLD } from 'shared/config';

export const roundToNearest = (n: BigNumber) => {
  for (let i = 0; i <= MAX_DECIMAL_ROUNDING; i++) {
    const value = BigNumber(n.toFixed(i, BigNumber.ROUND_DOWN));

    if (BigNumber(1).minus(value.div(n)).abs().lte(ROUNDING_THRESHOLD)) return value;
  }

  return BigNumber(0);
};
