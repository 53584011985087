import copy from 'clipboard-copy';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { logger } from 'shared/lib/logger';
import { notifyAttention, notifyHeadliner } from 'shared/lib/notifications';

export const useCopy = (text: string | undefined) => {
  const { t } = useTranslation();

  const handler = useCallback(() => {
    if (!text) {
      logger.error(new Error('Text not provided to copy'));
      notifyAttention({
        text: t('common.wrong'),
      });
      return;
    }

    copy(text)
      .then(() => {
        notifyHeadliner({
          text,
          title: t('common.copied'),
        });
      })
      .catch((error) => {
        logger.error(error);
        notifyAttention({
          text: t('common.wrong'),
        });
      });
  }, [text, t]);

  return handler;
};
