import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PathPatterns } from 'shared/lib';

import { OnrampTab } from './ui/OnrampTab';

export const Onramp = observer(() => {
  const navigate = useNavigate();

  return <OnrampTab tab={PathPatterns.Buy} onTabChange={(tab) => navigate(tab)} />;
});
