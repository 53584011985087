import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

import { routerAbi } from './abi';
import { AppChain } from './types';

export const avalanche: AppChain<'avalanche'> = {
  blockExplorers: {
    default: {
      name: 'SnowTrace',
      url: 'https://snowtrace.dev',
    },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  id: 43114,
  logo: <CurrencyIcon ticker="avax" size={24} />,
  name: 'Avalanche',
  nativeCurrency: {
    decimals: 18,
    name: 'AVAX',
    symbol: 'AVAX',
  },
  network: 'avalanche',
  tokenStandard: 'ERC-20',
  renderLogo: (size) => <CurrencyIcon ticker="avax" size={size} />,
  router: {
    abi: routerAbi,
    address: '0xa04295018eb7353a9755a2b227c9203fed3d9b7c',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.ankr.com/avalanche'],
    },
    public: {
      http: ['https://rpc.ankr.com/avalanche'],
    },
  },
  vault: {
    address: '0xde6b4964c4384bcdfa150a4a8be9865c5b91e29c',
  },
};
