import { observer } from 'mobx-react-lite';
import React, { CFC, useMemo, createContext } from 'react';

import { DualTariff, DualTariffsResponse } from 'entities/Dual/model';
import { useGetDualTariffsQuery } from 'entities/Dual/model/hooks';
import { useGetDualTokensQuery } from 'entities/Token';

import { useNetwork } from 'shared/hooks/network';

import { mapToTariffs } from '../../../model/mapResponseTariffsToTariffs';

import { formatTariff } from './formats';

type Props = {
  error?: unknown;
  isFetching: boolean;
  isLoading: boolean;
  refetch: () => void;
  response: DualTariffsResponse;
  responseMap: Map<string, DualTariffsResponse[0]>;
  tariffs: DualTariff[];
  tariffsMap: Map<string, DualTariff>;
};

export const DualTariffsContext = createContext({} as Props);

export const DualTariffsProvider: CFC = observer(({ children }) => {
  const { tokens } = useGetDualTokensQuery();

  const { network } = useNetwork();

  const { data, error, isFetching, isLoading, refetch } = useGetDualTariffsQuery();

  const tariffs = useMemo(() => {
    const tariffs = mapToTariffs(data, tokens);

    const tariffsWithNativeCurrency = tariffs.reduce<typeof tariffs>((acc, tariff) => {
      const newTarrif = formatTariff(tariff, network);

      if (newTarrif) {
        acc.push(newTarrif);
      }

      acc.push(tariff);
      return acc;
    }, []);

    return tariffsWithNativeCurrency;
  }, [data, network, tokens]);

  const value = useMemo(
    () => ({
      error,
      isFetching,
      isLoading,
      refetch,
      response: data || [],
      responseMap: new Map(data?.map((v) => [v.id as string, v]) || []),
      tariffs,
      tariffsMap: new Map(tariffs.map((v) => [v.id as string, v])),
    }),
    [tariffs, refetch, isLoading, error, isFetching, data],
  );

  return <DualTariffsContext.Provider value={value}>{children}</DualTariffsContext.Provider>;
});
