import { createTheme, keyframes } from '@mui/material/styles';

import { THEME_BREAKPOINTS, THEME_COLORS, THEME_SHADOWS, THEME_TYPOGRAPHY } from 'shared/tokens';

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    colors: typeof THEME_COLORS;
    fontFamily: string;
    shadows: typeof THEME_SHADOWS;
    text: typeof THEME_TYPOGRAPHY;
  }

  interface ThemeOptions {
    colors?: typeof THEME_COLORS;
    fontFamily?: string;
    shadow?: typeof THEME_SHADOWS;
    text?: typeof THEME_TYPOGRAPHY;
  }
}

const wave = keyframes`
  0 {
    transform: translateX(-100%) rotate(30deg);
  }
  70% {
    transform: translateX(100%) rotate(30deg);
  }
  100% {
    transform: translateX(100%) rotate(30deg);
  }
`;

const theme = createTheme({
  breakpoints: {
    values: {
      ...THEME_BREAKPOINTS,
    },
  },
  colors: THEME_COLORS,
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: 5,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          '&::after': {
            animation: `${wave} 1s linear 0.5s infinite`,
            background: 'linear-gradient( 90deg, transparent, rgba(255, 255, 255, 0.3), transparent )',
            filter: 'blur(6px)',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: THEME_COLORS['secondary-04'],
        },
        tooltip: {
          background: THEME_COLORS['secondary-04'],
          borderRadius: '8px',
          filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.5))',
          fontFamily: '"Roboto Mono", Arial, sans-serif',
          fontSize: THEME_TYPOGRAPHY['app-12-regular'].fontSize,
          fontWeight: THEME_TYPOGRAPHY['app-12-regular'].fontWeight,
          lineHeight: THEME_TYPOGRAPHY['app-12-regular'].lineHeight,
          padding: '8px 12px',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          label: 'label',
          p: 'p',
          span: 'span',
        },
      },
    },
  },
  fontFamily: '"Roboto Mono", Arial, sans-serif',
  shadow: THEME_SHADOWS,
  shape: {
    borderRadius: 1,
  },

  spacing: 1,

  text: THEME_TYPOGRAPHY,
});

export default theme;
