import { Address } from 'wagmi';

import { LocalStorage } from 'shared/lib';

export const storageSignature = {
  get(address: Address) {
    const sign = LocalStorage.get<Record<string, string>>('rehold_signature')?.[address];
    return sign || null;
  },
  set(sign: string, address: Address) {
    LocalStorage.set('rehold_signature', { [address]: sign });
  },
};
