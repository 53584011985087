export enum OnrampProviderType {
  MOONPAY = 'moonpay',
}

export type OnrampCryptoCurrency = {
  chain: string;
  chainId: number;
  code: string;
  name: string;
  ticker: string;
};

export type OnrampFiatCurrency = {
  code: string;
  name: string;
  ticker: string;
};

export type OnrampCryptoCurrencyFieldValue = {
  amount: string;
  currency: OnrampCryptoCurrency | null;
  isLoading?: boolean;
};

export type OnrampFiatCurrencyFieldValue = {
  amount: string;
  currency: OnrampFiatCurrency | null;
  isLoading?: boolean;
};
