import { FC } from 'react';
import { Toast } from 'react-hot-toast';

import { BasicToast } from '../BasicToast';
import { BasicToastBody } from '../BasicToastBody';

export interface HeadlinerProps {
  text: string;
  title: string;
}

export const Headliner: FC<Toast & HeadlinerProps> = ({ text, title, ...rest }) => (
  <BasicToast toastProps={rest}>
    <BasicToastBody variant="info" title={title} text={text} />
  </BasicToast>
);
