import { wrapCreateBrowserRouter } from '@sentry/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { createBrowserRouter, Navigate, Outlet, ScrollRestoration } from 'react-router-dom';

import { Dual } from 'pages/Dual';
import { Duals } from 'pages/Duals';
import { Onramp } from 'pages/Onramp';
import { PageNotFound } from 'pages/PageNotFound';
import { Perpetuals } from 'pages/Perpetuals';
import { Points } from 'pages/Points';
import { ReferralProgram } from 'pages/ReferralProgram';
import { Swap } from 'pages/Swap';

import { DualWidget } from 'widgets/Dual';
import { DualModal } from 'widgets/DualModal';
import { DualCreateFeature } from 'widgets/DualsDashboard';
import { BasicLayout } from 'widgets/Layout';

import { useSignup } from 'features/Auth';
import { TokenPermitsProvider } from 'features/CreateDual';
import { HappyMomentsProvider } from 'features/HappyMoments';
import { useHotjarStateChanges } from 'features/HotJar';
import { useDataLayer } from 'features/MixPanel';
import { useChainParam, useSyncNetwork, useWalletAnalytics } from 'features/Network';
import { OnboardingTour } from 'features/OnboardingTour';

import { DualModalProvider } from 'entities/Dual';
import { usePrefetchTokens } from 'entities/Token';
import { useQueryConnectWallet, WelcomeBonusModal } from 'entities/User';
import { useHandlePendingTransfers } from 'entities/User/model/useHandleTransfers';

import { useSaveHistoryApp, useSentryScope } from 'shared/hooks';
import { PathPatterns } from 'shared/lib';
import { BoxProps } from 'shared/ui';

const Root: React.FC<React.PropsWithChildren & BoxProps> = observer(({ children, ...layoutProps }) => {
  useDataLayer();

  return (
    <BasicLayout {...layoutProps}>
      <ScrollRestoration />
      <Outlet />
    </BasicLayout>
  );
});

export const router = wrapCreateBrowserRouter(createBrowserRouter)([
  {
    index: true,
    element: <Navigate to={PathPatterns.Buy} replace />,
    path: '/',
  },
  {
    children: [
      {
        element: <Onramp />,
        path: PathPatterns.Buy,
      },
      {
        element: <PageNotFound />,
        path: PathPatterns.PageNotFound,
      },
    ],
    element: <Root />,
    path: '/',
  },
]);
