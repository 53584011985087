import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatApr } from 'entities/Dual/lib';

import { Text, Card, Badge } from 'shared/ui';

import styles from './StakingPlanSelect.module.scss';

export type StakingPlanValue = {
  apr: number;
  hours: number;
};

interface DualStakingPlanSelectProps {
  className?: string;
  items: StakingPlanValue[];
  onSelect?: (index: number) => void;
  selected?: number;
}

export const DualStakingPlanSelect: React.FC<DualStakingPlanSelectProps> = ({
  className,
  items = [],
  onSelect = () => {},
  selected = 0,
}) => {
  const { t } = useTranslation();
  const handleSelect = (index: number) => () => {
    if (index === selected) {
      return;
    }

    onSelect(index);
  };

  const renderItem = (item: StakingPlanValue, index: number): React.ReactElement => {
    const isSelected = index === selected;
    const variant = isSelected ? 'primary' : 'secondary';

    return (
      <li className={styles.item} key={index}>
        <Card variant={variant} onClick={handleSelect(index)} isOutlined data-id="select-plan">
          <div className={styles.cardContent}>
            <Badge variant={variant}>{formatApr(item.apr)}</Badge>
            <Text text="app-12-regular" color={isSelected ? 'white-01' : 'secondary-03'}>
              {t('common.hoursWithCount', { count: item.hours })}
            </Text>
          </div>
        </Card>
      </li>
    );
  };

  return (
    <div className={classnames(styles.select, className)}>
      <Text color="secondary-03" lineHeight={20}>
        {t('dual.stakingPlan')}
      </Text>
      <ul role="tablist" className={styles.list}>
        {items.map(renderItem)}
      </ul>
    </div>
  );
};
