import React from 'react';

interface IconByUrlProps {
  alt?: string;
  className?: string;
  loading?: 'lazy' | 'eager';
  onError?: () => void;
  onLoad?: () => void;
  size?: number;
  url: string;
}

export const IconByUrl: React.FC<IconByUrlProps> = ({
  alt,
  className,
  size,
  url,
  loading = 'lazy',
  onLoad,
  onError,
}) => (
  <img
    alt={alt}
    src={url}
    style={{ height: size, maxWidth: 'none', width: size, borderRadius: '100px' }}
    className={className}
    loading={loading}
    onError={onError}
    onLoad={onLoad}
  />
);
