import { CFC, memo } from 'react';

import { Box, BoxProps } from '../Box';
import { Text } from '../Text';

import { BackButton } from './BackButton';

interface PageHeaderProps extends BoxProps {
  leftButton?: React.ReactNode;
  onLeftButtonPress?: () => Promise<void> | void;
  showLeftButton?: boolean;
}

export const PageHeader: CFC<PageHeaderProps> = memo(
  ({ children, leftButton, onLeftButtonPress, showLeftButton = true, ...rest }) => (
    <Box justifyContent="center" alignItems="center" px={16} position="relative" {...rest}>
      <Text text="app-18-medium">{children}</Text>
      {showLeftButton && (
        <Box position="absolute" top="center" left={!leftButton ? 4 : 0}>
          {leftButton || <BackButton hoverable onPress={onLeftButtonPress} />}
        </Box>
      )}
    </Box>
  ),
);
