import { path } from 'd3-path';
import { scaleLinear } from 'd3-scale';
import React, { useMemo } from 'react';

interface ChartProps {
  height?: number;
  width?: number;
}

export const Chart: React.FC<ChartProps> = ({ height = 0, width = 0 }) => {
  const chartStart = 18.5;
  const middleY = height / 2;
  const middleX = width / 2;

  const scaleX = useMemo(
    () => scaleLinear().domain([0, 1]).clamp(true).range([chartStart, middleX]),
    [chartStart, middleX],
  );

  const scaleY = useMemo(() => scaleLinear().domain([0, 1]).range([0, height]), [height]);

  const positiveLine = useMemo(() => {
    const p = path();

    p.moveTo(scaleX(0), scaleY(0.5));
    p.bezierCurveTo(
      scaleX(0.230249162011173),
      scaleY(0.545384615384615),
      scaleX(0.384450279329609),
      scaleY(0.5070625),
      scaleX(0.517643575418994),
      scaleY(0.431415384615385),
    );
    p.bezierCurveTo(
      scaleX(0.650837988826816),
      scaleY(0.355770192307692),
      scaleX(0.726256983240223),
      scaleY(0.228365384615385),
      scaleX(1),
      scaleY(0.25),
    );

    return p.toString();
  }, [scaleX, scaleY]);

  const negativeLine = useMemo(() => {
    const p = path();

    p.moveTo(scaleX(0), scaleY(0.5));
    p.bezierCurveTo(
      scaleX(0.230250279329609),
      scaleY(0.446015384615385),
      scaleX(0.396648044692737),
      scaleY(0.485576923076923),
      scaleX(0.508379888268156),
      scaleY(0.586538461538462),
    );
    p.bezierCurveTo(
      scaleX(0.620111731843575),
      scaleY(0.6875),
      scaleX(0.729050279329609),
      scaleY(0.771634615384615),
      scaleX(1),
      scaleY(0.75),
    );

    return p.toString();
  }, [scaleX, scaleY]);

  return (
    <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg">
      <rect height={height} width={width} fill="var(--background-01)" />
      <line
        vectorEffect="non-scaling-stroke"
        strokeWidth={1}
        x1={middleX}
        y1={0}
        x2={middleX}
        y2={height}
        stroke="var(--secondary-02)"
      />
      <line
        vectorEffect="non-scaling-stroke"
        strokeWidth={1}
        x1={0}
        y1={middleY}
        x2={width}
        y2={middleY}
        stroke="var(--secondary-02)"
      />
      <line x1={chartStart} x2={chartStart} y1={middleY} y2={scaleY(0.2)} stroke="white" strokeWidth={1} />
      <path
        d={positiveLine}
        stroke="var(--primary-01)"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
        width={1.5}
      />
      <path
        d={negativeLine}
        stroke="var(--primary-01)"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
        width={1.5}
      />
      <circle cx={chartStart} cy={middleY} fill="none" r={3} stroke="var(--white-01)" strokeWidth={2}>
        <animate attributeName="r" from={3} to={6} dur="1.5s" begin="0s" repeatCount="indefinite" />
        <animate attributeName="opacity" from={0.6} to={0} dur="1.5s" begin="0s" repeatCount="indefinite" />
      </circle>
      <circle cx={chartStart} cy={middleY} r={3} fill="var(--white-01)" />
    </svg>
  );
};
