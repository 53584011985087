import { OnrampTokensResponse } from 'entities/Onramp/model';

import { useOnrampTokens } from './useOnrampTokens';

const findToken = (tokens: OnrampTokensResponse, code: string) =>
  tokens.find((token) => token.code.toLowerCase() === code);

export const useOnrampTokenByCode = (code?: string | null, type?: 'fiat' | 'crypto' | null) => {
  const { queryCrypto, queryFiat } = useOnrampTokens();

  if (!queryCrypto.data || !queryFiat.data || !code) return null;

  if (!type) return findToken(queryFiat.data, code) || findToken(queryCrypto.data, code);

  return type === 'fiat' ? findToken(queryFiat.data, code) : findToken(queryCrypto.data, code);
};
