import { requester } from 'shared/api';

import {
  ChartRatesArgs,
  ChartRatesResponse,
  RateProduct,
  RatesExtendedResponse,
  RatesResponse,
  RatesType,
} from '../model/types';

export const getRates = () =>
  requester.get<RatesResponse>('/api/v2/rates').then((res) =>
    Object.entries(res.data).reduce(
      (acc, [symbol, [mid, bid, ask]]: any) => {
        acc[RateProduct.DEFAULT][symbol] = {
          ask,
          mid,
          bid,
        };
        return acc;
      },
      { [RateProduct.DEFAULT]: {} } as RatesType,
    ),
  );

export const getRatesExtended = ({ symbols, product }: { product?: string; symbols: string[] }) =>
  requester
    .get<RatesExtendedResponse>(
      `/api/v2/rates/extended?${symbols.map((symbol) => `symbol=${symbol}`).join('&')}${
        product ? `&product=${product}` : ''
      }`,
    )
    .then((res) => res.data);

export const getChartRates = ({ fromTicker, toTicker, fromDate, toDate }: ChartRatesArgs) =>
  requester
    .get<ChartRatesResponse>(
      `/api/v1/rates/chart?fromTicker=${fromTicker}&toTicker=${toTicker}&fromDate=${fromDate}&toDate=${toDate}&points=${60}`,
    )
    .then((res) => res.data);
