import { requester } from 'shared/api';

import { storageUser } from '../lib/storageUser';
import {
  AuthResponse,
  SignupArgs,
  UpdateUserArgs,
  ListNotificationsArgs,
  CountNotificationArgs,
  CountNotificationResponse,
  NotificationsResponse,
  NomisArgs,
  NomisResponse,
  CreateWithdrawalArgs,
  CreateWithdrawalResponse,
  GetTransferArgs,
  GetTransferResponse,
  GetTransfersArgs,
  GetTransfersResponse,
} from '../model/types';

export const authCheck = () => requester.get<undefined>('/api/v1/auth/me').then((res) => res.data);

export const authUser = (address: string) =>
  requester.get<AuthResponse>(`/api/v2/auth/${address}`).then((res) => res.data);

export const signup = ({ address, signature }: SignupArgs) =>
  requester.post<undefined>(
    '/api/v2/auth/signup',
    {
      address,
    },
    {
      headers: {
        Authorization: signature,
      },
    },
  );

export const updateUser = ({ signature, ...values }: UpdateUserArgs) => {
  const body = Object.entries(values).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key as keyof UpdateUserArgs] = value as any;
    }
    return acc;
  }, {} as UpdateUserArgs);
  return requester.put<AuthResponse>('/api/v2/auth/me', body, {
    headers: {
      Authorization: signature,
    },
  });
};

export const getNotifications = ({ address, language }: ListNotificationsArgs) =>
  requester
    .get<NotificationsResponse>(`/api/v1/notifications/${address}`, {
      params: {
        language,
      },
    })
    .then((res) => res.data);

export const getNotificationCount = ({ address, timestamp: userTimestamp }: CountNotificationArgs) => {
  const localTimestamp = storageUser.get(address!)?.notificationLastSeenAt;

  const timestamp = Math.max(userTimestamp || 0, localTimestamp || 0);
  return requester
    .get<CountNotificationResponse>(
      `/api/v1/notifications/${address}/count${timestamp ? `?timestamp=${timestamp}` : ''}`,
    )
    .then((res) => res.data);
};

export const getNomisScore = ({ chainId, address }: NomisArgs) =>
  requester.get<NomisResponse>(`api/v2/nomis/${chainId}/${address}`).then((res) => res.data);

export const createWithdrawal = ({ signature, chainId, ticker, amount }: CreateWithdrawalArgs) =>
  requester.post<CreateWithdrawalResponse>(
    `/api/v1/withdrawals`,
    {
      chainId,
      ticker,
      amount,
    },
    {
      headers: {
        Authorization: signature,
      },
    },
  );

export const getTransfer = ({ chainId, txHash, logIndex }: GetTransferArgs) =>
  requester.get<GetTransferResponse>(`/api/v1/transfers/${chainId}/${txHash}/${logIndex}`).then((res) => res.data);

export const getTransfers = ({ address, status }: GetTransfersArgs) =>
  requester.get<GetTransfersResponse>(`/api/v1/transfers/${address}?status=${status}`).then((res) => res.data);
