import React from 'react';
import { useTranslation } from 'react-i18next';

import { OnrampFiatField, useOnrampForm } from 'entities/Onramp';

export const OnrampInputField = () => {
  const { t } = useTranslation();

  const { input, update } = useOnrampForm();

  return (
    <OnrampFiatField
      title={t('common.youPay')}
      value={input}
      onChange={(value) => {
        update({ input: value });
      }}
    />
  );
};
