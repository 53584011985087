import Shepherd from 'shepherd.js';

import { TOUR_CLASSES } from './classes';

export const updateApproveStep = (status?: 'hide' | 'show') => {
  if (!Shepherd.activeTour || !Shepherd.activeTour?.isActive()) return;

  const stepId = Shepherd.activeTour.getCurrentStep()?.id;

  if (stepId === `step-${TOUR_CLASSES.APROVE.id}` && status === 'hide') {
    Shepherd.activeTour.hide();
  }

  if (stepId === `step-${TOUR_CLASSES.APROVE.id}` && status === 'show') {
    Shepherd.activeTour.show(`step-${TOUR_CLASSES.APROVE.id}`);
  }

  if (stepId === `step-${TOUR_CLASSES.APROVE.id}` && status === undefined) {
    Shepherd.activeTour.show(`step-${TOUR_CLASSES.START.id}`);
  }
};

export const nextStep = () => {
  if (!Shepherd.activeTour || !Shepherd.activeTour?.isActive()) return;
  Shepherd.activeTour.next();
};

export const hideStep = () => {
  if (!Shepherd.activeTour || !Shepherd.activeTour?.isActive()) return;
  Shepherd.activeTour.hide();
};

export const updateFinishStep = (status?: 'hide' | 'show') => {
  if (!Shepherd.activeTour || !Shepherd.activeTour?.isActive()) return;

  if (status === 'hide') {
    Shepherd.activeTour.hide();
  }
  if (status === 'show') {
    Shepherd.activeTour.show(`step-${TOUR_CLASSES.START.id}`);
  }
  if (status === undefined) {
    Shepherd.activeTour.complete();
  }
};
