import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseIcon } from 'shared/assets';
import { Box, Text } from 'shared/ui';

import { NotificationList } from '../NotificationList';
import { NotificationSubscribeCard } from '../NotificationSubscribeCard';

import { StyledDialog } from './styled';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const NotificationCenter: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  return (
    <StyledDialog open={isOpen} onClose={onClose}>
      <Box p={20} flexDirection="row" justifyContent="space-between">
        <Text color="white-01" text="app-18-medium">
          {t('notifications.center')}
        </Text>
        <Box onClick={onClose}>
          <CloseIcon width={24} height={24} color="white" />
        </Box>
      </Box>
      <NotificationSubscribeCard />
      <NotificationList />
    </StyledDialog>
  );
};
