import React from 'react';

import { Box, BoxProps, Text } from 'shared/ui';

interface BadgeProps {
  color?: BoxProps['bg'];
}

export const Badge: React.CFC<BadgeProps> = ({ children, color = 'white-01' }) => (
  <Box height={16} borderRadius={20} px={6} justifyContent="center" bg={color}>
    <Text text="app-12-medium" color="background-01">
      {children}
    </Text>
  </Box>
);
