import { useNetwork as useNetworkWagmi } from 'wagmi';

import { CHAIN_INFO, supportedChains } from 'shared/config/chains';

import { useStore } from '../store';
import { useWidget } from '../useWidget';

export const useNetwork = () => {
  const { networkStore } = useStore();
  const wagmi = useNetworkWagmi();

  const isWidget = useWidget();

  const { lastConnectorId, selectedChainId, switchingTo } = networkStore;

  const network = CHAIN_INFO[wagmi.chain?.id || selectedChainId];

  return {
    chainId: isWidget ? selectedChainId : network?.id || selectedChainId,
    lastConnectorId,
    network,
    networkReady: selectedChainId === wagmi.chain?.id,
    networks: supportedChains,
    selectedChainId,
    store: networkStore,
    switchingTo,
    wagmi,
  };
};
