import { Address } from 'viem';

import { LocalStorage } from 'shared/lib';

type LocalUser = {
  notificationLastSeenAt: number;
};

export const storageUser = {
  get(address: Address) {
    const localUser = LocalStorage.get<Record<string, LocalUser>>('rehold_user')?.[address];
    return localUser || null;
  },
  set(userInfo: LocalUser, address: Address) {
    LocalStorage.set('rehold_user', { [address]: userInfo });
  },
};
