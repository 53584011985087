import { Address } from 'viem';

import { WETH } from 'shared/config';
import { useNetwork } from 'shared/hooks/network';

export const useWETHAddress = () => {
  const { selectedChainId: chainId } = useNetwork();

  return WETH[chainId].toLowerCase() as Address;
};
