import { Skeleton } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useOnrampForm, useOnrampQuote } from 'entities/Onramp/lib/hooks';
import { OnrampCryptoCurrency, OnrampCryptoCurrencyFieldValue } from 'entities/Onramp/model';

import { Box, IconByUrl, Text } from 'shared/ui';

import { OnrampAssetInput } from '../OnrampAssetInput';
import { OnrampAssetSelect } from '../OnrampAssetSelect';

import { OnrampAssetRates } from './OnrampAssetRates';

interface Props {
  disabled?: boolean;
  onChange: (value: OnrampCryptoCurrencyFieldValue) => void;
  title: string;
  value: OnrampCryptoCurrencyFieldValue;
}

export const OnrampAssetField: FC<Props> = ({ title, disabled = false, value, onChange }) => {
  const { t } = useTranslation();

  const { input } = useOnrampForm();
  const { isFetching: isQuoteLoading, isInitialLoading: isQuoteInit } = useOnrampQuote();

  const handleAmountChange = (amount: string) => {
    onChange({
      ...value,
      amount,
    });
  };
  const handleCurrencyChange = (currency: OnrampCryptoCurrency | null) => {
    onChange({
      ...value,
      currency,
    });
  };

  return (
    <Box border={1} borderColor="secondary-02" borderRadius={16} padding={16} gap={2}>
      <Text text="app-12-medium" style={{ opacity: 0.4 }}>
        {title}
      </Text>
      <Box flexDirection="row" alignItems="center">
        {isQuoteLoading && isQuoteInit && input.amount && input.currency && value.currency && (
          <Skeleton
            animation="wave"
            sx={{ bgcolor: `rgba(134, 77, 247, 0.4)`, borderRadius: 4, transform: 'scale(1, 0.90)' }}
            width="100%"
            height={40}
            style={{ marginRight: '30%' }}
          />
        )}
        {!(isQuoteLoading && isQuoteInit && input.amount && input.currency && value.currency) && (
          <OnrampAssetInput
            value={value.amount}
            disabled={disabled}
            handleChange={({ value }) => handleAmountChange(value)}
            ticker={value.currency?.code as string}
          />
        )}
        <OnrampAssetSelect value={value.currency} onSelect={handleCurrencyChange} />
      </Box>
      <Box minHeight={18} flexDirection="row" alignItems="center" justifyContent="space-between" />
      <Box style={{ height: 1 }} width="100%" backgroundColor="secondary-02" />
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        paddingTop={14}
        gap={16}
        flexWrap="wrap"
      >
        <OnrampAssetRates />
        <Box flexDirection="row" alignItems="center" gap={12}>
          <img src="/static/onramps/truevo.png" height={38} />
        </Box>
      </Box>
    </Box>
  );
};
