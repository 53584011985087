import { observer } from 'mobx-react-lite';

import { DualType } from 'entities/Dual';

import { Box } from 'shared/ui';

import { useDualChartData } from '../../lib/hooks/useDualChartData';

import { DualChart } from './DualChart';
import { DualChartCalculationRow } from './DualChartCalculationRow';

interface DualChartWithProfitProps {
  chartMargin?: number;
  dual: DualType;
}

export const DualChartWithProfit: React.FC<DualChartWithProfitProps> = observer(({ chartMargin = -24, dual }) => {
  const { dualInitialPrice, lastPoint } = useDualChartData(dual);

  return (
    <>
      <DualChartCalculationRow row={dual.closeCalculate.up} active={lastPoint.price >= dualInitialPrice} />
      <Box mx={chartMargin}>
        <DualChart dual={dual} />
      </Box>
      <DualChartCalculationRow row={dual.closeCalculate.down} active={lastPoint.price < dualInitialPrice} />
    </>
  );
});
