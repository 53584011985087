import { getAmountPrecisionBySymbol } from '@rehold-io/formatters';
import BigNumber from 'bignumber.js';
import React, { FC } from 'react';
import { NumberFormatValues, NumericFormat, OnValueChange } from 'react-number-format';

import styles from './OnrampFiatInput.module.scss';

interface Props {
  disabled?: boolean;
  handleChange: OnValueChange;
  ticker?: string;
  value: string;
}

const MAX_VAL = 9999999999999998;

export const OnrampAssetInput: FC<Props> = ({ disabled = false, value, handleChange, ticker }) => {
  const withValueLimit = ({ floatValue }: NumberFormatValues) => (floatValue ?? 0) <= MAX_VAL;

  const amountPrecision = ticker ? getAmountPrecisionBySymbol(ticker) : 0;

  const isDotValueIncluded = value.includes('.');

  const decimalsLength = value.split('.')[1]?.length || 0;

  const integerValue = BigNumber(value).integerValue(BigNumber.ROUND_DOWN);
  const decimalValue = BigNumber(value)
    .minus(integerValue)
    .toFixed(decimalsLength, BigNumber.ROUND_DOWN)
    .substring(0, amountPrecision + 2);

  const roundedValue = integerValue.plus(decimalValue).toFixed(decimalsLength, BigNumber.ROUND_DOWN);

  const roundedValueDotIncluded = roundedValue.includes('.') ? roundedValue : `${roundedValue}.`;

  const roundedValueDotHandled = isDotValueIncluded ? roundedValueDotIncluded : roundedValue;

  const formattedValue = BigNumber(roundedValue).isZero() && !BigNumber(value).isZero() ? '0' : roundedValueDotHandled;

  return (
    <NumericFormat
      className={styles.input}
      placeholder="0"
      disabled={disabled}
      displayType="input"
      type="text"
      title={value}
      inputMode="decimal"
      onValueChange={handleChange}
      value={formattedValue}
      isAllowed={withValueLimit}
      allowNegative={false}
      allowLeadingZeros={false}
      decimalScale={amountPrecision}
      allowedDecimalSeparators={['.', ',']}
      defaultValue={0}
      valueIsNumericString
    />
  );
};
