import { Skeleton } from '@mui/material';
import BigNumber from 'bignumber.js';
import React, { FC, useState } from 'react';

import { useOnrampForm, useOnrampQuote } from 'entities/Onramp/lib/hooks';

import { roundToNearest } from 'shared/lib';
import { Box, Text } from 'shared/ui';

import { formatUnsigZeros } from '../../../../shared/lib/formats/format';

export const OnrampAssetRates = () => {
  const [toggled, setToggled] = useState(true);

  const { input, output } = useOnrampForm();
  const {
    data: quote,
    isError: isQuoteError,
    isFetching: isQuoteLoading,
    isInitialLoading: isQuoteInit,
  } = useOnrampQuote();

  if (!input.currency || !output.currency || !input.amount) return <Box />;

  const firstSymbol = toggled ? output.currency.ticker.toUpperCase() : input.currency.ticker.toUpperCase();
  const lastSymbol = toggled ? input.currency.ticker.toUpperCase() : output.currency.ticker.toUpperCase();

  const isError = isQuoteError || quote === null;
  const isLoading = isQuoteLoading && isQuoteInit;

  if (isLoading)
    return (
      <Skeleton
        animation="wave"
        sx={{ bgcolor: `rgba(134, 77, 247, 0.4)`, borderRadius: 4, transform: 'scale(1, 0.90)' }}
        width="35%"
        height={24}
      />
    );

  if (!quote || isError) return <Box />;

  const defaultRate = BigNumber(quote.fromAmount).div(quote.toAmount).toNumber();
  const invertedRate = BigNumber(quote.toAmount).div(quote.fromAmount).toNumber();

  const rate = toggled ? defaultRate : invertedRate;

  return (
    <Component
      onClick={() => setToggled((prev) => !prev)}
      input={1}
      inputSymbol={firstSymbol}
      output={1 * rate}
      outputSymbol={lastSymbol}
    />
  );
};

interface Props {
  input: number;
  inputSymbol: string;
  onClick: () => void;
  output: number;
  outputSymbol: string;
}

const Component: FC<Props> = ({ onClick, input, inputSymbol, output, outputSymbol }) => {
  const roundedOutput = formatUnsigZeros(roundToNearest(BigNumber(output)).toFixed(20));

  return (
    <Box maxWidth="100%" flexDirection="row" alignItems="center" gap={8} flexWrap="wrap" rowGap={8} onClick={onClick}>
      <Text text="app-14-medium" color="white-01" opacity={0.4}>
        {`${input} ${inputSymbol} = ${roundedOutput} ${outputSymbol}`}
      </Text>
    </Box>
  );
};
