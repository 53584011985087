import { runInAction } from 'mobx';
import React, { memo, useEffect } from 'react';

import { storageSignature } from 'features/Auth';

import { useGetNotificationsQuery, useUserMutation, useUserStore } from 'entities/User';
import { storageUser } from 'entities/User/lib/storageUser';

import { useAccount } from 'shared/hooks';
import { Box, Spinner } from 'shared/ui';

import { NotificationItem } from '../NotificationItem';

export const NotificationList = memo(() => {
  const { data, isInitialLoading } = useGetNotificationsQuery();
  const { mutateAsync } = useUserMutation();
  const { address } = useAccount();
  const { setUser, user } = useUserStore();

  useEffect(() => {
    const signature = storageSignature.get(address!);
    const timestamp = Date.parse(new Date().toISOString());

    if (data) {
      const prevState = storageUser.get(address!);
      storageUser.set({ ...prevState, notificationLastSeenAt: timestamp }, address!);

      if (signature) {
        mutateAsync({ clientSettings: { notificationLastSeenAt: timestamp }, signature });
      } else if (user) {
        runInAction(() => {
          user.clientSettings = { ...user.clientSettings, notificationLastSeenAt: timestamp };
        });
      } else {
        setUser({ clientSettings: { notificationLastSeenAt: timestamp } } as any);
      }
    }
  }, [data, mutateAsync, address, setUser]);

  if (isInitialLoading) {
    return (
      <Box height={250} justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      {data?.map((item) => (
        <NotificationItem {...item} key={item.id} />
      ))}
    </>
  );
});
