import { FC } from 'react';
import { Toast } from 'react-hot-toast';

import { BasicToast } from '../BasicToast';
import { BasicToastBody } from '../BasicToastBody';

export interface ErrorToastProps {
  text: string;
  title?: string;
}

export const ErrorToast: FC<Toast & ErrorToastProps & { onClose: VoidFunction }> = ({
  onClose,
  text,
  title,
  ...rest
}) => (
  <BasicToast toastProps={rest} variant="error" onClose={onClose}>
    <BasicToastBody variant="error" title={title} text={text} />
  </BasicToast>
);
