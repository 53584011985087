import { requester } from 'shared/api';

import {
  OnrampQuoteRequestArgs,
  OnrampQuoteResponse,
  OnrampTokensRequestArgs,
  OnrampTokensResponse,
  OnrampDataRequestArgs,
  OnrampDataResponse,
} from '../model';

export const getOnrampQuote = ({ provider, fromAmount, fromTokenCode, toTokenCode }: OnrampQuoteRequestArgs) =>
  requester
    .get<OnrampQuoteResponse>(`/api/v1/onramps/${provider}/quote`, {
      params: { fromAmount, fromTokenCode, toTokenCode },
    })
    .then((res) => res.data);

export const getOnrampTokens = ({ type, provider }: OnrampTokensRequestArgs) =>
  requester
    .get<OnrampTokensResponse>(`/api/v1/onramps/${provider}/tokens`, { params: { type } })
    .then((res) => res.data);

export const getOnrampData = ({
  address,
  color,
  fromAmount,
  fromTokenCode,
  language,
  provider,
  redirectURL,
  toTokenCode,
  signature,
}: OnrampDataRequestArgs) =>
  requester
    .post<OnrampDataResponse>(
      `/api/v1/onramps/${provider}/sign`,
      {
        address,
        color,
        fromAmount,
        fromTokenCode,
        language,
        redirectURL,
        toTokenCode,
      },
      {
        headers: {
          Authorization: signature,
        },
      },
    )
    .then((res) => res.data);
