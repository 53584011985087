import { ClickAwayListener, useMediaQuery, useTheme } from '@mui/material';
import classNames from 'classnames';
import { CFC, CSSProperties, useCallback, useRef, useState, useEffect } from 'react';

import { Box } from 'shared/ui/Box';

import styles from './Menu.module.scss';
import { MenuProps } from './types';

const headerHeight = 65;

export interface ExtendedMenuProps extends MenuProps {
  blockBackgroundScroll?: boolean;
  fullWidth?: boolean;
  leftSided?: boolean;
  widthExtend?: number;
}

export const Menu: CFC<ExtendedMenuProps> = (props) => {
  const { colors } = useTheme();

  const {
    children,
    isOpen,
    toggle,
    trigger,
    backgroundColor = 'background-01',
    borderColor = 'secondary-04',
    showCorner = true,
    clickable = false,
    hoverable = true,
    forceCallback = false,
    forceDesktopLayout = false,
    minWidth = 300,
    fullWidth = false,
    blockBackgroundScroll = false,
    leftSided,
    widthExtend,
  } = props;

  const isWideMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });
  const triggerRef = useRef<HTMLDivElement>(null);
  const [triggerWidth, setTriggerWidth] = useState(0);

  const [isOpenedOnce, setOpenedOnce] = useState(false);

  useEffect(() => {
    function updateWidth() {
      if (fullWidth && triggerRef.current) {
        setTriggerWidth(triggerRef.current.offsetWidth + (widthExtend || 0));
      }
    }

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [fullWidth, triggerRef, widthExtend, isOpen]);

  useEffect(() => {
    if (isOpen && isWideMobile && blockBackgroundScroll && !forceDesktopLayout) {
      const classes = document.body.className;
      document.body.className = `${classes} overflow-hidden`;
      document.body.style.width = '100vw';
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
      setOpenedOnce(true);
    }
    if (!isOpen && isOpenedOnce) {
      const classes = document.body.className;
      document.body.className = classes.replace('overflow-hidden', '');
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      if (scrollY) window.scrollTo(0, parseInt(scrollY, 10) * -1);
    }

    return () => {
      const classes = document.body.className;
      document.body.className = classes.replace('overflow-hidden', '');
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      if (scrollY) window.scrollTo(0, parseInt(scrollY, 10) * -1);
    };
  }, [isOpen, isWideMobile, blockBackgroundScroll, forceDesktopLayout]);

  const MenuCorner = () => (
    <div
      style={{
        background: colors['secondary-04'],
        borderRadius: 2,
        height: 14,
        marginRight: -10,
        position: 'absolute',
        right: '50%',
        top: 15,
        transform: 'rotate(45deg)',
        width: 14,
      }}
    />
  );

  const menuWrapperStyles: CSSProperties =
    isWideMobile && !forceDesktopLayout
      ? {
          width: fullWidth ? `${triggerWidth}px` : undefined,
          left: 0,
          position: 'fixed',
          right: 0,
          top: headerHeight,
        }
      : {
          width: fullWidth ? `${triggerWidth}px` : undefined,
          position: 'absolute',
          right: leftSided ? undefined : 0,
          left: leftSided ? 0 : undefined,
          top: '100%',
        };

  const handler = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, isOpen: boolean) => {
      if (isWideMobile && !forceCallback) {
        return;
      }

      e.stopPropagation();
      toggle(isOpen);
    },
    [isWideMobile, toggle, forceCallback],
  );

  return (
    <ClickAwayListener onClickAway={() => toggle(false)}>
      <div
        className={classNames(styles.container, { relative: !(isWideMobile && !forceDesktopLayout) && isOpen })}
        {...(hoverable ? { onMouseLeave: (e) => handler(e, false) } : {})}
      >
        <div
          ref={triggerRef}
          {...(hoverable ? { onMouseEnter: (e) => handler(e, true) } : {})}
          {...(clickable ? { onClick: (e) => handler(e, !isOpen) } : {})}
        >
          {trigger}
        </div>
        <div
          className={classNames(styles.root, {
            [styles.block]: !(isWideMobile && !forceDesktopLayout),
            [styles['root.mobile']]: !(isWideMobile && !forceDesktopLayout),
            [styles.hidden]: !isOpen,
            [styles.open]: isOpen,
          })}
          style={menuWrapperStyles}
        >
          {!(isWideMobile && !forceDesktopLayout) && showCorner && <MenuCorner />}
          <Box
            py={props.py === undefined ? 24 : props.py}
            px={props.px === undefined ? 0 : props.px}
            mt={props.menuGap || (isWideMobile && !forceDesktopLayout ? 0 : 8)}
            bg={backgroundColor}
            borderRadius={isWideMobile && !forceDesktopLayout ? 0 : 16}
            width={isWideMobile && !forceDesktopLayout ? '100vw' : 'auto'}
            sx={{
              borderBottomWidth: 1,
              borderLeftWidth: isWideMobile && !forceDesktopLayout ? 0 : 1,
              borderRightWidth: isWideMobile && !forceDesktopLayout ? 0 : 1,
              borderTopWidth: 1,
            }}
            borderColor={borderColor}
            minWidth={minWidth}
            maxHeight="calc(100vh - 65px)"
            overflow="auto"
          >
            {children}
          </Box>
        </div>
      </div>
    </ClickAwayListener>
  );
};
