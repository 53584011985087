import { useMemo } from 'react';
import { Address, erc20ABI, useContractReads } from 'wagmi';

export const useBalances = (
  args: { chainId: number; token: string; user: string }[],
  options?: { enabled: boolean },
) => {
  const { data, isError, isLoading, refetch } = useContractReads({
    enabled: options ? options.enabled : true,
    allowFailure: true,
    cacheTime: 1_000,
    batchSize: 1024 * 10,
    contracts: args.map(
      ({ chainId, token, user }) =>
        ({
          abi: erc20ABI,
          address: token as Address,
          args: [user as Address],
          chainId,
          functionName: 'balanceOf',
        } as const),
    ),
    select: (values) => values.map(({ result }, i) => ({ ...args[i], value: result || 0 })),
  });

  const balances = useMemo(
    () =>
      data
        ? Object.fromEntries(
            data
              .filter(({ value }) => value !== null && value >= 0)
              .map((balance) => [`${balance.chainId}:${balance.token}`, balance.value]),
          )
        : {},
    [data],
  );

  return {
    balances,
    data,
    isError,
    isLoading,
    refetch,
  };
};
