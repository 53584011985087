import { useTheme } from '@mui/material';
import { useMemo, FC } from 'react';

import { useIsMobile } from 'shared/hooks/useResponsive';

import { default as GridSVG } from './assets/grid.svg';

export const LayoutBackground: FC = () => {
  const isMobile = useIsMobile();

  const { colors } = useTheme();

  const style = useMemo<React.CSSProperties>(
    () => ({
      background: isMobile
        ? colors['background-01']
        : `linear-gradient(180deg, ${colors['layout-01']} 20.25%, ${colors['layout-02']} 78.59%, ${colors['layout-03']} 100%), url('${GridSVG}') repeat-x ${colors['background-01']} center top fixed`,
      bottom: 0,
      left: 0,
      position: 'fixed',
      right: 0,
      top: 0,
    }),
    [isMobile, colors],
  );

  return <div style={style} />;
};
