import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { CFC } from 'react';

type ContextValue = {
  dualParams: DualParams;
  setDualParams: (value: DualParams) => void;
};

type DualParams = {
  enabledAutoReplay?: boolean;
  id: string;
  type?: 'default' | 'replay';
} | null;

export const DualModalContext = React.createContext({} as ContextValue);

export const DualModalProvider: CFC = observer(({ children }) => {
  const state = useLocalObservable<ContextValue>(() => ({
    dualParams: null,
    setDualParams(dualParams: DualParams) {
      this.dualParams = dualParams;
    },
  }));

  return <DualModalContext.Provider value={state}>{children}</DualModalContext.Provider>;
});
