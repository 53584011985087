import { toast } from 'react-hot-toast';

import { ErrorToast, ErrorToastProps } from 'shared/ui/Toasts/Error';

const uniqMap = new Map<any, boolean>();

export const notifyError = (options: ErrorToastProps) => {
  const optionsJson = JSON.stringify(options);
  if (uniqMap.has(optionsJson)) {
    return;
  }
  uniqMap.set(optionsJson, true);
  // we can show the same error if it happens in 10sec
  setTimeout(() => {
    uniqMap.delete(optionsJson);
  }, 5_000);

  toast.custom((t) => <ErrorToast onClose={() => toast.dismiss(t.id)} {...options} {...t} />, {
    duration: 5_000,
  });
};
