import { useEffect, useState } from 'react';

import { useNetwork } from 'shared/hooks/network';

export const useChangeChainEffect = (onChange: () => void) => {
  const { selectedChainId } = useNetwork();
  const [lastChainId, setLastChainId] = useState<number | null>(null);

  useEffect(() => {
    setLastChainId(selectedChainId);
  }, []);

  useEffect(() => {
    if (lastChainId && lastChainId !== selectedChainId) {
      onChange();
      setLastChainId(selectedChainId);
    }
  }, [lastChainId, selectedChainId]);

  return { chainId: selectedChainId };
};
