import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from 'shared/hooks';
import { Box, Text, PageHeader, PageContent } from 'shared/ui';

export const PageNotFound = React.memo(() => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <>
      <Helmet title={t('notFound.metaTitle')} />
      <PageHeader pt={{ desktop: 20, phone: 0, tablet: 16 }}>{t('notFound.title')}</PageHeader>
      <PageContent
        px={{ default: 12, desktop: 16, tablet: 16 }}
        py={{ desktop: 20, phone: 0, tablet: 16 }}
        pb={{ desktop: isMobile ? 84 + 20 : 20, phone: 0, tablet: isMobile ? 84 + 16 : 16 }}
      >
        <Box height={150} justifyContent="center" alignItems="center">
          <Text color="white-01">{t('notFound.description')}</Text>
        </Box>
      </PageContent>
    </>
  );
});
