import { Box as MUBox, BoxProps as MUBoxProps, Theme, useTheme } from '@mui/material';
import { CSSProperties, forwardRef } from 'react';

export type BoxProps = {
  backgroundColor?: keyof Theme['colors'];
  bg?: keyof Theme['colors'];
  borderColor?: keyof Theme['colors'];
  color?: keyof Theme['colors'];
  disabled?: boolean;
  opacity?: CSSProperties['opacity'];
} & Omit<MUBoxProps, 'backgroundColor' | 'borderColor' | 'color' | 'ref'>;

export const Box = forwardRef<HTMLDivElement, BoxProps>(
  ({ backgroundColor, bg, borderColor, color, disabled, onClick, opacity, sx, ...rest }, ref) => {
    const theme = useTheme();
    const bgColor = bg || backgroundColor;

    return (
      <MUBox
        display="flex"
        flexDirection="column"
        position="relative"
        minHeight={0}
        minWidth={0}
        flexShrink={0}
        flexBasis="auto"
        {...rest}
        sx={{
          background: bgColor ? theme.colors[bgColor] : undefined,
          borderColor: borderColor ? theme.colors[borderColor] : undefined,
          borderStyle: borderColor ? 'solid' : undefined,
          color: color ? theme.colors[color] : undefined,
          opacity,
          ...sx,
        }}
        ref={ref}
        onClick={disabled ? undefined : onClick}
        role={onClick ? 'button' : undefined}
      />
    );
  },
);
