import classnames from 'classnames';
import React from 'react';

import { LogoGradientImage, LogoImage } from 'shared/assets';

import styles from './Logo.module.scss';

interface LogoProps {
  size?: number;
  variant?: 'inverted' | 'primary' | 'gradient';
}

export const Logo: React.FC<LogoProps> = ({ size = 32, variant }) => {
  const className = classnames(styles.logo, {
    [styles.inverted]: variant === 'inverted',
    [styles.primary]: variant === 'primary',
  });

  return variant === 'gradient' ? (
    <LogoGradientImage className={className} height={size} width={size} />
  ) : (
    <LogoImage className={className} height={size} width={size} />
  );
};
