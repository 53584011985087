import { useMediaQuery } from '@mui/material';
import React, { forwardRef } from 'react';
import { Toast } from 'react-hot-toast';

import { CloseIcon } from 'shared/assets';
import { THEME_COLORS } from 'shared/tokens';
import { Box, BoxProps } from 'shared/ui/Box';

type Variant = 'error' | 'info' | 'warning';

interface BasicToastProps extends BoxProps {
  onClose?: VoidFunction;
  toastProps?: Toast;
  variant?: Variant;
}

const mapVariantToCloseButtonColor: Record<Variant, string> = {
  error: THEME_COLORS['background-01'],
  info: THEME_COLORS['secondary-03'],
  warning: THEME_COLORS['background-01'],
};

const mapVariantToBgColor = {
  error: 'red-01',
  info: 'secondary-02',
  warning: 'orange-01',
} as const;

export const BasicToast = forwardRef<HTMLDivElement, BasicToastProps>(
  ({ bg: background, children, onClose, toastProps, variant = 'info', ...props }, ref) => {
    const isWideMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });

    return (
      <Box
        p={24}
        pr={36}
        borderRadius={16}
        bg={background || mapVariantToBgColor[variant]}
        position="relative"
        minWidth={isWideMobile ? 0 : 342}
        maxWidth={isWideMobile ? '100%' : 342}
        className={toastProps ? (toastProps?.visible ? 'animate-enter' : 'animate-leave') : ''}
        {...props}
        ref={ref}
      >
        {children}
        {onClose && (
          <Box
            borderRadius={16}
            width={32}
            height={32}
            justifyContent="center"
            alignItems="center"
            position="absolute"
            right={8}
            top={8}
            onClick={onClose}
          >
            <CloseIcon width={16} height={16} color={mapVariantToCloseButtonColor[variant]} />
          </Box>
        )}
      </Box>
    );
  },
);
