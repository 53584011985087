import { FormControl, OutlinedInputProps } from '@mui/material';
import { forwardRef, memo } from 'react';

import { StyledErrorText, StyledInput, StyledLabel } from './styled';

type InputProps = Omit<OutlinedInputProps, 'error' | 'size'> & {
  error?: string;
  isOutline?: boolean;
  size?: 'large' | 'medium' | 'small' | 'tiny';
};

const InputInner = forwardRef<HTMLInputElement, InputProps>(
  ({ error, isOutline = false, label, size = 'medium', ...props }, ref) => (
    <FormControl fullWidth>
      {label && (
        <StyledLabel text="app-12-medium" color="secondary-03">
          {label}
        </StyledLabel>
      )}
      <StyledInput {...props} autoComplete="off" $size={size} $isOutline={isOutline} inputRef={ref} />
      {error && (
        <StyledErrorText
          className="triangle-up"
          position="absolute"
          bottom={0}
          zIndex={10}
          borderRadius="8px"
          px={8}
          py={4}
          bgColor="red-01"
          text="app-12-regular"
          left="1rem"
          maxWidth="320px"
        >
          {error}
        </StyledErrorText>
      )}
    </FormControl>
  ),
);

export const Input = memo(InputInner);
