import cx from 'classnames';
import React, { ReactNode } from 'react';

import styles from './Checkbox.module.scss';

type Props = {
  asRow?: boolean;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  isChecked?: boolean;
  setIsChecked?: (checked: boolean) => void;
};

export const Checkbox: React.FC<Props> = (props: Props) => {
  const { isChecked, setIsChecked, disabled, className, asRow } = props;

  return (
    <div
      className={cx(
        styles.checkbox,
        { [styles.disabled]: disabled, [styles.selected]: isChecked, [styles.fullRow]: asRow },
        className,
      )}
      onClick={() => setIsChecked?.(!isChecked)}
    >
      <span className={styles['checkbox-icon-wrapper']}>
        {isChecked && <Checked size={16} className={cx(styles['checkbox-icon'], styles.active)} />}
        {!isChecked && <Checked size={16} className={cx(styles['checkbox-icon'], styles.inactive)} />}
      </span>
      {props.children && <span className={styles['checkbox-label']}>{props.children}</span>}
    </div>
  );
};

const Checked: React.CFC<{ className?: string; size?: number | string }> = ({ className, size = 16 }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="14" height="14" rx="3" stroke="#877CA3" strokeWidth="2" />
  </svg>
);
