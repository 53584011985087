import { supportedChains } from './chains';

type ExplorersInfo = Record<number, { name: string; url: string }>;

const EXPLORERS: ExplorersInfo = supportedChains.reduce(
  (acc, { blockExplorers, id }) => ({
    ...acc,
    [id]: blockExplorers?.default!,
  }),
  {
    42170: {
      name: 'ArbiScan',
      url: 'https://nova.arbiscan.io',
    },
    421613: {
      name: 'ArbiScan',
      url: 'https://goerli.arbiscan.io',
    },
    1564830818: {
      name: 'SKALE',
      url: 'https://honorable-steel-rasalhague.explorer.mainnet.skalenodes.com',
    },
    1351057110: {
      name: 'SKALE',
      url: 'https://staging-fast-active-bellatrix.explorer.staging-v3.skalenodes.com',
    },
  } as ExplorersInfo,
);

export const getChainBlockExplorerLink = (prefix: 'address' | 'tx', hash: string | undefined, chainId: number) => {
  const explorer = EXPLORERS[chainId];

  if (!explorer || !hash) {
    return null;
  }

  const anchor = explorer.name;
  const url = `${explorer.url}/${prefix}/${hash}`;

  return {
    anchor,
    url,
  };
};
