export const TOUR_CLASSES = {
  AMOUNT: { class: 'tour-amount', id: 2 },
  APROVE: { button: 'tour-aprove-button', class: 'tour-aprove', id: 4 },
  FORM_CONNECT: { class: 'tour-form-connect', id: 3 },
  HEADER_CONNECT: { class: 'tour-header-connect', id: 0 },
  INSUFFICIENT_FUNDS: { button: 'tour-inf-button', class: 'tour-inf', id: 7 },
  SWITCH_CHAIN: { button: 'tour-switch-button', class: 'tour-switch', id: 6 },
  PERIOD: { class: 'tour-period', id: 1 },
  START: { button: 'tour-create-button', class: 'tour-create', id: 5 },
  UNAVAILABLE_CREATION: { button: 'tour-unavailable-button', class: 'tour-inf', id: 8 },
};

export const STEPS_CLASSES = Object.values(TOUR_CLASSES)
  .sort((a, b) => a.id - b.id)
  .map((item) => ('button' in item ? item.button : item.class));
