import { observer } from 'mobx-react-lite';
import React, { CFC } from 'react';

import { useGetRatesQuery } from 'entities/Rates/model/useGetRatesQuery';
import { UserStore, userStore } from 'entities/User/model/store';

import { networkStore, NetworkStore } from 'shared/store';

type ContextValue = {
  networkStore: NetworkStore;
  userStore: UserStore;
};

export const AppContext = React.createContext({} as ContextValue);

const store = {
  networkStore,
  userStore,
};

export const AppProviders: CFC = observer(({ children }) => {
  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
});
