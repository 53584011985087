import { useMediaQuery, useTheme } from '@mui/material';
import classNames from 'classnames';
import { CFC, useEffect, useState } from 'react';
import ReactModal, { Props } from 'react-modal';

import { CloseIcon } from 'shared/assets';
import { Box } from 'shared/ui/Box';

import styles from './Modal.module.scss';

export interface ModalProps extends Props {
  className?: string;
  closeCallback?: () => void;
  closeIcon?: boolean;
  closeIconPaddingX?: string;
  closeIconPaddingY?: string;
  contentPadding?: string;
  forceDesktopLayout?: boolean;
  header?: React.ReactNode;
  maxWidth?: number | string;
  onClose: (e: React.MouseEvent<Element, MouseEvent>) => void;
  stickyTop?: boolean;
  zIndex?: number;
}

export const Modal: CFC<ModalProps> = (props) => {
  const {
    children,
    className,
    closeCallback,
    closeIcon,
    closeIconPaddingX = 8,
    closeIconPaddingY = 14,
    contentPadding,
    isOpen,
    forceDesktopLayout,
    maxWidth,
    onClose: onRequestClose,
    stickyTop,
  } = props;
  const { colors } = useTheme();
  const isWideMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });

  const isMobileLayout = !forceDesktopLayout && isWideMobile;

  const [isOpenedOnce, setOpenedOnce] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const classes = document.body.className;
      document.body.className = `${classes} overflow-hidden`;
      document.body.style.width = '100vw';
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
      setOpenedOnce(true);
    }

    if (!isOpen && isOpenedOnce) {
      const classes = document.body.className;
      document.body.className = classes.replace('overflow-hidden', '');
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      if (scrollY) window.scrollTo(0, parseInt(scrollY, 10) * -1);
    }
  }, [isOpen]);

  const onClose = (e: React.MouseEvent<Element, MouseEvent>) => {
    onRequestClose?.(e);
  };

  return (
    <ReactModal
      closeTimeoutMS={250}
      preventScroll
      onAfterClose={() => {
        if (closeCallback) closeCallback();
      }}
      overlayElement={({ style, ...rest }, contentEl) => (
        <div
          style={{
            ...style,
            alignItems: 'center',
            backgroundColor: colors['overlay-02'],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            zIndex: props.zIndex || 10,
          }}
          // first click on overlay doesn't close the modal if something is focused
          // this is a workaround for a known issue:
          // https://github.com/reactjs/react-modal/issues/892
          // https://github.com/reactjs/react-modal/issues/958
          // https://github.com/reactjs/react-modal/issues/826
          {...rest}
          onClick={(e) => {
            if (e.target === e.currentTarget) onClose(e);
            e.stopPropagation();
          }}
        >
          {contentEl}
        </div>
      )}
      contentElement={({ style, ...rest }, children) => (
        <div
          style={{
            backgroundColor: colors['background-01'],
            borderColor: colors['secondary-02'],
            borderRadius: isMobileLayout ? 'unset' : 24,
            borderStyle: isMobileLayout ? undefined : 'solid',
            borderWidth: isMobileLayout ? 0 : 1,
            display: 'flex',
            flexDirection: 'column',
            height: isMobileLayout ? '100%' : 'auto',
            justifyContent: 'flex-start',
            maxHeight: isMobileLayout ? '100%' : '90vh',
            maxWidth: maxWidth || 500,
            overflow: 'auto',
            padding: contentPadding ?? '22px 0 0 0',
            width: !isMobileLayout && isWideMobile ? 'calc(100% - 32px)' : '100%',
            marginLeft: !isMobileLayout && isWideMobile ? '16px' : undefined,
            marginRight: !isMobileLayout && isWideMobile ? '16px' : undefined,
            zIndex: 11,
          }}
          {...rest}
          className={classNames(className, 'hide-scroll', styles.modal, {
            [styles.open]: isOpen,
            [styles.stickyTop]: stickyTop && !isMobileLayout,
          })}
        >
          {closeIcon && (
            <Box
              justifyContent="center"
              alignItems="center"
              width={40}
              height={40}
              position="absolute"
              top={closeIconPaddingY}
              right={closeIconPaddingX}
              zIndex={1}
              onClick={onClose}
            >
              <CloseIcon width={24} height={24} />
            </Box>
          )}
          {children}
        </div>
      )}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      {...props}
      onRequestClose={onClose}
    >
      {props.header && props.header}
      {children}
    </ReactModal>
  );
};
