import { useEffect, useState } from 'react';

import { TokensStorage } from 'entities/Token/lib/index';
import { TokenFullData } from 'entities/Token/model/types';

import { useNetwork } from 'shared/hooks/network';

import { useSwapTokens } from './useSwapTokens';

export const useSwapTokenAdd = (args?: { cache?: boolean }) => {
  const { selectedChainId: chainId } = useNetwork();
  const { data: tokensData, cache: tokensCache } = useSwapTokens();

  const [addFunc, setAddFunc] = useState<(token: TokenFullData) => void>();

  useEffect(() => {
    if (tokensData && tokensCache) {
      const tokens = args?.cache ? [...tokensData, ...tokensCache] : tokensData;

      const add = (token: TokenFullData) => {
        if (token) {
          const sameTokenByAddress = tokens.find((t) => t.address === token.address);

          if (!sameTokenByAddress) {
            const sameTokenBySymbols = tokens.filter(
              (t) => t.symbol === token.symbol || t.symbol.startsWith(`${token.symbol}_`),
            );

            const newToken = {
              ...token,
            };

            if (sameTokenBySymbols.length) {
              let lastIndex = 0;

              for (const token of sameTokenBySymbols) {
                if (!token.symbol.includes('_') && lastIndex === 0) {
                  lastIndex = 1;
                  return;
                }

                const [, index] = token.symbol.split('_');

                if (index && parseFloat(index) > lastIndex) {
                  lastIndex = parseFloat(index);
                  return;
                }
              }

              newToken.symbol = lastIndex ? `${token.symbol}_${lastIndex}` : token.symbol;
            }

            TokensStorage.add({ chainId, cache: Boolean(args?.cache) }, newToken);
          }
        }
      };

      setAddFunc(() => add);
    }
  }, [tokensData, tokensCache, chainId]);

  return addFunc;
};
