import { environment } from 'shared/config';
import { LOCAL_STORAGE_MOCK_ADDRESS_KEY } from 'shared/lib/constants';

import { bitkeep } from './bitkeep';
import { coinbase } from './coinbase';
import { cryptoWallet } from './crypto';
// import { imToken } from './imToken';
import { injected } from './injected';
// import { math } from './math';
import { metamask } from './metamask';
import { mock } from './mock';
import { okx } from './okx';
import { particleGoogle } from './particleGoogle';
import { phantom } from './phantom';
import { safePal } from './safePal';
import { trustWallet } from './trust';
import { walletConnect2 } from './walletConnect';
import { zerion } from './zerion';

export const availableWallets = [
  metamask,
  coinbase,
  walletConnect2,
  trustWallet,
  cryptoWallet,
  bitkeep,
  safePal,
  //math,
  okx,
  zerion,
  // coin98,
  phantom,
  particleGoogle,
  // imToken,
  injected,
];

if (environment !== 'production' && window.ethereum && localStorage.getItem(LOCAL_STORAGE_MOCK_ADDRESS_KEY)) {
  availableWallets.push(mock);
}
