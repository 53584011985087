import React from 'react';
import { useTranslation } from 'react-i18next';

import { OnrampAssetField, useOnrampForm } from 'entities/Onramp';

export const OnrampOutputField = () => {
  const { t } = useTranslation();

  const { output, update } = useOnrampForm();

  return (
    <OnrampAssetField
      title={t('common.youGet')}
      value={output}
      disabled
      onChange={(value) => {
        update({ output: value });
      }}
    />
  );
};
