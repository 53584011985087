import { CFC } from 'react';

import { Box } from '../Box';

import { StyledText } from './styled';

export type ErrorProps = {
  align?: 'left' | 'right';
  left?: number;
  message?: string;
  right?: number;
  width?: string;
};

export const Error: CFC<ErrorProps> = ({ align = 'left', children, message, ...props }) => (
  <Box position="relative" zIndex={1}>
    {children}
    {message && (
      <StyledText
        $align={align}
        className="triangle-up"
        position="absolute"
        bottom={0}
        zIndex={10}
        borderRadius="8px"
        px={8}
        py={4}
        bgColor="red-01"
        text="app-12-regular"
        left={align === 'left' ? '0' : 'unset'}
        right={align === 'right' ? '0' : 'unset'}
        maxWidth="320px"
        {...props}
      >
        {message}
      </StyledText>
    )}
  </Box>
);
