import React, { useState } from 'react';

import { OnrampCryptoCurrency } from 'entities/Onramp/model';

import { Box, IconByUrl, Text } from 'shared/ui';
import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

import { OnrampChainIcon } from './OnrampChainIcon';

interface Props extends Omit<React.ComponentProps<typeof CurrencyIcon>, 'ticker' | 'url'> {
  currency: OnrampCryptoCurrency | null;
}

export const OnrampAssetIcon: React.FC<Props> = (props) => {
  const [isError, setIsError] = useState(false);

  return (
    <>
      <Box height={props.size} width={props.size} borderRadius={40} {...props}>
        {props.currency && !isError && (
          <IconByUrl {...props} url={`/static/tickers/${props.currency.ticker}.svg`} onError={() => setIsError(true)} />
        )}
        {(!props.currency || isError) && <FallbackAssetIcon {...props} />}
        <Box
          height={props.size ? props.size * 0.5 : undefined}
          width={props.size ? props.size * 0.5 : undefined}
          position="absolute"
          right={props.size ? -(props.size * 0.125) : 0}
          bottom={props.size ? -(props.size * 0.1) : 0}
          backgroundColor="background-01"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          borderRadius={40}
        >
          <OnrampChainIcon
            {...props}
            chain={props.currency?.chain}
            size={props.size ? props.size * 0.375 : undefined}
          />
        </Box>
      </Box>
    </>
  );
};

const FallbackAssetIcon: React.FC<Props> = (props) => (
  <>
    <Box
      height={props.size}
      width={props.size}
      backgroundColor="secondary-01"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      borderRadius={40}
      {...props}
    >
      <Text style={{ fontSize: (props.size as number) / 2.8 }}>
        {props.currency?.ticker.toUpperCase().replace(/[a-z]/g, '').substring(0, 3)}
      </Text>
    </Box>
  </>
);
