import { OnrampProviderType } from './general';

export enum OnrampTokenType {
  CRYPTO = 'crypto',
  FIAT = 'fiat',
}

export type OnrampTokensRequestArgs = {
  provider: OnrampProviderType;
  type: OnrampTokenType;
};

export type OnrampTokensResponse = {
  chain: string;
  chainId: number;
  code: string;
  name: string;
  ticker: string;
}[];
