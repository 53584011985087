import React, { useEffect, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useMountedEffect } from 'shared/hooks';

import { Box } from '../Box';
import { Text } from '../Text';

import { ReactComponent as TimeIcon } from './time.svg';

interface CountdownProps {
  callback?: () => void;
  to: number;
}

const formatDigits = (_value: number) => {
  let value = `${_value}`;
  if (value.length <= 1) {
    value = `0${value}`;
  }

  return value;
};

export const Countdown: FC<CountdownProps> = ({ callback, to }) => {
  const { t } = useTranslation();

  const [diff, setDiff] = useState<number>(0);
  const [isCallbackCalled, setCallbackCalled] = useState<boolean>(false);

  useEffect(() => {
    const id = setInterval(() => {
      setDiff(to - Date.now());
    }, 100);

    return () => {
      clearInterval(id);
    };
  }, [setDiff, to]);

  useMountedEffect(
    (isMounted) => {
      if (isMounted() && callback && !isCallbackCalled && diff < 0) {
        callback();
        setCallbackCalled(true);
      }
    },
    [diff, isCallbackCalled, callback],
  );

  const totalSeconds = Math.floor(diff / 1000);
  const seconds = formatDigits(totalSeconds > 0 ? totalSeconds % 60 : 0);

  const totalMinutes = Math.floor(totalSeconds / 60);
  const minutes = formatDigits(totalMinutes > 0 ? totalMinutes % 60 : 0);

  const totalHours = Math.floor(totalMinutes / 60);
  const hours = formatDigits(totalHours > 0 ? totalHours : 0);

  if (!diff) {
    return null;
  }

  return (
    <Box flexDirection="row" alignItems="center">
      <Box alignSelf="center" mr={8} color="primary-01">
        <TimeIcon width={16} height={16} />
      </Box>
      <Box>
        <Text color="primary-01">
          {t('common.hoursShort', { value: hours })}:{t('common.minutesShort', { value: minutes })}:
          {t('common.secondsShort', { value: seconds })}
        </Text>
      </Box>
    </Box>
  );
};
