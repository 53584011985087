import axios from 'axios';

import { environment } from 'shared/config';
import { BACKEND_HOST } from 'shared/config/api';
import { LOCAL_STORAGE_BASE_URL_KEY } from 'shared/lib/constants';

export const requester = axios.create({
  baseURL: (environment !== 'production' && localStorage.getItem(LOCAL_STORAGE_BASE_URL_KEY)) || BACKEND_HOST,
  headers: {},
  withCredentials: true,
});
