import { getImageChain } from 'entities/Chain';

import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

import { routerAbi } from './abi';
import { AppChain } from './types';

export const bscTestnet: AppChain<'bsc-testnet'> = {
  blockExplorers: {
    default: {
      name: 'BscScan',
      url: 'https://testnet.bscscan.com',
    },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  disabled: false,
  id: 97,
  logo: <CurrencyIcon url={getImageChain('bsc')} ticker="bnb" size={24} />,
  name: 'BNB Chain Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'BNB',
    symbol: 'BNB',
  },
  network: 'bsc-testnet',
  tokenStandard: 'BEP-20',
  renderLogo: (size) => <CurrencyIcon url={getImageChain('bsc')} ticker="bnb" size={size} />,
  router: {
    abi: routerAbi,
    address: '0x1D131EBd06E511e1B6d0ad40EdD05B277347A9Fa',
  },
  rpcUrls: {
    default: {
      http: ['https://data-seed-prebsc-2-s2.binance.org:8545'],
    },
    public: {
      http: ['https://data-seed-prebsc-2-s2.binance.org:8545'],
    },
  },
  testnet: true,
  vault: {
    address: '0x24312B0405032e51E6708cd592AF91ee8049f9f2',
  },
  tokens: {
    usdt: { address: '0x4469862eFF2A34fc8DF656886c01632877409d47' },
    usdc: { address: '0x5c32F80319871dD2b9485322126972E5B5DEF2A4' },
  },
};
