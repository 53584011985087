import { makeAutoObservable } from 'mobx';

import { supportedChains } from 'shared/config/chains';
import { availableWallets } from 'shared/lib/rehold-wallets';

const urlParams = new URLSearchParams(window?.location?.search);
const chainFromParams = Number(urlParams.get('chainId'));

export const initialChainId = chainFromParams || Number(localStorage.getItem('REHOLD_CHAIN_ID'));
const selectedChainId = supportedChains.some((c) => c.id === initialChainId) ? initialChainId : supportedChains[0].id;

const initialConnectorId = localStorage.getItem('REHOLD_CONNECTOR_ID');
const lastConnectorId = availableWallets.some((w) => w.connector.id === initialConnectorId) ? initialConnectorId : null;

export class NetworkStore {
  lastConnectorId: null | string = lastConnectorId;
  selectedChainId: number = selectedChainId;
  switchingTo: null | number = null;

  constructor() {
    makeAutoObservable(this);
  }

  selectNetwork(selectedChainId: number) {
    this.selectedChainId = selectedChainId;

    localStorage.setItem('REHOLD_CHAIN_ID', String(selectedChainId));
  }

  setLastConnectorId(id: null | string) {
    this.lastConnectorId = id;

    if (!id) {
      localStorage.removeItem('REHOLD_CONNECTOR_ID');
      return;
    }

    localStorage.setItem('REHOLD_CONNECTOR_ID', id);
  }

  switchReset() {
    this.switchingTo = null;
  }

  switchTo(switchingTo: null | number) {
    this.switchingTo = switchingTo;
  }
}

export const networkStore = new NetworkStore();
