import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetRatesQuery } from 'entities/Rates';

import { useNetwork } from 'shared/hooks/network';
import { Box, Button, Spinner } from 'shared/ui';

import { useCalculateOutput } from '../../lib';
import { DualTariff } from '../../model';
import { DualChart } from '../DualChart';

type Props = {
  inputAmount: string;
  inputTicker: string;
  tariff: DualTariff;
};

export const DualChartPreview: FC<Props> = observer(({ inputAmount, inputTicker, tariff: currentTariff }) => {
  const { t } = useTranslation();

  const { chainId } = useNetwork();

  const { output, rateObj } = useCalculateOutput({
    currentTariff,
    inputAmount,
    inputTicker,
  });

  const baseTicker = currentTariff?.baseTicker || '';
  const quoteTicker = currentTariff?.quoteTicker || '';

  const { isError, isLoading, refetch } = useGetRatesQuery();

  if (isError) {
    return (
      <Box height={150} justifyContent="center" alignItems="center">
        <Button onClick={() => refetch()} data-id="retry-chart-preview">
          {t('common.fetchRetry')}
        </Button>
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Box height={150} justifyContent="center" alignItems="center">
        <Spinner />
      </Box>
    );
  }

  if (!output || !rateObj?.ready || !currentTariff) {
    return null;
  }

  return (
    <DualChart
      baseTicker={baseTicker}
      quoteTicker={quoteTicker}
      entryPrice={rateObj.formatted.price}
      stakingPeriod={t('common.hoursWithCount', {
        count: currentTariff?.stakingPeriod,
      })}
      calculation={output}
      chainId={chainId}
    />
  );
});
