import { useQuery } from '@tanstack/react-query';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';

import { getDnsName } from '../api/queries';

export const useGetDomain = () => {
  const { chainId } = useNetwork();
  const { address } = useAccount();

  const { data } = useQuery({
    enabled: !!chainId && !!address,
    queryFn: () => getDnsName({ address: address!, chainId: chainId! }),
    queryKey: [`get-dns-${chainId}-${address}`],
  });

  return { name: data?.name || null };
};
