import { styled, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CSSProperties } from 'react';

import { shouldForwardProp } from 'shared/lib/shouldForwardDomProp';

type Props = {
  $bgColor?: keyof Theme['colors'];
  $lineClamp?: number;
  $opacity?: CSSProperties['opacity'];
  $text: keyof Theme['text'];
  $textColor?: keyof Theme['colors'];
  $textDecoration?: CSSProperties['textDecoration'];
  $textIndent?: CSSProperties['textIndent'];
  $wordBreak?: CSSProperties['wordBreak'];
  className?: string;
};

export const StyledText = styled(Typography, {
  shouldForwardProp,
})<Props>(
  ({
    $bgColor,
    $lineClamp,
    $opacity,
    $text,
    $textColor,
    $textDecoration,
    $textIndent,
    $wordBreak,
    fontSize,
    lineHeight,
    theme,
  }) => ({
    color: $textColor ? theme.colors[$textColor] : 'var(--default-font-color)',
    fontSize: typeof fontSize === 'number' ? `${fontSize}px` : theme.text[$text].fontSize,
    opacity: $opacity,
    ...($wordBreak && { wordBreak: $wordBreak }),
    ...($textDecoration && { textDecoration: $textDecoration }),
    ...($textIndent && { textIndent: $textIndent }),
    fontFamily: theme.fontFamily,
    fontWeight: $text ? theme.text[$text].fontWeight : ('var(--default-font-weight)' as CSSProperties['fontWeight']),
    lineHeight: typeof lineHeight === 'number' ? `${lineHeight}px` : theme.text[$text].lineHeight,
    overflowWrap: 'break-word',
    transition: 'color 0.25s ease',
    ...($lineClamp && {
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: $lineClamp,
      display: '-webkit-box',
      maxWidth: 'max-content',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'initial',
      wordBreak: $wordBreak || 'break-word',
    }),

    ...($bgColor && { backgroundColor: theme.colors[$bgColor] }),
  }),
);
