import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';
import { Address } from 'viem';

import { getSwapQuote } from 'entities/Swap/api';
import { useIsWETH } from 'entities/Swap/lib/hooks/useIsWETH';

import { useNetwork } from 'shared/hooks/network';

import { SwapQuoteRequestArgs, SwapQuoteResponse } from '../quote';

export const useGetSwapQuoteQuery = (
  args: Partial<SwapQuoteRequestArgs>,
  options?: UseQueryOptions<SwapQuoteResponse, SwapQuoteRequestArgs>,
) => {
  const { chainId } = useNetwork();

  const isWETH = useIsWETH(args.fromToken as Address, args.toToken as Address);

  const isAllowedToFetch =
    args &&
    args.chainId &&
    args.fromAmount &&
    BigNumber(args.fromAmount).isGreaterThan(0) &&
    args.fromToken &&
    args.toToken &&
    !isWETH;

  return useQuery<SwapQuoteResponse, SwapQuoteRequestArgs>(
    [`swap-quote-${chainId}-${args.fromAmount}-${args.fromToken}-${args.toToken}`],
    () =>
      isAllowedToFetch
        ? getSwapQuote({
            chainId: chainId!,
            fromAmount: args.fromAmount!,
            fromToken: args.fromToken!,
            toToken: args.toToken!,
          })
        : null,
    {
      ...options,
      enabled:
        typeof options?.enabled === 'undefined'
          ? !!isAllowedToFetch && !!chainId
          : options.enabled && !!isAllowedToFetch && !!chainId,
      cacheTime: 1000,
    },
  );
};
