import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';

import { getOnrampQuote } from 'entities/Onramp/api';

import { OnrampQuoteRequestArgs, OnrampQuoteResponse } from '../quote';

export const useGetOnrampQuoteQuery = (
  args: Partial<OnrampQuoteRequestArgs>,
  options?: UseQueryOptions<OnrampQuoteResponse | null, OnrampQuoteRequestArgs>,
) => {
  const isAllowedToFetch =
    args && args.fromTokenCode && args.fromAmount && BigNumber(args.fromAmount).gt(0) && args.toTokenCode;

  return useQuery<OnrampQuoteResponse | null, OnrampQuoteRequestArgs>(
    [`onramp-quote-${args.provider}-${args.fromTokenCode}-${args.toTokenCode}-${args.fromAmount}`],
    () =>
      isAllowedToFetch
        ? getOnrampQuote({
            provider: args.provider!,
            fromAmount: args.fromAmount!,
            fromTokenCode: args.fromTokenCode!,
            toTokenCode: args.toTokenCode!,
          })
        : null,
    {
      ...options,
      enabled: typeof options?.enabled === 'undefined' ? !!isAllowedToFetch : options.enabled && !!isAllowedToFetch,
      cacheTime: 1000,
    },
  );
};
