import classnames from 'classnames';
import { FC, useCallback } from 'react';
import { To, useNavigate } from 'react-router-dom';

import { BackIcon } from 'shared/assets';
import { useHistoryApp, useWidget } from 'shared/hooks';

import { Box } from '../Box';

import styles from './BackButton.module.scss';

interface Props {
  hoverable?: boolean;
  onPress?: () => void;
  to?: To;
}

export const BackButton: FC<Props> = ({ onPress, to = -1 as To, hoverable }) => {
  const navigate = useNavigate();
  const isWidget = useWidget();

  const history = useHistoryApp();

  const handlePressBack = useCallback(() => {
    if (history && history.length >= 1) navigate(to, { preventScrollReset: false });
    else navigate(isWidget ? '/widgets/dual' : '/', { preventScrollReset: false });

    onPress?.();
  }, [navigate, onPress, to, history, isWidget]);

  return (
    <Box
      className={classnames({ [styles['hoverable-button']]: !!hoverable })}
      justifyContent="center"
      alignItems="center"
      width={40}
      height={40}
      onClick={handlePressBack}
      color="white-01"
    >
      <BackIcon width={24} height={24} />
    </Box>
  );
};
