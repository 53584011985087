import { requester } from 'shared/api';
import { CHAIN_INFO } from 'shared/config/chains';

import { FullTokenListsResponse, TokenFullData, TokenList, TokenListsResponse, TokensState } from '../model/types';

export const getDualTokenLists = async () =>
  requester.get<FullTokenListsResponse>(`/token-lists.json`, { baseURL: '/' }).then((res) =>
    Object.entries(res.data).reduce((acc, [chainId, tokens]) => {
      const network = CHAIN_INFO[+chainId];

      if (!network) return acc;

      acc[+chainId] = {};

      const wrapToken = `w${network.nativeCurrency.symbol}`.toLowerCase();
      const nativeToken = network.nativeCurrency.symbol.toLowerCase();

      (tokens as TokenList).forEach((token) => {
        acc[+chainId][token.symbol.toLowerCase()] = {
          ...token,
          chainId: +chainId,
        };
        acc[+chainId][token.address.toLowerCase()] = {
          ...token,
          chainId: +chainId,
        };

        if (token.symbol.toLowerCase() === wrapToken) {
          acc[+chainId][nativeToken] = {
            address: undefined,
            decimals: token.decimals,
            logoURI: `/static/tickers/${nativeToken}.svg`,
            symbol: network.nativeCurrency.symbol,
            chainId: +chainId,
          };
        }
      });

      return acc;
    }, {} as Record<number, TokensState>),
  );

export const getDualTokenList = (chainId: number) =>
  requester.get<TokenListsResponse>(`/token-lists/${chainId}.json`, { baseURL: '/' }).then((res) => {
    const network = CHAIN_INFO[chainId];
    const wrapToken = `w${network.nativeCurrency.symbol}`.toLowerCase();
    const nativeToken = network.nativeCurrency.symbol.toLowerCase();

    return res.data.tokens.reduce((acc, token) => {
      acc[token.symbol.toLowerCase()] = { ...token, chainId };
      acc[token.address.toLowerCase()] = {
        ...token,
        chainId,
      };

      if (token.symbol.toLowerCase() === wrapToken) {
        acc[nativeToken] = {
          address: undefined,
          decimals: token.decimals,
          logoURI: `/static/tickers/${nativeToken}.svg`,
          symbol: network.nativeCurrency.symbol,
          chainId,
        };
      }

      return acc;
    }, {} as TokensState);
  });

export const getSwapTokenList = (chainId: number) =>
  requester.get<TokenFullData[]>(`/api/v1/tokens/${chainId}`, { baseURL: '/' }).then((res) => res.data);
