import { StyledEngineProvider, ThemeProvider as MUThemeProvider } from '@mui/material';
import useResizeObserver from '@react-hook/resize-observer';
import { QueryClientProvider } from '@tanstack/react-query';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React, { Suspense, useCallback, useEffect, useLayoutEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { RouterProvider } from 'react-router-dom';

import { router } from 'app/appRouter';
import { ErrorBoundary } from 'app/lib';

import { WaitForUserActionModal } from 'entities/User';

import { WagmiProvider } from 'shared/lib/providers';
import { queryClient } from 'shared/lib/react-query';
import { i18n, I18nextProviderWithSpy } from 'shared/locales';
import theme from 'shared/theme';

import { AppProviders } from './appProvider';

export const App = () => (
  <ErrorBoundary fallback={(reset) => <Fallback reset={reset} />}>
    <StyledEngineProvider injectFirst>
      <MUThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AppProviders>
            <WagmiProvider>
              <I18nextProviderWithSpy i18n={i18n}>
                {/* <Intercom /> */}
                <ViewportSpy />
                <Suspense>
                  <RouterProvider router={router} />
                </Suspense>
                <Toaster position="top-right" reverseOrder={false} gutter={8} />
                <WaitForUserActionModal />
              </I18nextProviderWithSpy>
            </WagmiProvider>
          </AppProviders>
        </QueryClientProvider>
      </MUThemeProvider>
    </StyledEngineProvider>
  </ErrorBoundary>
);

const ViewportSpy: React.FC = () => {
  const setHeight = useCallback(() => {
    // iOS Safari white line at the bottom (in Coinbase and other specific cases)
    // https://stackoverflow.com/questions/20308351/mystical-white-stripe-at-bottom-on-safari-ios

    const height = `${window.innerHeight}px`;

    if (document.documentElement.style.height !== height) {
      document.documentElement.style.height = height;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setHeight);

    return window.removeEventListener('resize', setHeight);
  }, [setHeight]);

  useResizeObserver(document.documentElement, setHeight);
  useLayoutEffect(setHeight, [setHeight]);

  return null;
};

const Fallback: React.FC<{ reset: () => void }> = ({ reset }) => (
  <div
    style={{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 16,
    }}
  >
    <h3>Something went wrong</h3>
    <button
      style={{
        outline: 0,
        border: 0,
        margin: 0,
        padding: '0px 16px',
        color: 'black',
        backgroundColor: '#05f283',
        width: '300px',
        height: 40,
        borderRadius: 24,
        transition: 'all 0.2s ease 0s',
      }}
      onClick={reset}
    >
      Retry
    </button>
  </div>
);
