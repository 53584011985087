import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { getOnrampTokens } from 'entities/Onramp/api';

import { OnrampTokensRequestArgs, OnrampTokensResponse } from '../tokens';

export const useGetOnrampTokensQuery = (
  args: OnrampTokensRequestArgs,
  options?: UseQueryOptions<OnrampTokensResponse, OnrampTokensRequestArgs>,
) => {
  const isAllowedToFetch = !!args;

  return useQuery<OnrampTokensResponse, OnrampTokensRequestArgs>(
    [`onramp-tokens-${args.provider}-${args.type}`],
    () =>
      getOnrampTokens({
        provider: args.provider,
        type: args.type,
      }),
    {
      ...options,
      enabled: typeof options?.enabled === 'undefined' ? !!isAllowedToFetch : options.enabled && !!isAllowedToFetch,
      cacheTime: 1000,
    },
  );
};
