import { styled } from '@mui/material/styles';

import { shouldForwardProp } from 'shared/lib/shouldForwardDomProp';

type Props = {
  $size: 'icon' | 'lg' | 'md' | 'sm';
  $variant: 'attention' | 'dark' | 'primary' | 'secondary';
};

export const StyledButton = styled('button', { shouldForwardProp })<Props>(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '16px',
  cursor: 'pointer',
  display: 'flex',
  fontSize: theme.text['app-14-medium'].fontSize,
  fontWeight: theme.text['app-14-medium'].fontWeight,
  gap: '8px',
  justifyContent: 'center',
  outline: '0',
  padding: '16px',
  [theme.breakpoints.up('tablet')]: {
    fontSize: theme.text['app-16-medium'].fontSize,
    fontWeight: theme.text['app-16-medium'].fontWeight,
  },

  userSelect: 'none',
}));
