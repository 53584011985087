import { useAccount as useWagmiAccount } from 'wagmi';

type WagmiAccountType = typeof useWagmiAccount;
type Arguments<T> = T extends (args: infer R) => any ? R : never;

export const useAccount = (props?: Arguments<WagmiAccountType>) => {
  const result = useWagmiAccount(props);

  return {
    ...result,
    address: result.address?.toLowerCase() as `0x${string}` | undefined,
  };
};
