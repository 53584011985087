import { observer } from 'mobx-react-lite';
import React, { CFC, useState } from 'react';

import { OnrampProviderType } from 'entities/Onramp/model';

type Props = {
  changeProvider: (provider: OnrampProviderType) => void;
  provider: OnrampProviderType;
};

export const OnrampProviderContext = React.createContext({} as Props);

export const OnrampProvider: CFC = observer(({ children }) => {
  const [provider, setProvider] = useState<OnrampProviderType>(OnrampProviderType.MOONPAY);

  return (
    <OnrampProviderContext.Provider value={{ provider, changeProvider: setProvider } as Props}>
      {children}
    </OnrampProviderContext.Provider>
  );
});
