import { useMutation } from '@tanstack/react-query';

import { updateUser } from '../api/queries';

import { useUserStore } from './useUserStore';

export const useUserMutation = () => {
  const { setUser } = useUserStore();
  return useMutation(['user-update'], updateUser, {
    onSuccess: (result) => {
      setUser(result.data);
    },
  });
};
