export interface ILocalStorageService {
  get<T>(key: string): T | null;
  remove(key: string): void;
  set(key: string, value: Record<string, unknown>): void;
}

export const storage = (store: Storage) => ({
  get<T>(key: string): T | null {
    const state = store.getItem(key);
    return state ? JSON.parse(state) : null;
  },

  remove(key: string) {
    store.removeItem(key);
  },

  set(key: string, value: Record<string, unknown>) {
    const prevState = store.getItem(key);

    const res = prevState && JSON.parse(prevState);
    const state = prevState ? JSON.stringify({ ...res, ...value }) : JSON.stringify({ ...value });

    store.setItem(key, state);
  },
});

export const LocalStorage = storage(localStorage);
export const SessionStorage = storage(sessionStorage);
