import { styled } from '@mui/material';

export const StyledContainer = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}));

type StyledButtonProps = {
  $hide: boolean;
  $type: 'next' | 'prev';
};

export const StyledButton = styled('button')<StyledButtonProps>(({ $hide, $type, theme }) => ({
  '&:hover': {
    opacity: 0.8,
    transition: '0.25s ease',
  },
  alignItems: 'center',
  borderRadius: '44px',
  cursor: 'pointer',
  display: 'flex',
  height: '32px',
  justifyContent: 'center',
  transition: '0.25s ease',

  width: '48px',

  ...($hide && {
    visibility: 'hidden',
  }),

  ...($type === 'prev' && {
    background: theme.colors['secondary-02'],
    color: theme.colors['secondary-03'],
    transform: 'rotate(180deg)',
  }),

  ...($type === 'next' && {
    background: theme.colors['primary-01'],
    color: theme.colors['background-01'],
  }),
}));

export const StyledCircles = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
}));

export const StyledCircle = styled('button')<{ $isActive: boolean }>(({ $isActive, theme }) => ({
  background: $isActive ? theme.colors['primary-01'] : theme.colors['secondary-02'],
  borderRadius: '8px',
  cursor: 'pointer',
  height: '8px',
  width: $isActive ? '16px' : '8px',
}));
