import { Address } from 'viem';

import { TokenFullData } from '../model/types';

import { getTokenByAddress } from './getTokenByAddress';
import { getTokenBySymbol } from './getTokenBySymbol';

type StorageContext = {
  cache?: boolean;
  chainId: number;
};

export class TokensStorage {
  static key({ chainId, cache = false }: StorageContext) {
    return `REHOLD-STORAGE-TOKENS-${chainId}${cache ? '-CACHE' : ''}`;
  }

  static get({ chainId, cache = false }: StorageContext) {
    const tokensKey = this.key({ chainId, cache });

    return JSON.parse(localStorage.getItem(tokensKey) || '[]') as TokenFullData[];
  }

  static getByAddress({ chainId, cache = false }: StorageContext, address: Address) {
    const tokens = this.get({ chainId, cache });

    return getTokenByAddress<TokenFullData>(tokens, address);
  }

  static getBySymbol({ chainId, cache = false }: StorageContext, symbol: string) {
    const tokens = this.get({ chainId, cache });

    return getTokenBySymbol<TokenFullData>(tokens, symbol);
  }

  static set({ chainId, cache = false }: StorageContext, tokens: TokenFullData[]) {
    const tokensKey = this.key({ chainId, cache });

    localStorage.setItem(tokensKey, JSON.stringify(tokens));
  }

  static add({ chainId, cache = false }: StorageContext, token: TokenFullData) {
    const prevTokens = this.get({ chainId, cache });

    const newTokens = [...prevTokens, token];

    this.set({ chainId, cache }, newTokens);
  }

  static clear({ chainId, cache = false }: StorageContext) {
    this.set({ chainId, cache }, []);
  }

  static deleteByAddress({ chainId, cache = false }: StorageContext, address: Address) {
    const prevTokens = this.get({ chainId, cache });

    const newTokens = prevTokens.filter((token) => token.address !== address);

    this.set({ chainId, cache }, newTokens);
  }

  static deleteBySymbol({ chainId, cache = false }: StorageContext, symbol: string) {
    const prevTokens = this.get({ chainId, cache });

    const newTokens = prevTokens.filter((token) => token.symbol !== symbol);

    this.set({ chainId, cache }, newTokens);
  }
}
