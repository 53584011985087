import { UseQueryResult } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import React, { CFC, useMemo } from 'react';

import {
  OnrampTokenType,
  OnrampTokensRequestArgs,
  OnrampTokensResponse,
  useGetOnrampTokensQuery,
} from 'entities/Onramp/model';

import { useOnrampProvider } from '../hooks/useOnrampProvider';

type Props = {
  isLoading: boolean;
  queryCrypto: UseQueryResult<OnrampTokensResponse, OnrampTokensRequestArgs>;
  queryFiat: UseQueryResult<OnrampTokensResponse, OnrampTokensRequestArgs>;
  refetch: () => void;
  tokensCrypto: OnrampTokensResponse;
  tokensFiat: OnrampTokensResponse;
} & Omit<Omit<UseQueryResult<OnrampTokensResponse, unknown>, 'refetch'>, 'data'>;

export const OnrampTokensContext = React.createContext({} as Props);

export const OnrampTokensProvider: CFC = observer(({ children }) => {
  const { provider } = useOnrampProvider();

  const queryCrypto = useGetOnrampTokensQuery({ provider, type: OnrampTokenType.CRYPTO });

  const {
    data: dataCrypto,
    refetch: refetchCrypto,
    isLoading: isCryptoLoading,
    isFetching: isCryptoFetching,
    isRefetching: isCryptoRefetching,
  } = queryCrypto;

  const queryFiat = useGetOnrampTokensQuery({ provider, type: OnrampTokenType.FIAT });

  const {
    data: dataFiat,
    refetch: refetchFiat,
    isLoading: isFiatLoading,
    isFetching: isFiatFetching,
    isRefetching: isFiatRefetching,
  } = queryFiat;

  const refetch = () => {
    refetchCrypto();
    refetchFiat();
  };

  const value = useMemo(
    () => ({
      isLoading:
        isCryptoLoading ||
        isCryptoFetching ||
        isCryptoRefetching ||
        isFiatLoading ||
        isFiatFetching ||
        isFiatRefetching,
      queryCrypto,
      queryFiat,
      refetch,
      tokensCrypto: dataCrypto || [],
      tokensFiat: dataFiat || [],
    }),
    [
      queryCrypto,
      queryFiat,
      refetch,
      dataCrypto,
      dataFiat,
      isCryptoLoading,
      isCryptoFetching,
      isCryptoRefetching,
      isFiatLoading,
      isFiatFetching,
      isFiatRefetching,
    ],
  );

  return <OnrampTokensContext.Provider value={value as Props}>{children}</OnrampTokensContext.Provider>;
});
