import { TabUnstyled, TabsUnstyled } from '@mui/base';
import { styled } from '@mui/material/styles';

export const StyledTabs = styled(TabsUnstyled)(({ theme }) => ({
  background: theme.colors['secondary-02'],
  borderRadius: '32px',
  display: 'flex',
  position: 'relative',
  width: '100%',
}));

export const StyledTab = styled(TabUnstyled)(({ theme }) => ({
  '&.Mui-selected': {
    background: theme.colors['secondary-03'],
  },
  '&:hover .tab-text': {
    color: theme.colors['white-01'],
  },
  '&:disabled': {
    cursor: 'default',
  },
  '&:disabled .tab-text': {
    color: theme.colors['secondary-03'],
    opacity: 0.5,
    cursor: 'default',
  },
  alignItems: 'center',
  background: 'transparent',
  borderRadius: '32px',
  cursor: 'pointer',
  display: 'flex',
  flex: '1 1 auto',
  gap: '4px',
  justifyContent: 'center',
  minHeight: '35px',
  minWidth: 0,
  padding: '0 24px',
  textTransform: 'none',

  transition: '0.2s ease background',
  width: '100%',
}));
