import { Theme } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { compose, spacing, SpacingProps, SystemProps } from '@mui/system';
import React, { CSSProperties, FC, memo } from 'react';

import { StyledText } from './styled';

export type TextProps = {
  /**
   * Color name in Figma
   */
  bgColor?: keyof Theme['colors'];
  className?: string;
  /**
   * Color name in Figma
   */
  color?: keyof Theme['colors'];

  /**
   * Number of lines to be trimmed
   */
  lineClamp?: number;
  opacity?: CSSProperties['opacity'];

  /**
   * Text name in Figma
   */
  text?: keyof Theme['text'];
  textDecoration?: CSSProperties['textDecoration'];
  textIndent?: CSSProperties['textIndent'];
  wordBreak?: CSSProperties['wordBreak'];
} & SpacingProps &
  Pick<TypographyProps, 'align' | 'ref' | 'sx' | 'textTransform' | 'variant'> &
  Omit<SystemProps<Theme>, 'color'> &
  React.HTMLAttributes<HTMLSpanElement>;

export const Typography: FC<TextProps> = React.forwardRef<HTMLSpanElement, TextProps>(
  (
    {
      bgColor,
      children,
      className,
      color = 'white-01',
      lineClamp,
      opacity,
      text = 'app-14-medium',
      textDecoration,
      textIndent,
      variant = 'span',
      wordBreak,
      ...props
    },
    ref,
  ) => {
    const isButton = !!props.onClick;

    return (
      <StyledText
        className={className}
        tabIndex={isButton ? 0 : undefined}
        role={isButton ? 'button' : undefined}
        $textColor={color}
        $opacity={opacity}
        $bgColor={bgColor}
        $text={text}
        $lineClamp={lineClamp}
        $wordBreak={wordBreak}
        $textIndent={textIndent}
        $textDecoration={textDecoration}
        variant={variant}
        {...props}
        ref={ref}
      >
        {children}
      </StyledText>
    );
  },
);

export const Text = memo(
  styled(Typography, { shouldForwardProp: (prop) => !spacing.filterProps.includes(prop as string) })(compose(spacing)),
) as FC<TextProps>;
