import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { getDualTariff } from 'entities/Dual/api/queries';

let isPolling = false;

type Props = {
  baseToken: string;
  chainId: number;
  quoteToken: string;
};

export const useGetDualTariffQuery = (props: Props) => {
  const result = useQuery(
    [`dual-tariffs-${props.chainId}-${props.baseToken}-${props.quoteToken}`],
    () => getDualTariff(props),
    { enabled: !!props.chainId && !!props.baseToken && !!props.quoteToken },
  );

  const { refetch } = result;

  useEffect(() => {
    if (isPolling) return;
    isPolling = true;
    const intervalId = setInterval(() => {
      refetch();
    }, 30_000);

    return () => {
      clearInterval(intervalId);
      isPolling = false;
    };
  }, [refetch]);

  return result;
};
