export const THEME_COLORS = {
  'background-01': 'var(--background-01)',
  'black-01': 'var(--black-01)',
  'gradient-app': 'var(--gradient-app)',
  'gradient-price_down': 'var(--gradient-price_down)',
  'gradient-price_up': 'var(--gradient-price_up)',
  'gray-01': 'var(--gray-01)',
  'layout-01': 'var(--layout-01)',
  'layout-02': 'var(--layout-02)',
  'layout-03': 'var(--layout-03)',
  'orange-01': 'var(--orange-01)',
  'overlay-01': 'var(--overlay-01)',
  'overlay-02': 'var(--overlay-02)',
  'primary-01': 'var(--primary-01)',
  'primary-01-disabled': 'var(--primary-01-disabled)',
  'red-01': 'var(--red-01)',
  'secondary-01': 'var(--secondary-01)',

  'secondary-02': 'var(--secondary-02)',
  'secondary-03': 'var(--secondary-03)',
  'secondary-03-disabled': 'var(--secondary-03-disabled)',

  'secondary-04': 'var(--secondary-04)',

  transparent: 'var(--transparent)',
  'white-01': 'var(--white-01)',
  'white-02': 'var(--white-02)',
};
