import { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { compose, spacing, SpacingProps, flexbox, FlexboxProps, sizing, SizingProps } from '@mui/system';
import React, { FC, memo } from 'react';

import { Box } from 'shared/ui/Box';
import { Error } from 'shared/ui/Error';
import { Spinner } from 'shared/ui/Spinner';
import { Switch, SwitchProps } from 'shared/ui/Switch';
import { Text } from 'shared/ui/Text';
import { Tooltip } from 'shared/ui/Tooltip';

import { StyledContainer } from './styled';

type FormControlSwitchProps = Omit<SwitchProps, 'isError'> & {
  className?: string;
  /**
   * Color name in Figma
   */
  colorText?: keyof Theme['colors'];
  error?: string;
  isLoading?: boolean;
  label?: React.ReactNode | string;

  /**
   * Number of lines to be trimmed
   */
  lineClamp?: number;
  /**
   * Text name in Figma
   */
  text?: keyof Theme['text'];
  tooltip?: React.ReactNode;
} & SpacingProps &
  FlexboxProps &
  SizingProps;

export const FormControlSwitchInner = React.forwardRef<HTMLInputElement, FormControlSwitchProps>(
  ({ className, colorText, error, isLoading, label, text, tooltip, ...props }, ref) => (
    <StyledContainer className={className}>
      {typeof label === 'string' ? (
        <Text text={text} color={colorText}>
          {label}
        </Text>
      ) : (
        label
      )}
      {isLoading ? (
        <Box alignItems="center" width="36px">
          <Spinner size={24} />
        </Box>
      ) : (
        <Error message={error} align="right" width="max-content">
          <Tooltip
            title={tooltip}
            disabled={!tooltip}
            PopperProps={{
              modifiers: [
                {
                  name: 'preventOverflow',
                  options: {
                    padding: 12,
                  },
                },
              ],
            }}
          >
            <Switch ref={ref} {...props} isError={!!error} />
          </Tooltip>
        </Error>
      )}
    </StyledContainer>
  ),
);

export const FormControlSwitch = memo(
  styled(FormControlSwitchInner)(compose(spacing, flexbox, sizing)),
) as FC<FormControlSwitchProps>;
