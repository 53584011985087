import { useQuery } from '@tanstack/react-query';

import { useNetwork } from 'shared/hooks/network';

import { getDualTokenList } from '../api/queries';

export const useGetDualTokensQuery = () => {
  const { chainId } = useNetwork();

  const result = useQuery([`dual-tokens-${chainId}`], () => getDualTokenList(chainId!), {
    enabled: !!chainId,
    staleTime: 100000,
  });

  return { ...result, tokens: result.data || {} };
};
