import { ParticleNetwork } from '@particle-network/auth';
import { particleWallet } from '@particle-network/rainbowkit-ext';

import { supportedChains } from 'shared/config';

import { getImageWallet } from '../../getImages';
import { AppWallet } from '../../types';

import './styles.css';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const auth = new ParticleNetwork({
  projectId: process.env.REACT_APP_PARTICLE_PROJECT_ID as string,
  clientKey: process.env.REACT_APP_PARTICLE_CLIENT_KEY as string,
  appId: process.env.REACT_APP_PARTICLE_APP_ID as string,
  wallet: {
    displayWalletEntry: false,
    preload: true,
    supportChains: supportedChains,
  },
});

const connector = Object.create(
  particleWallet({ chains: supportedChains, authType: 'google' }).createConnector().connector,
);

connector.addListener('change', () => {});

connector.name = 'Google';
connector.id = 'particle:google';

export const particleGoogle: AppWallet = {
  connector,
  iconPath: getImageWallet('google'),
  id: connector.id,
  name: connector.name,
};
