import { withProfiler, ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from 'react-modal';

import { App } from 'app/appEntry';

import 'shared/styles/globals.scss';
import 'shared/styles/index.css';
import 'shared/styles/theme.scss';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

Modal.setAppElement('#root');

const ProfilerApp = withProfiler(App);

root.render(
  <SentryErrorBoundary>
    <React.StrictMode>
      <ProfilerApp />
    </React.StrictMode>
  </SentryErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
