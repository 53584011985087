import classNames from 'classnames';
import React, { CFC } from 'react';

import { Text } from 'shared/ui/Text';

import styles from './Badge.module.scss';
import { BadgeProps } from './types';

const SizeProps = {
  md: {
    height: '32px',
    px: '12px',
    py: '7px',
    text: 'app-14-medium',
  },
  sm: {
    height: '24px',
    padding: '6px',
    text: 'app-12-medium',
  },
} as const;

const ColorProps = {
  primary: { bgColor: 'primary-01', color: 'background-01' },
  secondary: { bgColor: 'secondary-02', color: 'secondary-03' },
} as const;

export const Badge: CFC<BadgeProps> = ({ children, className, size = 'md', variant = 'primary' }) => (
  <Text className={classNames(styles.badge, className)} {...SizeProps[size]} {...ColorProps[variant]}>
    {children}
  </Text>
);
