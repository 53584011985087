import { styled } from '@mui/material';

import { Text } from '../Text';

type Props = {
  $align: 'left' | 'right';
};

export const StyledText = styled(Text)<Props>(({ $align }) => ({
  '&::before': {
    left: $align === 'left' ? '1rem' : 'unset',
    right: $align === 'right' ? '1rem' : 'unset',
  },

  transform: 'translateY(calc(100% + 8px))',
}));
