import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import React, { CFC, useEffect, useMemo, useState } from 'react';
import { FormProvider, UseFormReturn, useForm, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Address } from 'viem';

import { SwapAssetFieldValue, SwapFormValues } from 'entities/Swap/model';

import { NATIVE_TOKEN_ADDRESS } from 'shared/config';
import { useAccount, useChangeChainEffect } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { removeQueryParam } from 'shared/lib';

import { useIsWETH } from '../hooks/useIsWETH';
import { useSwapTokenBySymbol } from '../hooks/useSwapTokenBySymbol';

type Props = {
  form: UseFormReturn<SwapFormValues, any, undefined>;
  formValues: SwapFormValues;
  input: SwapAssetFieldValue;
  output: SwapAssetFieldValue;
  reverse: () => void;
  update: (args: { input?: Partial<SwapAssetFieldValue>; output?: Partial<SwapAssetFieldValue> }) => void;
};

export const SwapFormContext = React.createContext({} as Props);

export const SwapFormProvider: CFC = observer(({ children }) => {
  const { selectedChainId: chainId } = useNetwork();
  const { address } = useAccount();
  const [searchParams] = useSearchParams();

  const chainIdParam = useMemo<string | null>(() => searchParams.get('chainId'), []);
  const baseTickerParam = useMemo<string | null>(() => searchParams.get('baseTicker'), []);
  const quoteTickerParam = useMemo<string | null>(() => searchParams.get('quoteTicker'), []);

  const baseTokenParam = useSwapTokenBySymbol(baseTickerParam?.toLowerCase());
  const quoteTokenParam = useSwapTokenBySymbol(quoteTickerParam?.toLowerCase());

  const [baseTokenParamUsed, setBaseTokenParamUsed] = useState(false);
  const [quoteTokenParamUsed, setQuoteTokenParamUsed] = useState(false);

  const [inputValues, setInputValues] = useState<SwapAssetFieldValue>({
    amount: '',
    amountUSD: null,
    address: baseTickerParam ? null : (NATIVE_TOKEN_ADDRESS as Address | null),
  });

  const [outputValues, setOutputValues] = useState<SwapAssetFieldValue>({
    amount: '',
    amountUSD: null,
    address: null as Address | null,
  });

  const swapForm = useForm<SwapFormValues>({
    defaultValues: {
      address,
      slippage: '',
      fromToken: inputValues.address,
      fromAmount: inputValues.amount,
      toToken: outputValues.address,
    },
    mode: 'onSubmit',
  });

  const swapFormValues = useWatch({ control: swapForm.control });

  const isWETH = useIsWETH(swapFormValues.fromToken, swapFormValues.toToken);

  useEffect(() => {
    if (
      !baseTokenParamUsed &&
      !quoteTokenParamUsed &&
      baseTokenParam !== null &&
      quoteTokenParam !== null &&
      baseTickerParam === quoteTickerParam
    ) {
      update({
        input: { address: baseTokenParam?.address || NATIVE_TOKEN_ADDRESS },
        output: { address: null },
      });
      setBaseTokenParamUsed(true);
      setQuoteTokenParamUsed(true);
    }
    if (!baseTokenParamUsed && baseTokenParam !== null && baseTickerParam !== quoteTickerParam) {
      update({
        input: { address: baseTokenParam?.address || NATIVE_TOKEN_ADDRESS },
      });
      setBaseTokenParamUsed(true);
    }
    if (!quoteTokenParamUsed && quoteTokenParam !== null && baseTickerParam !== quoteTickerParam) {
      update({
        output: { address: quoteTokenParam?.address || null },
      });
      setQuoteTokenParamUsed(true);
    }
  }, [baseTokenParam, quoteTokenParam]);

  useEffect(() => {
    const isChainParamProvided = !!searchParams.get('chainId');
    const isChainParamMatched = isChainParamProvided && chainIdParam && chainIdParam === chainId.toString();

    if (isChainParamMatched) removeQueryParam('chainId');

    if (isChainParamMatched || !isChainParamProvided) {
      if (searchParams.get('baseTicker') && baseTickerParam) removeQueryParam('baseTicker');
      if (searchParams.get('quoteTicker') && quoteTickerParam) removeQueryParam('quoteTicker');
    }
  }, [searchParams, chainId, chainIdParam, baseTickerParam, quoteTickerParam]);

  useEffect(() => {
    if (isWETH) {
      update({
        input: { amountUSD: null },
        output: { amount: inputValues.amount, amountUSD: null },
      });
    }
  }, [isWETH, inputValues.amount]);

  useEffect(() => {
    if (!inputValues.amount || BigNumber(inputValues.amount).isZero())
      update({
        input: { amountUSD: null },
        output: { amount: '', amountUSD: null },
      });
  }, [inputValues.amount]);

  useChangeChainEffect(() => {
    handleInputChange({ address: NATIVE_TOKEN_ADDRESS, amount: '' });
    handleOutputChange({ address: null, amount: '' });
  });

  const rawInputChange = (value: Partial<SwapAssetFieldValue>) => {
    setInputValues((prev) => ({ ...prev, ...value }));
    if (value.address !== undefined) swapForm.setValue('fromToken', value.address);
    if (value.amount !== undefined) swapForm.setValue('fromAmount', value.amount);
  };

  const rawOutputChange = (value: Partial<SwapAssetFieldValue>) => {
    setOutputValues((prev) => ({ ...prev, ...value }));
    if (value.address !== undefined) swapForm.setValue('toToken', value.address);
  };

  const handleInputChange = (value: Partial<SwapAssetFieldValue>) => {
    if (value.address && value.address === outputValues.address) {
      reverse();
      return;
    }

    rawInputChange(value);
  };

  const handleOutputChange = (value: Partial<SwapAssetFieldValue>) => {
    if (value.address && value.address === inputValues.address) {
      reverse();
      return;
    }

    rawOutputChange(value);
  };

  const update = ({
    input,
    output,
  }: {
    input?: Partial<SwapAssetFieldValue>;
    output?: Partial<SwapAssetFieldValue>;
  }) => {
    if (input) {
      handleInputChange({ ...inputValues, ...input });
    }

    if (output) {
      handleOutputChange({ ...outputValues, ...output });
    }
  };

  const reverse = () => {
    const lastInput = { ...inputValues };
    const lastOutput = { ...outputValues };

    rawInputChange(lastOutput);
    rawOutputChange({ amount: isWETH ? lastOutput.amount : '', amountUSD: null, address: lastInput.address });
  };

  const values = useMemo(
    () => ({ input: inputValues, output: outputValues, form: swapForm, formValues: swapFormValues, update, reverse }),
    [inputValues, outputValues, swapForm, swapFormValues, update, reverse],
  );

  return (
    <SwapFormContext.Provider value={values as Props}>
      <FormProvider {...values.form}>{children}</FormProvider>
    </SwapFormContext.Provider>
  );
});
