import React, { useState } from 'react';

import { OnrampFiatCurrency } from 'entities/Onramp/model';

import { Box, IconByUrl, Text } from 'shared/ui';
import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

interface Props extends Omit<React.ComponentProps<typeof CurrencyIcon>, 'ticker' | 'url'> {
  currency: OnrampFiatCurrency | null;
}

export const OnrampFiatIcon: React.FC<Props> = (props) => {
  const [isError, setIsError] = useState(false);

  return (
    <>
      {props.currency && !isError && (
        <IconByUrl {...props} url={`/static/tickers/${props.currency.ticker}.svg`} onError={() => setIsError(true)} />
      )}
      {(!props.currency || isError) && <FallbackFiatIcon {...props} />}
    </>
  );
};

const FallbackFiatIcon: React.FC<Props> = (props) => (
  <>
    <Box
      height={props.size}
      width={props.size}
      backgroundColor="secondary-01"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      borderRadius={40}
      {...props}
    >
      <Text style={{ fontSize: (props.size as number) / 2.8 }}>
        {props.currency?.ticker.toUpperCase().replace(/[a-z]/g, '').substring(0, 3)}
      </Text>
    </Box>
  </>
);
