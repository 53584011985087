import { useMemo } from 'react';

import { CHAIN_INFO } from 'shared/config/chains';

import { useNetwork } from './network';

export const useBlockExplorerLink = (prefix: 'address' | 'tx', hash: string | undefined, chainId?: number) => {
  const { network } = useNetwork();

  const explorer = chainId ? CHAIN_INFO[chainId]?.blockExplorers?.default : network?.blockExplorers?.default;

  const r = useMemo(() => {
    const anchor = explorer?.name;
    const url = `${explorer?.url}/${prefix}/${hash}`;

    return {
      anchor,
      url,
    };
  }, [explorer, hash, prefix]);

  if (!explorer || !hash) {
    return null;
  }

  return r;
};
