import { Address } from 'viem';

import { NATIVE_TOKEN_ADDRESS } from 'shared/config';

import { useWETHAddress } from './useWETHAddress';

export const useIsWETH = (inputAddress: Address | null | undefined, outputAddress: Address | null | undefined) => {
  const weth = useWETHAddress();

  return (
    (inputAddress?.toLowerCase() === weth.toLowerCase() &&
      outputAddress?.toLowerCase() === NATIVE_TOKEN_ADDRESS.toLowerCase()) ||
    (inputAddress?.toLowerCase() === NATIVE_TOKEN_ADDRESS.toLowerCase() &&
      outputAddress?.toLowerCase() === weth.toLowerCase())
  );
};
