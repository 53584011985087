import { Address } from 'viem';
import { erc20ABI, readContracts } from 'wagmi';

export const fetchToken = async (args: { address: Address; chainId: number }) => {
  const contractsPayload = {
    contracts: [
      {
        address: args.address,
        abi: erc20ABI,
        functionName: 'name',
        chainId: args.chainId,
      },
      {
        address: args.address,
        abi: erc20ABI,
        functionName: 'symbol',
        chainId: args.chainId,
      },
      {
        address: args.address,
        abi: erc20ABI,
        functionName: 'decimals',
        chainId: args.chainId,
      },
    ],
  };

  const tokenData = await readContracts(contractsPayload);

  if (
    !tokenData ||
    !tokenData[0] ||
    !tokenData[1] ||
    !tokenData[2] ||
    tokenData[0].status === 'failure' ||
    tokenData[1].status === 'failure' ||
    tokenData[2].status === 'failure'
  )
    return null;

  return {
    address: args.address,
    name: tokenData[0].result! as string,
    symbol: tokenData[1].result! as string,
    decimals: tokenData[2].result! as number,
  };
};
