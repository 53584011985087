import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useNetwork } from 'shared/hooks/network';

import { getSwapTokenList } from '../api/queries';

export const useGetSwapTokensQuery = () => {
  const { selectedChainId: chainId } = useNetwork();

  const result = useQuery([`swap-tokens-${chainId}`], () => getSwapTokenList(chainId!), {
    enabled: !!chainId,
    staleTime: 1000000,
  });

  const tokens = useMemo(() => result.data || [], [result.data]);

  return { ...result, tokens };
};
