import React, { useState } from 'react';

import { CHAIN_IMAGE_MAP } from 'shared/config';
import { Box, IconByUrl, Text } from 'shared/ui';
import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

interface Props extends Omit<React.ComponentProps<typeof CurrencyIcon>, 'ticker' | 'url'> {
  chain?: string | null;
}

export const OnrampChainIcon: React.FC<Props> = (props) => {
  const [isError, setIsError] = useState(false);

  return (
    <>
      {props.chain && !isError && (
        <IconByUrl
          {...props}
          url={`/static/chains/${CHAIN_IMAGE_MAP[props.chain] || props.chain}.svg`}
          onError={() => setIsError(true)}
        />
      )}
      {(!props.chain || isError) && <FallbackChainIcon {...props} />}
    </>
  );
};

const FallbackChainIcon: React.FC<Props> = (props) => (
  <>
    <Box
      height={props.size}
      width={props.size}
      backgroundColor="secondary-01"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      borderRadius={40}
      {...props}
    >
      <Text style={{ fontSize: (props.size as number) / 2.8 }}>{props.chain?.substring(0, 3).toUpperCase()}</Text>
    </Box>
  </>
);
