//@ts-nocheck
import { InjectedConnector } from 'wagmi/connectors/injected';

function isDeFiConnectProvider(object: any): object is IDeFiConnectProvider {
  return (
    object &&
    typeof object.chainId !== 'undefined' &&
    typeof object.networkVersion !== 'undefined' &&
    typeof object.accounts !== 'undefined' &&
    typeof object.connect !== 'undefined' &&
    typeof object.request !== 'undefined'
  );
}

export class CryptoComConnector extends InjectedConnector {
  constructor({ chains = [], options_ = {} }) {
    const options = {
      name: 'Crypto.com Wallet',
      ...options_,
    };
    super({ chains, options });

    this.id = 'CryptoCom';
    this.ready = typeof window !== 'undefined' && !!this.findProvider(window.deficonnectProvider);
  }

  findProvider(ethereum) {
    if (ethereum?.providers) return ethereum.providers.find(this.getReady);
    return this.getReady(ethereum);
  }

  async getProvider() {
    async function checkInjectProvider(times = 0): Promise<any> {
      return new Promise((resolve) => {
        function check() {
          if (isDeFiConnectProvider(window.deficonnectProvider)) {
            resolve(window.deficonnectProvider);
            return;
          }
          if (navigator?.userAgent?.includes('DeFiWallet') && window.ethereum) {
            resolve(window.ethereum);
            return;
          }
          if (times > 0) {
            setTimeout(async () => {
              --times;
              check();
            }, 50);
            return;
          }
          resolve(undefined);
        }
        check();
      });
    }

    const deficonnectProvider = await checkInjectProvider(10);

    return deficonnectProvider;
  }

  getReady(ethereum) {
    return ethereum;
  }
}
