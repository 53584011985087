import React from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorBoundary } from 'app/lib';

import { Box, BoxProps, Button, Text } from 'shared/ui';

export const LayoutContent: React.FC<React.PropsWithChildren & BoxProps> = React.memo(({ children, ...boxProps }) => (
  <Box
    id="basic-layout-content"
    alignSelf="center"
    width={{ default: '100%', tablet: 568 }}
    mb={{ default: 0, desktop: 24, tablet: 16 }}
    bg="background-01"
    borderColor="secondary-02"
    sx={{ borderWidth: { default: 0, tablet: 1 } }}
    borderRadius={24}
    overflow="hidden"
    {...boxProps}
  >
    <ErrorBoundary fallback={(reset) => <LayoutFallback reset={reset} />}>{children}</ErrorBoundary>
  </Box>
));

const LayoutFallback: React.FC<{ reset: () => void }> = ({ reset }) => {
  const { t } = useTranslation();

  return (
    <Box height={250} justifyContent="center" alignItems="center">
      <Text text="app-18-medium" mb={16}>
        {t('common.wrong')}
      </Text>
      <Button onClick={reset}>{t('common.fetchRetry')}</Button>
    </Box>
  );
};
