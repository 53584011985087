import { styled } from '@mui/material';

export const SwitchThumb = styled('span')(({ theme }) => ({
  '&.checked': {
    backgroundColor: theme.colors['background-01'],
    transform: 'translateX(18px)',
  },
  alignItems: 'center',
  backgroundColor: theme.colors['secondary-01'],
  borderRadius: '100%',
  display: 'flex',
  flexShrink: 0,
  height: '18px',
  justifyContent: 'center',
  left: 0,
  position: 'absolute',
  top: 0,
  transition: 'all 0.25s ease',

  width: '18px',
}));

type Props = {
  $isError?: boolean;
  $isHover?: boolean;
};

export const SwitchRoot = styled('span')<Props>(({ $isError, $isHover, theme }) => ({
  '&.checked': {
    backgroundColor: theme.colors['primary-01'],
  },
  alignItems: 'center',
  backgroundColor: theme.colors['secondary-02'],
  borderRadius: '16px',
  boxSizing: 'border-box',
  display: 'flex',
  flexShrink: '0',
  height: '22px',
  padding: '2px',
  position: 'relative',

  transition: 'all 0.25s ease',

  width: '40px',

  ...($isError && {
    '&:before': {
      border: `2px solid ${theme.colors['red-01']}`,
      borderRadius: '16px',
      boxSizing: 'content-box',
      content: "''",
      height: '100%',
      left: '-2px',
      opacity: 1,
      pointerEvents: 'none',
      position: 'absolute',
      top: '-2px',
      transition: 'opacity 0.25s ease',
      width: '100%',
    },
  }),

  '&.checked:hover:not(.disabled) .SW-SwitchThumb': {
    transform: 'translateX(16px)',
  },

  '&.disabled': {
    opacity: 0.4,
  },

  '&.disabled input': {
    cursor: 'not-allowed',
  },

  '&:hover:not(.checked,.disabled) .SW-SwitchThumb': {
    transform: 'translateX(2px)',
  },

  ...($isHover && {
    '&.checked:not(.disabled) .SW-SwitchThumb': {
      transform: 'translateX(18px)',
    },
    '&:not(.checked,.disabled) .SW-SwitchThumb': {
      transform: 'translateX(2px)',
    },
  }),
}));

export const SwitchInput = styled('input')(({ theme }) => ({
  cursor: 'pointer',
  height: '100%',
  left: '0',
  margin: '0',
  opacity: '0',
  position: 'absolute',
  [theme.breakpoints.down('tablet')]: {
    height: '200%',
    left: '50%',
    top: '50%',
    transform: 'translate3d(-50%,-50%,0)',
    width: '200%',
  },
  top: '0',

  width: '100%',
}));

export const SwitchTrack = styled('span')(() => ({
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  height: '18px',
  justifyContent: 'space-between',
  padding: '0 3px',
  position: 'relative',
  width: '100%',
}));
