import throttle from 'lodash.throttle';
import { makeAutoObservable, reaction, runInAction } from 'mobx';

import { socket } from 'shared/api';

import { getRateBySymbol } from '../lib/getRateBySymbol';

import { RateProduct, RatesType } from './types';

type RateEvent = [symbol: string, mid: MID, bid: BID, ask: ASK, date: string, diff24: number];

const subThrottled = throttle(
  (symbols) => {
    socket.emit('sub', {
      name: 'rates3',
      symbols,
    });
  },
  100,
  { leading: false, trailing: true },
);

const subThrottledProduct = throttle(
  (symbols, product) => {
    socket.emit('sub', {
      name: 'rates3',
      symbols,
      product,
    });
  },
  100,
  { leading: false, trailing: true },
);

export class RatesStore {
  countRates: Record<RateProduct, Record<string, any>> = { [RateProduct.DEFAULT]: {}, [RateProduct.PERPETUAL]: {} };
  rates: RatesType = { [RateProduct.DEFAULT]: {}, [RateProduct.PERPETUAL]: {} };
  callbacks: Record<RateProduct, Map<string, (event: RateEvent) => void>> = {
    [RateProduct.DEFAULT]: new Map(),
    [RateProduct.PERPETUAL]: new Map(),
  };

  constructor() {
    makeAutoObservable(this);

    socket.on('rates3', (event: any) => {
      const product: RateProduct | null = event[0];
      const rates = event[1];
      rates.forEach((event: RateEvent) => {
        const [symbol, mid, bid, ask, date] = event;
        runInAction(() => {
          if (this.rates[product || RateProduct.DEFAULT]) {
            this.rates[product || RateProduct.DEFAULT][symbol] = {
              mid,
              bid,
              ask,
              createdAt: Date.parse(date),
            };
          }
          if (this.callbacks[product || RateProduct.DEFAULT]) {
            this.callbacks[product || RateProduct.DEFAULT].forEach((callback) => callback(event));
          }
        });
      });
    });

    reaction(
      () =>
        Object.keys(this.countRates[RateProduct.DEFAULT]).filter(
          (r) => (this.countRates[RateProduct.DEFAULT][r] || 0) > 0,
        ),
      (values) => subThrottled(values),
    );

    reaction(
      () =>
        Object.keys(this.countRates[RateProduct.PERPETUAL]).filter(
          (r) => (this.countRates[RateProduct.PERPETUAL][r] || 0) > 0,
        ),
      (values) => {
        subThrottledProduct(values, RateProduct.PERPETUAL);
      },
    );
  }

  setRates(rates: RatesType) {
    this.rates = { ...this.rates, ...rates };
  }

  getRate(symbol: string, product?: RateProduct | null, inputTicker?: string | undefined) {
    const rateProduct = product || RateProduct.DEFAULT;
    const rate = this.rates[rateProduct]?.[symbol];
    if (!rate) return undefined;
    return getRateBySymbol(rate, symbol, inputTicker);
  }

  rateSubscribe(symbol: string, product?: RateProduct | null) {
    const rateProduct = product || RateProduct.DEFAULT;

    if (!this.countRates[rateProduct]?.[symbol]) {
      this.countRates[rateProduct][symbol] = 1;
      return;
    }
    this.countRates[rateProduct][symbol] += 1;
  }

  rateUnsubscribe(symbol: string, product?: RateProduct | null) {
    const rateProduct = product || RateProduct.DEFAULT;

    if (!this.countRates[rateProduct]?.[symbol]) {
      this.countRates[rateProduct][symbol] = 0;
      return;
    }
    this.countRates[rateProduct][symbol] -= 1;
  }

  registerCallback(product: RateProduct, id: string, callback: (e: RateEvent) => void) {
    this.callbacks[product].set(id, callback);
  }

  deregisterCallback(product: RateProduct, id: string) {
    this.callbacks[product].delete(id);
  }

  dispose() {}
}

export const ratesStore = new RatesStore();
