import { useQuery } from '@tanstack/react-query';

import { useAccount } from 'shared/hooks';

import { getPointsStats } from '../api/queries';

export const useGetPointsStatsQuery = () => {
  const { address, isConnected } = useAccount();

  return useQuery([`points-stats-${address}`], () => (address ? getPointsStats(address!) : null), {
    enabled: isConnected && !!address,
    staleTime: 30000,
  });
};
