import { UseQueryResult } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import React, { CFC, useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { formatUnits, parseUnits } from 'viem';

import { SwapQuoteResponse, useGetSwapQuoteQuery } from 'entities/Swap/model';

import { useNetwork } from 'shared/hooks/network';

import { useIsWETH } from '../hooks/useIsWETH';
import { useSwapDebouncedAmount } from '../hooks/useSwapDebouncedAmount';
import { useSwapForm } from '../hooks/useSwapForm';
import { useSwapTokenByAddress } from '../hooks/useSwapTokenByAddress';

type Props = UseQueryResult<SwapQuoteResponse>;

export const SwapQuoteContext = React.createContext({} as Props);

export const SwapQuoteProvider: CFC = observer(({ children }) => {
  const { selectedChainId: chainId } = useNetwork();

  const { input, output, form, update } = useSwapForm();
  const values = useWatch({ control: form.control });

  const { data: inputToken } = useSwapTokenByAddress(values.fromToken);
  const { data: outputToken } = useSwapTokenByAddress(values.toToken);

  const isWETH = useIsWETH(values.fromToken, values.toToken);

  const debouncedAmount = useSwapDebouncedAmount();

  const isQueryReady =
    !isWETH &&
    !!debouncedAmount &&
    BigNumber(debouncedAmount).gt(0) &&
    !!values.fromToken &&
    !!values.toToken &&
    !!inputToken;

  const result = useGetSwapQuoteQuery(
    {
      chainId,
      fromAmount:
        inputToken && debouncedAmount
          ? parseUnits(debouncedAmount as `${number}`, inputToken.decimals).toString()
          : undefined,
      fromToken: values.fromToken as string,
      toToken: values.toToken as string,
    },
    {
      refetchInterval: 1000 * 10,
      enabled: isQueryReady,
      retry: false,
    },
  );

  useEffect(() => {
    if (result.data) {
      update({
        input: { ...input, amountUSD: result.data.fromAmountUSD },
        output: {
          ...output,
          amount: outputToken ? formatUnits(BigInt(result.data.toAmount), outputToken.decimals).toString() : '',
          amountUSD: result.data.toAmountUSD,
        },
      });
    }
  }, [result.data]);

  useEffect(() => {
    if (!result.isFetching && result.error) {
      update({
        input: { ...input, amountUSD: null },
        output: {
          ...output,
          amount: '',
          amountUSD: null,
        },
      });
    }
  }, [result.isFetching, result.error]);

  useEffect(() => {
    if (isQueryReady) result.refetch();
  }, [isQueryReady]);

  const memoResult = useMemo(() => result, [result]);

  return <SwapQuoteContext.Provider value={memoResult as Props}>{children}</SwapQuoteContext.Provider>;
});
