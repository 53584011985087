import { ButtonBase, styled, Theme } from '@mui/material';

import { shouldForwardProp } from 'shared/lib';

type Props = {
  $disabled?: boolean;
  $size: 'large' | 'medium' | 'small' | 'tiny';
  $variant:
    | 'primary'
    | 'primary-solid'
    | 'red'
    | 'secondary'
    | 'secondary-dark'
    | 'secondary-inactive'
    | 'secondary-solid'
    | 'transparent';
};

export const BUTTON_SIZE = {
  large: {
    borderRadius: 16,
    height: 56,
    px: 32,
  },
  medium: {
    borderRadius: 12,
    height: 48,
    px: 24,
  },
  small: {
    borderRadius: 8,
    height: 40,
    px: 16,
  },
  tiny: {
    borderRadius: 8,
    height: 32,
    px: 16,
  },
} as const;

const BG = {
  primary: 'primary-01',
  red: 'red-01',
  secondary: 'secondary-01',
  'secondary-dark': 'secondary-02',
  'secondary-inactive': 'secondary-02',
  solid: 'transparent',
} as Record<string, keyof Theme['colors']>;

const HOVER_BG = {
  primary: '#8FFFCA',
  'primary-solid': 'rgba(9, 102, 65, 0.3)',
  red: '#BE3333',
  secondary: '#864DF7',
  'secondary-dark': '#281452',
  'secondary-inactive': 'secondary-02',
  'secondary-solid': '#20123F',
} as Record<string, string>;

const DISABLED_BG = {
  primary: 'rgba(9, 102, 65, 1)',
  'primary-solid': 'transparent',
  red: '#562531',
  secondary: 'rgba(32, 18, 63, 1)',
  'secondary-dark': 'rgba(32, 18, 63, 1)',
  'secondary-inactive': 'secondary-02',
  'secondary-solid': 'transparent',
} as Record<string, string>;

const styles = ({ $disabled, $size, $variant, theme }: Props & { theme: Theme }) => ({
  alignItems: 'center',
  background: BG[$variant] ? theme.colors[BG[$variant]] : 'transparent',
  borderRadius: BUTTON_SIZE[$size].borderRadius,
  cursor: 'pointer',
  display: 'flex',
  height: BUTTON_SIZE[$size].height,
  justifyContent: 'center',
  paddingLeft: BUTTON_SIZE[$size].px,
  paddingRight: BUTTON_SIZE[$size].px,
  transition: 'all 0.2s ease',

  width: '100%',

  ...($variant === 'primary-solid' &&
    ({
      border: `1px solid ${theme.colors['primary-01']}`,
    } as const)),
  ...($variant === 'secondary-solid' &&
    ({
      border: `1px solid ${theme.colors['secondary-03']}`,
    } as const)),

  '&.Mui-disabled': {
    cursor: 'not-allowed',
    pointerEvents: 'all',
  },

  '&.button-disabled': {
    background: DISABLED_BG[$variant] ? DISABLED_BG[$variant] : 'transparent',
    cursor: 'not-allowed',
    pointerEvents: 'all',
  },

  '@media (hover: hover)': {
    '&:not(.button-disabled):hover': {
      background: HOVER_BG[$variant] ? HOVER_BG[$variant] : 'transparent',
    },
  },

  '@media (hover: none)': {
    '&:not(.button-disabled):active': {
      background: HOVER_BG[$variant] ? HOVER_BG[$variant] : 'transparent',
    },
  },

  ...($variant === 'transparent' && {
    '> span::after': {
      background: theme.colors['secondary-03'],
      content: '""',
      display: 'flex',
      height: '1px',
      opacity: 0,
      transition: 'all 0.2s ease',
      width: '100%',
    },
    ...(!$disabled && {
      '&:hover > span::after': {
        opacity: 1,
      },
    }),
  }),

  [theme.breakpoints.down('tablet')]: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
});

export const StyledButton = styled(ButtonBase, { shouldForwardProp })<Props>(styles);
export const StyledDiv = styled('div', { shouldForwardProp })<Props>(styles);
