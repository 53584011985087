import { useWatch } from 'react-hook-form';

import { useDebounce } from 'shared/hooks';

import { useOnrampForm } from './useOnrampForm';

export const ONRAMP_INPUT_DEBOUNCE = 300;

export const useOnrampDebouncedAmount = () => {
  const { form } = useOnrampForm();
  const values = useWatch({ control: form.control });

  const debounced = useDebounce(values.fromAmount, ONRAMP_INPUT_DEBOUNCE);
  return debounced;
};
