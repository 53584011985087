import { createPublicClient, http } from 'viem';
import { mainnet } from 'viem/chains';
import { createConfig, createStorage, configureChains } from 'wagmi';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';

import { supportedChains } from 'shared/config/chains';
import { PUBLIC_NODES } from 'shared/config/nodes';
import { availableWallets } from 'shared/lib/rehold-wallets';

const providers = Array.from({ length: 10 })
  .map((_, i) => i)
  .map((i) =>
    jsonRpcProvider({
      rpc: (chain) =>
        PUBLIC_NODES[chain.id]?.[i]
          ? {
              http: PUBLIC_NODES[chain.id][i],
            }
          : null,
    }),
  );

export const { publicClient, webSocketPublicClient } = configureChains(supportedChains, providers, {
  retryCount: 7,
});

export const ethPublicClient = createPublicClient({
  chain: mainnet,
  transport: http(),
});

export const config = createConfig({
  autoConnect: true,
  connectors: availableWallets.map((c) => c.connector),
  publicClient,
  storage: createStorage({ key: 'wagmi1', storage: window.localStorage }),
  webSocketPublicClient,
});
