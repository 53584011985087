import React, { memo } from 'react';

import { Box, BoxProps } from 'shared/ui';

import { LayoutBackground } from '../Background';
import { LayoutContent } from '../Content';
import { LayoutHeader } from '../Header';

export const BasicLayout: React.FC<React.PropsWithChildren & BoxProps> = memo(({ children, ...rest }) => (
  <Box minWidth={320} id="page" position="unset">
    <LayoutBackground />
    <LayoutHeader />
    <LayoutContent {...rest}>{children}</LayoutContent>
  </Box>
));
