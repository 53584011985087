import isPropValid from '@emotion/is-prop-valid';
import memoize from '@emotion/memoize';

type Fn<T> = (key: string) => T;

const createShouldForwardDomProp = (): Fn<boolean> => memoize((prop) => isPropValid(prop));
const createShouldForwardProp = (): Fn<boolean> => memoize((prop) => !prop.startsWith('$'));

export const shouldForwardDomProp: Fn<boolean> = createShouldForwardDomProp();
export const shouldForwardProp: Fn<boolean> = createShouldForwardProp();
