import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { getDualTariffs } from 'entities/Dual/api/queries';

import { useNetwork } from 'shared/hooks/network';

let isPolling = false;

export const useGetDualTariffsQuery = () => {
  const { chainId } = useNetwork();

  const result = useQuery([`dual-tariffs-${chainId}`], () => getDualTariffs({ chainId: chainId! }), {
    enabled: !!chainId,
  });

  const { refetch } = result;

  useEffect(() => {
    if (!chainId || isPolling) return;
    isPolling = true;
    const intervalId = setInterval(() => {
      refetch();
    }, 30_000);

    return () => {
      clearInterval(intervalId);
      isPolling = false;
    };
  }, [chainId, refetch]);

  return result;
};
