import { supportedChains } from 'shared/config';

import { CryptoComConnector } from '../../connectors/CryptoComConnector';
import { getImageWallet } from '../../getImages';
import { AppWallet } from '../../types';

export const cryptoWallet: AppWallet = {
  connector: new CryptoComConnector({
    chains: supportedChains as any,
  }),
  iconPath: getImageWallet('cryptocom'),
  id: 'CryptoCom',
  name: 'Crypto.com',
};
