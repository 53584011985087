import { useQuery } from '@tanstack/react-query';

import { getDualTokenLists } from '../api/queries';

export const useGetDualTokensAllQuery = () => {
  const result = useQuery(['dual-tokens'], () => getDualTokenLists(), {
    staleTime: 100000,
  });

  return { ...result, tokens: result.data || {} };
};
