import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserStore } from 'entities/User/model';

import { Box, Button, Text, PageContent, PageHeader, Spinner, Modal } from 'shared/ui';

export const WaitForUserActionModal = observer(() => {
  const { t } = useTranslation();

  const { isOpenConfiramationModal, optionsConfirmationModal, setIsOpenConfiramationModal } = useUserStore();

  let title = '';
  let description = '';

  if (optionsConfirmationModal === 'message') {
    title = t('modals.signMessage.title');
    description = t('modals.signMessage.message');
  } else if (optionsConfirmationModal === 'tx') {
    title = t('modals.signTransaction.title');
    description = t('modals.signTransaction.message');
  } else {
    title = optionsConfirmationModal.title;
    description = optionsConfirmationModal.description;
  }

  const handleClose = useCallback(() => {
    setIsOpenConfiramationModal(false);
  }, [setIsOpenConfiramationModal]);

  return (
    <Modal
      zIndex={999}
      isOpen={isOpenConfiramationModal}
      closeIcon
      onClose={handleClose}
      header={<PageHeader showLeftButton={false}>{title}</PageHeader>}
    >
      <PageContent p={24} mt={20}>
        <Box mt={24} mb={16} alignItems="center">
          <Spinner size={40} />
        </Box>
        <Box mb={56}>
          <Text textAlign="center">{description}</Text>
        </Box>
        <Button onClick={handleClose} variant="secondary">
          {t('modals.signTransaction.close')}
        </Button>
      </PageContent>
    </Modal>
  );
});
