import { FC, memo } from 'react';

import { Box, BoxProps } from '../Box';

import styles from './PageContent.module.scss';

export const PageContent: FC<BoxProps> = memo(({ children, className, ...rest }) => (
  <Box flexShrink={2} className={`hide-scroll ${styles.root} ${className}`} {...rest}>
    {children}
  </Box>
));
