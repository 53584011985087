export type RateObjType = {
  ask: number;
  bid: number;
  createdAt?: number; // will come from websocket only
  mid: number;
};

export enum RateProduct {
  DEFAULT = 'default',
  PERPETUAL = 'perpetual',
}

export type RatesType = Record<RateProduct, Record<string, RateObjType | undefined>>;
export type RatesResponse = Record<string, [MID, BID, ASK, number | undefined] | undefined>;

export type RateExtendedType = {
  ask?: number;
  bid?: number;
  high: number;
  low: number;
  open: number;
  price: number;
  symbol: string;
};
export type RatesExtendedResponse = RateExtendedType[];

export type ChartRatesResponse = {
  createdAt: string;
  price: string;
}[];

export type ChartRateItems = {
  createdAt: number;
  price: number;
}[];

export type ChartRatesArgs = {
  fromDate: string;
  fromTicker: string;
  toDate: string;
  toTicker: string;
};
