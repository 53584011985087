import { FC } from 'react';

import { Box } from 'shared/ui';

import styles from './NavLinks.module.scss';
import { NavLogo } from './NavLogo';
import { NavTabs } from './NavTabs';

export const NavLinks: FC = () => (
  <Box className={styles['nav-links-container']}>
    <NavLogo />
    <NavTabs />
  </Box>
);
