import React from 'react';

import { AttentionIcon, ErrorIcon, InfoIcon } from 'shared/assets';
import { Box } from 'shared/ui/Box';
import { Text } from 'shared/ui/Text';

interface BasicToastBodyProps {
  text?: string;
  title?: string;
  variant?: 'error' | 'info' | 'warning';
}

export const BasicToastBody: React.FC<BasicToastBodyProps> = ({ text, title, variant }) => {
  const textColor = variant === 'info' ? 'secondary-03' : 'background-01';
  return (
    <Box flexDirection="row">
      <Box mr={16} alignSelf="center">
        <Text color={textColor}>
          {variant === 'info' && <InfoIcon width={24} height={24} />}
          {variant === 'error' && <ErrorIcon width={24} height={24} />}
          {variant === 'warning' && <AttentionIcon width={24} height={24} />}
        </Text>
      </Box>
      <Box flex={1} alignSelf="center">
        {title && (
          <Text color={textColor} mb={4} whiteSpace="pre-wrap">
            {title}
          </Text>
        )}
        {text && (
          <Text text="app-12-regular" color={textColor} whiteSpace="pre-wrap">
            {text}
          </Text>
        )}
      </Box>
    </Box>
  );
};
