import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useIsSmallMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('phone'), { noSsr: true });
};

export const useIsMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('tablet'), { noSsr: true });
};

export const useIsTablet = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('desktop'), { noSsr: true });
};

export const useIsDesktop = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('laptop'), { noSsr: true });
};

export const useIsLaptop = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up('laptop'), { noSsr: true });
};
