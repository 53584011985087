import { useSwitch, UseSwitchParameters } from '@mui/base';
import classNames from 'classnames';
import React, { memo } from 'react';

import { SwitchRoot, SwitchInput, SwitchTrack, SwitchThumb } from './styled';

export type SwitchProps = {
  id?: string;
  isError?: boolean;
  isHover?: boolean;
  name?: string;
} & UseSwitchParameters;

const SwitchInner = React.forwardRef<HTMLInputElement, SwitchProps>(
  ({ disabled, id, isError, isHover, name, ...props }, ref) => {
    const { checked, focusVisible, getInputProps } = useSwitch(props);

    const stateClasses = {
      checked,
      disabled,
      focusVisible,
    };
    const { onBlur, onChange, ...innerProps } = getInputProps();

    return (
      <SwitchRoot
        className={classNames(stateClasses)}
        $isHover={isHover}
        $isError={isError}
        ref={ref}
        onClick={(e) => e.stopPropagation()}
      >
        <SwitchTrack>
          <SwitchThumb className={classNames(stateClasses, 'SW-SwitchThumb')} />
        </SwitchTrack>
        <SwitchInput
          {...innerProps}
          aria-label="switch"
          name={name}
          id={id}
          onChange={disabled ? () => {} : onChange}
          onBlur={disabled ? () => {} : onBlur}
        />
      </SwitchRoot>
    );
  },
);

export const Switch = memo(SwitchInner);
