/* eslint-disable no-nested-ternary */
import { ButtonBaseProps, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  compose,
  spacing,
  SpacingProps,
  GridProps,
  borders,
  BordersProps,
  FlexboxProps,
  grid,
  flexbox,
  sizing,
  SizingProps,
  positions,
  PositionsProps,
} from '@mui/system';
import classNames from 'classnames';
import React, { memo, FC, forwardRef, useCallback } from 'react';

import { useCopy } from 'shared/hooks';
import { Spinner } from 'shared/ui/Spinner';

import { Box } from '../Box';
import { Text } from '../Text';

import { StyledButton, StyledDiv } from './styled';

const TEXT_SIZE = {
  large: 'app-18-medium',
  medium: 'app-16-medium',
  small: 'app-14-medium',
  tiny: 'app-14-medium',
} as const;

const TEXT_COLOR = {
  primary: 'black-01',
  'primary-solid': 'primary-01',
  red: 'white-01',
  secondary: 'white-01',
  'secondary-dark': 'secondary-03',
  'secondary-inactive': 'secondary-03',
  'secondary-solid': 'secondary-01',
  transparent: 'secondary-03',
} as const;

const TEXT_COLOR_DISABLED = {
  primary: 'black-01',
  'primary-solid': 'primary-01-disabled',
  red: 'white-01',
  secondary: 'gray-01',
  'secondary-dark': 'secondary-03',
  'secondary-inactive': 'secondary-03',
  'secondary-solid': 'secondary-03',
  transparent: 'secondary-03-disabled',
} as const;

export type ButtonProps = {
  loading?: boolean;
  //'data-id': string;
  onFakeDisabled?: () => void;
  size?: 'large' | 'medium' | 'small' | 'tiny';
  text?: keyof Theme['text'];
  variant?:
    | 'primary'
    | 'primary-solid'
    | 'red'
    | 'secondary'
    | 'secondary-dark'
    | 'secondary-inactive'
    | 'secondary-solid'
    | 'transparent';
} & Omit<ButtonBaseProps, 'size' | 'sx' | 'variant'> &
  SpacingProps &
  GridProps &
  FlexboxProps &
  BordersProps &
  SizingProps &
  PositionsProps;

export const BaseButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled,
      loading,
      onClick,
      onFakeDisabled,
      size = 'medium',
      text,
      variant = 'primary',
      ...rest
    },
    ref,
  ) => {
    const Component = onFakeDisabled || disabled ? StyledDiv : StyledButton;

    const onClickFakeDisabled = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onFakeDisabled?.();
    };

    return (
      <Component
        //@ts-ignore
        ref={ref}
        disabled={(onFakeDisabled ? false : disabled) || loading}
        //@ts-ignore
        onClick={loading ? undefined : disabled ? onClickFakeDisabled : onClick}
        $variant={variant}
        $size={size}
        $disabled={disabled}
        className={classNames(className, {
          'button-disabled': disabled,
        })}
        {...rest}
      >
        {loading && (
          <Box position="absolute">
            <Spinner size={20} />
          </Box>
        )}

        <Text
          color={disabled ? TEXT_COLOR_DISABLED[variant] : TEXT_COLOR[variant]}
          text={text || TEXT_SIZE[size]}
          textAlign="center"
          opacity={loading ? 0 : 1}
        >
          {children}
        </Text>
      </Component>
    );
  },
);

export interface ButtonCopyProps extends ButtonProps {
  copyText: string;
}

export const Button = memo(
  styled(BaseButton, {
    shouldForwardProp: (prop) =>
      !compose(spacing, grid, flexbox, borders, sizing, positions).filterProps.includes(prop as string),
  })(compose(spacing, grid, flexbox, borders, sizing, positions)),
) as FC<ButtonProps>;

export const ButtonCopy: FC<ButtonCopyProps> = ({ copyText, onClick, ...rest }) => {
  const copy = useCopy(copyText);

  const handlePressCopy = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      copy();
      onClick?.(event);
    },
    [copy, onClick],
  );

  return <Button onClick={handlePressCopy} {...rest} width="max-content" />;
};
