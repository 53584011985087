import { requester } from 'shared/api';

import { SwapDataRequestArgs, SwapDataResponse, SwapQuoteRequestArgs, SwapQuoteResponse } from '../model';

export const getSwapQuote = ({ chainId, fromAmount, fromToken, toToken }: SwapQuoteRequestArgs) =>
  requester
    .get<SwapQuoteResponse>(`/api/v1/swaps/${chainId}/quote`, { params: { fromAmount, fromToken, toToken } })
    .then((res) => res.data);

export const getSwapData = ({ chainId, address, slippage, fromAmount, fromToken, toToken }: SwapDataRequestArgs) =>
  requester
    .get<SwapDataResponse>(`/api/v1/swaps/${chainId}/swap`, {
      params: { address, slippage, fromAmount, fromToken, toToken },
    })
    .then((res) => res.data);
