import { FC } from 'react';
import { Toast } from 'react-hot-toast';

import { BasicToast } from '../BasicToast';
import { BasicToastBody } from '../BasicToastBody';

export interface AttentionProps {
  text: string;
  title?: string;
}

export const Attention: FC<Toast & AttentionProps & { onClose: VoidFunction }> = ({
  onClose,
  text,
  title,
  ...rest
}) => (
  <BasicToast onClose={onClose} toastProps={rest} variant="warning">
    <BasicToastBody variant="warning" title={title} text={text} />
  </BasicToast>
);
