import { Theme, styled } from '@mui/material';
import React, { useCallback, FC } from 'react';

import { OnrampFiatCurrency } from 'entities/Onramp/model';

import { shouldForwardProp } from 'shared/lib';
import { Box, Text } from 'shared/ui';

import { OnrampFiatIcon } from './OnrampFiatIcon';

type BoxProps = {
  $borderColor: keyof Theme['colors'];
};

const StyledBox = styled(Box, { shouldForwardProp })<BoxProps>(({ $borderColor, theme }) => ({
  borderColor: `var(--currency-pair-color, ${theme.colors[$borderColor]})`,
  transition: 'all 0.2s ease',
}));

type Props = {
  className?: string;
  currency: OnrampFiatCurrency;
  onPress: (selected: OnrampFiatCurrency) => void;
  selected?: boolean;
};

export const OnrampFiat: FC<Props> = ({ className, currency, selected = false, onPress }) => {
  const handlePress = useCallback(() => {
    onPress(currency);
  }, [currency, onPress]);

  return (
    <>
      <li className={className} onClick={handlePress} style={{ opacity: selected ? 0.5 : 1 }}>
        <Box flexDirection="row" alignItems="center" width="60%">
          <Box flexDirection="row">
            <StyledBox position="relative" zIndex={1} borderRadius={32} $borderColor="background-01">
              <OnrampFiatIcon currency={currency} size={32} />
            </StyledBox>
          </Box>{' '}
          <Box ml={8} flexBasis={0} flexGrow={1}>
            <Text color="white-01">{currency.name}</Text>
            <Text color="gray-01">{currency.ticker.toUpperCase()}</Text>
          </Box>
        </Box>
      </li>
    </>
  );
};
