import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';

import { supportedChains } from 'shared/config';

import { getImageWallet } from '../../getImages';
import { AppWallet } from '../../types';

export const walletConnect2: AppWallet = {
  connector: new WalletConnectConnector({
    chains: supportedChains.filter(({ testnet }) => !testnet) as any,

    options: {
      projectId: '05789562b4524162f7b33832e43e3df2',
      showQrModal: false,
    },
  }),
  iconPath: getImageWallet('walletconnect'),
  id: 'walletConnect', // used from WalletConnectConnector
  name: 'WalletConnect',
};
