import { TabsUnstyledProps } from '@mui/base';
import { styled } from '@mui/material/styles';
import { compose, spacing, SpacingProps, SystemProps } from '@mui/system';
import React, { FC, memo } from 'react';

import { StyledTabs } from './styled';

type Props = TabsUnstyledProps & SpacingProps & SystemProps;

export const ImplTabs: FC<Props> = memo(({ children, ...props }) => <StyledTabs {...props}>{children}</StyledTabs>);

export const Tabs = memo(
  styled(ImplTabs, { shouldForwardProp: (prop) => !spacing.filterProps.includes(prop as string) })(compose(spacing)),
) as FC<Props>;
