import hj from '@hotjar/browser';

export enum HotjarEvents {
  DUAL_APPROVE_CLICK = 'dual_approve_click',
  DUAL_APPROVE_SUCCESS = 'dual_approve_success',
  DUAL_START_CLICK = 'dual_start_click',
  DUAL_START_SUCCESS = 'dual_start_success',
  WALLET_CONNECT_CLICK = 'wallet_connect_click',
  WALLET_CONNECT_SUCCESS = 'wallet_connect_success',
}

export const trackHotjar = (event: HotjarEvents) => {
  if (hj.isReady() && !!window.hj?.settings) hj.event(event as string);
};
