import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useGetPointsStatsQuery } from 'entities/Points';
import { useUserStore } from 'entities/User/model';

import { PathPatterns } from 'shared/lib';
import { Box, Button, Modal, Text } from 'shared/ui';

import imageSource from './assets/image.png';

export const WelcomeBonusModal: React.FC = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isOpenWelcomeModal, setIsOpenWelcomeModal } = useUserStore();
  const { refetch: statsRefetch } = useGetPointsStatsQuery();

  const handleClose = useCallback(() => {
    statsRefetch();
    setIsOpenWelcomeModal(false);
  }, [setIsOpenWelcomeModal]);

  const handleAction = useCallback(() => {
    handleClose();
    navigate(PathPatterns.DualsDashboard);
  }, [handleClose, navigate]);

  const isWideMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });

  return (
    <Modal
      contentPadding="0"
      isOpen={isOpenWelcomeModal}
      onClose={handleClose}
      closeIcon
      forceDesktopLayout
      zIndex={100000}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <img
          style={{ objectFit: 'cover' }}
          alt="success"
          src={imageSource}
          width="100%"
          height={isWideMobile ? '150px' : '220px'}
        />
        <Text width="80%" text="app-22-medium" textAlign="center" mt={16}>
          {t('modals.wecomeBonusModal.title')}
        </Text>
        <Button width="fit-content" mt={20} mb={24} onClick={handleAction}>
          {t('modals.wecomeBonusModal.button')}
        </Button>
      </Box>
    </Modal>
  );
});
