import { SafePalConnector } from '@rehold-io/connectkit';

import { supportedChains } from 'shared/config';

import { getImageWallet } from '../../getImages';
import { AppWallet } from '../../types';

export const safePal: AppWallet = {
  connector: new SafePalConnector({
    chains: supportedChains as any,
  }),
  iconPath: getImageWallet('safePal'),
  id: 'safePal',
  name: 'safePal',
};
