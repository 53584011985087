import BigNumber from 'bignumber.js';

import { TokensState } from 'entities/Token/model/types';

import { toBigNumber } from 'shared/lib';

import { DualTariff } from './dualTariff';

export type Tarrifs = Readonly<
  {
    baseToken: string;
    enabled?: boolean;
    id: string;
    quoteToken: string;
    stakingPeriod: BigNumber | number;
    yield: BigNumber | string;
  }[]
>;

export const mapToTariffs = (values?: Tarrifs, tokens?: TokensState): DualTariff[] => {
  if (!values) return [];

  return values
    .map((tariff) => {
      const baseTicker = tokens?.[tariff.baseToken?.toLowerCase()!]?.symbol?.toLowerCase() || '';
      const quoteTicker = tokens?.[tariff.quoteToken?.toLowerCase()!]?.symbol?.toLowerCase() || '';

      const apr = toBigNumber(Number(tariff.yield)).div(Number(tariff.stakingPeriod)).times(24).times(365).div(1e8);

      const formattedApr = apr.toFixed(4);

      return {
        apr: +formattedApr,
        baseTicker,
        id: tariff.id?.toString(),
        quoteTicker,
        stakingPeriod: Number(tariff.stakingPeriod),
        yield: tariff.yield?.toString(),
      };
    })
    .filter((tariff) => tariff.baseTicker && tariff.quoteTicker);
};
