import { Theme, styled } from '@mui/material';

import { shouldForwardProp } from 'shared/lib';

import { Box } from '../Box';

type Props = {
  $borderColor: keyof Theme['colors'];
};

export const StyledBox = styled(Box, { shouldForwardProp })<Props>(({ $borderColor, theme }) => ({
  borderColor: `var(--currency-pair-color, ${theme.colors[$borderColor] || $borderColor})`,
  transition: 'all 0s ease',
}));
