import { UseQueryResult } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import React, { CFC, useEffect, useMemo } from 'react';

import { OnrampQuoteResponse, useGetOnrampQuoteQuery } from 'entities/Onramp/model';

import { useOnrampDebouncedAmount } from '../hooks/useOnrampDebouncedAmount';
import { useOnrampForm } from '../hooks/useOnrampForm';

type Props = UseQueryResult<OnrampQuoteResponse>;

export const OnrampQuoteContext = React.createContext({} as Props);

export const OnrampQuoteProvider: CFC = observer(({ children }) => {
  const { input, output, update, formValues } = useOnrampForm();

  const debouncedAmount = useOnrampDebouncedAmount();

  const isQueryReady = !!input.currency && !!output.currency && !!debouncedAmount && BigNumber(debouncedAmount).gt(0);

  const result = useGetOnrampQuoteQuery(
    {
      fromTokenCode: input.currency?.code,
      fromAmount: debouncedAmount,
      toTokenCode: output.currency?.code,
      provider: formValues.provider,
    },
    {
      refetchInterval: 1000 * 10,
      enabled: isQueryReady,
      retry: false,
    },
  );

  useEffect(() => {
    if (result.data) {
      update({
        output: {
          ...output,
          amount: output.currency ? result.data.toAmount.toString() : '',
        },
      });
    }
  }, [result.data]);

  useEffect(() => {
    if (!result.isFetching && result.error) {
      update({
        output: {
          ...output,
          amount: '',
        },
      });
    }
  }, [result.isFetching, result.error]);

  useEffect(() => {
    if (isQueryReady) result.refetch();
  }, [isQueryReady]);

  const memoResult = useMemo(() => result, [result]);

  return <OnrampQuoteContext.Provider value={memoResult as Props}>{children}</OnrampQuoteContext.Provider>;
});
