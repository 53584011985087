import { PhantomConnector } from '@rehold-io/connectkit';

import { supportedChains } from 'shared/config';

import { getImageWallet } from '../../getImages';
import { AppWallet } from '../../types';

export const phantom: AppWallet = {
  connector: new PhantomConnector({
    chains: supportedChains as any,
  }),
  iconPath: getImageWallet('phantom'),
  id: 'phantom',
  name: 'Phantom',
};
